#members {
	#member-subtext {
		margin-bottom: 35px;
		.member-subtext-left_text p {
			margin-bottom: 10px;
		}
		#member-subtext-left #member-whatsapp-desktop {
			img {
				min-height: 140px;
			}
		}
	}
}
#adventskalender {
	#advent-mainimage {
		width: 100%;
		min-width: auto;
	}
	&.silvester {
		height: 720px;
		background-position: 95%;
	}
	.button {
		padding: 3px 24px 0 24px;
		height: 44px;
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50px;
	}
	ul.medialist {
		padding: 0 20px;
		li.medialist__item {
			.medialist__item-title {
				a {
					max-width: 260px;
				}
			}
		}
	}
	ul.medialist#advent-mobile-medialist-bottom {
		margin-top: 0;
		li.medialist__item {
			width: 100%;
			margin-bottom: 16px;
			.medialist__item-title {
				display: block;
			}
		}
	}
	#advent-topl, #advent-bottomr {
		display: none;
	}
	#advent-description {
		width: auto;
		min-width: auto;
		padding: 0 20px;
		h3 {
			letter-spacing: 5px;
			font-size: 15px;
			line-height: 75px;
		}
		#advent-description-top {
			padding: 20px 0 10px 0;
			h3 {
				letter-spacing: 5.47px;
				font-size: 14px;
				line-height: 1;
			}
			h2 {
				line-height: 28px;
				font-size: 20px;
			}
		}
		#advent-description-bottom {
			#advent-memberlink {
				line-height: 22.4px;
			}
		}
	}
	&.starter {
		img {
			width: 100%;
		}
		#advent-starter-centered {
			.button {
				padding: 3px 10px 0 10px;
				height: 44px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			#advent-starter-daynumber {
				font-size: 112px;
				line-height: 1em;
			}
		}
		#advent-starter-bottomtext {
			padding: 20px 50px 0 50px;
			font-size: 16px;
		}
	}
	.advent-starter-door {
		width: auto;
		min-width: 300px;
		max-width: 750px;
	}
}
.advent-headerimage {
	height: 102px;
	width: 300px;
	margin-bottom: -30px;
	.advent-headerimage-day {
		font-size: 34px;
    right: 44px;
    top: 3px;
    width: 50px;
	}
}

.aktionspage {
	&#silvester, &#newyear {
		background-size: auto 100%;
		background-position: top right -58px;
		.aktionspage-centered {
			top: 15%;
			height: 63%;
			width: 90%;
			img {
				width: 100%;
			}
		}
		.aktionspage-bgimg {
			min-height: 100vh;
			object-fit: cover;
			object-position: 85%;
		}
		&.mobile-fullwidth {
			margin-left: 0 !important;
		}
	}
	&#newyear {
		background-position: top left 59%;
		margin-top: -60px;
		.aktionspage-bgimg {
			object-position: 60%;
		}
		.aktionspage-centered {
			top: 80px;
			height: calc(100% - 80px);
			span {
				font-size: 14px;
				letter-spacing: 14px;
				text-indent: 14px;
				margin-bottom: 180px;
			}
			h1 {
				font-size: 60px;
				margin-bottom: 0;
			}
			h2 {
				font-size: 20px;
				letter-spacing: 10px;
				text-indent: 10px;
			}
			.aktionspage-bottomtext {
				bottom: 65px;
			}
		}
	}
	h3 {
		letter-spacing: 6px;
		font-size: 14px;
		margin-top: 18px;
		line-height: 1em;
	}
	h2 {
		line-height: 1.4;
		font-size: 22px;
	}
	.aktionspage-description {
		width: 100%;
		background-color: rgba(5, 5, 5, 0.65);
	}
	.aktionspage-centered {
		.button {
			width: 85%;
			padding: 17px 0px 17px 0px;
			display: inline-block;
			margin-bottom: 0px;
			line-height: 1em;
			left: 7.5%;
		}
	}
	.aktionspage-memberlink {
		font-size: 16px;
	}
	.aktionspage-bottomtext {
		line-height: 24px;
		width: 90%;
		text-align: center;
	}
}

@media screen and (max-width: 768px) {
	#blackfriday .aktionspage-centered {
		width: 92%;
		transform: none;
		top: 54vw;
		left: unset;
		.aktionspage-topimage {
			width: 70%;
		}
		.aktionspage-description-top {
			padding: 10px 20px 20px 20px;
		}
	}
}

._container_gradient {
	margin-left: 0;
	margin-right: 0;
	@media screen and (max-width: 1024px) {
		._textcontent_stickybanner, ._img_stickybanner {
			display: none;
		}
	}
	.button {
		margin: 0 auto;
	}
}

#cam-menu-top-right {
	width: 100%;
	justify-content: space-evenly;
	border-top: 1px solid $color-grey1;
	padding-top: 10px;
	margin-top: -10px;
	.dropdown_cam.active {
		background-color: $color-coral;
		color: #ffffff;
		.icon {
			color: #ffffff;
		}
	}
	label.cam_filter {
		font-size: 18px;
		&.active {
			&:after {
				width: 42px;
				margin-left: -21px;
				bottom: -2px;
			}
		}
	}
}

#cam-filter {
	position: fixed;
	z-index: 999;
	bottom: 15px;
	right: 15px;
	border-radius: 60px;
	font-size: 8px;
	width: 50px;
	height: 50px;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.icon {
		font-size: 18px;
		margin-right: 0;
	}
}
#mainlisting-cams {
	#cam-filters-active {
		margin-bottom: 10px;
		margin-top: -10px;
		span {
			display: none;
		}
	}
	label.cam_filter {
		font-size: 15px;
		margin-right: 0px;
		&:hover {
			background-color: $color-grey1;
			color: $color-dark;
		}
		&.active {
			&:after {
				font-size: 13px;
			}
		}
	}
	#cam-filter-menu {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		height: 100vh;
		z-index: 999;
		background-color: $color-white;
		flex-direction: column;
		align-items: flex-start;
		.cam-categories {
			margin-left: 0;
			display: block;
			.cam-filter-menu-categories {
				margin-right: 0;
				div {
					flex-direction: row;
				}
				h4 {
					margin-top: 10px;
					font-size: 14px;
				}
				&:nth-child(1) {
					margin-left: 0;
				}
				a {
					display: inline-block;
					margin-right: 2px;
				}
			}
		}
		.filter_container {
			width: 100%;
			display: block;
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px solid $color-grey2;
			.cam_filter_dropdown, .cam_filter_delete {
				width: 100%;
				font-size: 17px;
				padding: 10px 0px;
				display: flex;
				justify-content: center;
				align-items: center;
				.icon {
					font-size: 19px;
				}
			}
		}
		#cam-filter-mobile-close {
			width: 100%;
			border-bottom: 1px solid $color-grey2;
			padding-bottom: 8px;
			.close-icon {
				float: right;
				display: block;
			}
			.vertical-banner {
				transform: none;
				float: left;
				position: relative;
				text-transform: none;
				font-weight: 600;
				left: 0;
				top: 0;
				width: auto;
				text-align: left;
				color: $color-dark;
				font-size: 17px;
			}
		}
	}
}

.lSSlideWrapper .lSAction {
	display: none !important;
}

@media screen and (max-width: 1024px) {
	#adventskalender.mobile-fullwidth {
		margin-left: 0 !important;
	}
	#advent-description-top + .mobile-only {
		display: none;
	}
}

@media screen and (max-width: 670px) {
	#adventskalender #advent-mainimage {
		justify-content: flex-end;
		.advent-videopackimg {
			width: 53%;
			left: 20px;
			bottom: 20%;
		}
		ul.medialist {
			display: flex;
			flex-direction: column;
			min-width: 140px;
			max-width: 50%;
			margin-top: 20px;
			grid-gap: 0;
			li.medialist__item {
				margin-bottom: 10px;
			}
		}
	}
	#adventskalender ul.medialist#advent-mobile-medialist-bottom {
		display: flex;
    flex-direction: column;
    padding: 0;
	}
	#adventskalender #advent-description #advent-description-top {
		padding-top: 20px;
	}
}

@media screen and (max-width: 500px) {
	#adventskalender {
		.advent-starter-door {
			#advent-starter-centered {
				top: 70%;
				.button {
					margin-top: 80px;
					font-size: 16px;
					height: 38px;
				}
			}
		}
		#advent-starter-bottomtext {
			margin-top: 30px;
		}
	}
	#advent-description-top + .mobile-only {
		display: block;
	}
}

@media screen and (max-width: 425px) {
	#adventskalender .medialist__item-title {
		display: none;
	}
	.aktionspage#silvester .aktionspage-centered {
		top: 36%;
	}
}