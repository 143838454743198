.loader,
.loader:before,
.loader:after {
	background: #ffffff;
	-webkit-animation: load1 1s infinite ease-in-out;
	animation: load1 1s infinite ease-in-out;
	width: 1em;
	height: 2em;
}
.loader {
	color: #ffffff;
	text-indent: -9999em;
	margin: 25px 15px 20px 15px;
	position: relative;
	font-size: 6px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.loader.dark {
	background: black;
	color: black;
	&:after,
	&:before {
		background: black;
	}
}
.loader:before,
.loader:after {
	position: absolute;
	top: 0;
	content: "";
}
.loader:before {
	left: -1.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}
.loader:after {
	left: 1.5em;
}
@-webkit-keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 2em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 3em;
	}
}
@keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 2em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 3em;
	}
}
.rotate {
	-webkit-animation: rotate 10s linear infinite;
	animation: rotate 10s linear infinite;
}
@-webkit-keyframes rotate {
	to {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}

.accordion {
	button.accordion {
		background-color: #fff;
		cursor: pointer;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 16px;
		width: 100%;
		text-align: left;
		border: none;
		outline: none;
        transition: 0.4s;
        margin-bottom: 15px;
        &.active, &:hover {
            background-color: #F5F6FA;
        }
	}
	.panel {
		padding: 0px 18px 0px 18px;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;
    }
    button.accordion.active + .panel {
        padding-bottom: 10px;
    }
	.accordion:before {
        font-family: 'puricons' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
		content: "\E916";
		font-size: 13px;
		float: right;
        margin-left: 5px;
		color: $color-coral;
		margin-top: 7px;
	}

	.active:before {
        font-family: 'puricons' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\E917";
        color: $color-coral;
	}
}
