.bonuspage {
	.content-limited {
		position: relative;
		height: 90vh;
		max-height: 1000px;
		min-height: 600px;
		overflow: hidden;
	}
	.bonuspage__textarea {
		color: $color-white;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 15%;
		z-index: 2;
    transform: translate(0, -50%);
	}
	.handwriting {
		color: $color-white;
		display: block;
		font-size: 46px;
		text-shadow: 0px 3px 6px #00000033;
		margin-bottom: 10px;
	}
	h1 {
		font-size: 135px;
		text-shadow: 0px 3px 6px #00000033;
	}
	h3 {
		font-size: 44px;
		text-shadow: 0px 3px 6px #00000033;
		margin-bottom: 40px;
	}
	a {
		display: inline-block;
	}
	.infotext {
		display: block;
		margin-top: 20px;
		font-size: 14px;
		color: #8A96A3;
	}
	.button {
		font-size: 18px;
		text-transform: uppercase;
		background-image: linear-gradient(82deg, #FF617C 0%, #FF6F61 100%);
		box-shadow: 0px 2px 15px #F9657D;
		border-radius: 100px;
		font-family: "Poppins", sans-serif;
		padding: 10px 48px 10px 48px;
		border: 2px solid transparent;
		transition: 500ms ease-out;
		&:hover {
			border: 2px solid $color-white;
			background-image: none;
			background-color: transparent;
			box-shadow: none;
		}
	}
	img {
		display: block;
	}
	@media screen and (max-width: 1560px) {
		.handwriting {
			font-size: 2.9vw;
		}
		h1 {
			font-size: 8.6vw;
		}
		h3 {
			font-size: 2.8vw;
		}
	}

	@media screen and (max-width: 1250px) {
		.content-limited, .bonuspage__coins-imagecontainer {
			max-height: 800px;
		}
	}

	@media screen and (max-width: 1024px) {
		.handwriting {
			font-size: 29px;
		}
		h1 {
			font-size: 86px;
		}
		h3 {
			font-size: 28px;
		}
		.bonuspage__textarea {
			top: unset;
			bottom: 10%;
			transform: translate(0, 0);
		}
	}

	@media screen and (max-width: 768px) {
		.content-limited {
			padding: 0;
		}
		.bonuspage__textarea {
			left: unset;
			width: 100%;
		}
	}

	@media screen and (max-width: 500px) {
		.handwriting {
			font-size: 5.7vw;
		}
		h1 {
			font-size: 17.1vw;
		}
		h3 {
			font-size: 5.5vw;
			margin-bottom: 20px;
		}
		.content-limited, .bonuspage__coins-imagecontainer {
			max-height: 630px;
		}
	}
}

#bonuspage__coins, #error-page {
	background-image: linear-gradient(243deg, rgba(133,147,152,1) 0%, rgba(40,48,72,1) 100%);
	.bonuspage__coins-image {
		position: absolute;
		right: 0;
	}
	.bonuspage__coins-imagecontainer {
		position: relative;
		height: 90vh;
		max-height: 1000px;
		img:first-of-type {
			position: relative;
			z-index: 1;
			height: 100%;
		}
		img:last-of-type {
			position: absolute;
			left: 70px;
   	 	top: 15%;
    	width: 30%;
			animation: rotate 45s linear infinite;
		}
	}
	@media screen and (min-width: 1250px) and (max-height: 900px) {
		.bonuspage__coins-image {
			right: 5%;
		}
	}
	@media screen and (max-width: 768px) {
		.bonuspage__coins-image {
			right: -100px;
		}
	}
}

#error-page {
	background-image: linear-gradient(180deg, #00000000 0%, #00000080 100%), url(#{$cdn}/images/static/lpcms_bg404.jpg);
	background-position: right;
	background-size: cover;
	.bonuspage__textarea {
		left: 7%;
		max-width: 55%;
		@media screen and (max-width: 1024px) {
			left: 5%;
			max-width: 50%;
		}
		@media screen and (max-width: 768px) {
			left: unset;
			max-width: unset;
			h3 {
				padding: 0 20px;
			}
		}
	}
	.bonuspage__coins-imagecontainer {
		img:last-of-type {
			display: none;
		}
	}
}

#bonuspage__livecams {
	background-image: linear-gradient(243deg, rgba(146,133,152,1) 0%, rgba(57,40,72,1) 100%);
	overflow: hidden;
	.content-limited {
		overflow: unset;
		img {
		position: absolute;
		left: 70%;
		bottom: 0;
		max-height: 900px;
		height: 85vh;
		}
	}
	@media screen and (max-width: 1024px) {
		.content-limited {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.bonuspage__textarea {
				position: static;
				transform: none;
				margin: 40px 0 80px 0;
			}
			img {
				position: static;
				width: 100%;
				height: auto;
			}
		}
	}
}

#bonuspage__videorabatt {
	background-image: linear-gradient(243deg, rgba(58,58,61,1) 0%, rgba(16,16,20,1) 100%);
	.content-limited {
		height: unset;
		@media screen and (max-width: 425px) {
			height: 80vh;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
		}
	}
	.bonuspage__textarea {
		position: static;
		transform: translate(0, 0);
		margin: 20px 0 30px 0;
	}
	.bonuspage__videos {
		.bonuspage__videos-medialist {
			display: flex;
			flex-direction: row;
			margin-bottom: 12px;

			.is-vertical {
				a {
					background-color: transparent;
				}
			}
			animation: videoslide alternate infinite linear 80s;
			&:last-of-type {
				animation: videoslide alternate-reverse infinite linear 80s;
			}
			@media screen and (max-width: 425px) {
				animation-name: videoslide-small;
				animation-duration: 40s;
				&:last-of-type {
					animation: videoslide-small-reverse alternate infinite linear 40s;
				}
			}
			@media screen and (max-height: 800px) {
				animation-name: videoslide-small;
				animation-duration: 40s;
				&:last-of-type {
					animation: videoslide-small-reverse alternate infinite linear 40s;
				}
			}
			&:hover {
				animation-play-state: paused;
			}

			img, video {
				width: 320px;
				margin-right: 12px;
				border-radius: 5px;
				height: 180px;
				@media screen and (max-width: 425px) {
					width: 220px;
					height: 124px;
				}
				@media screen and (max-height: 800px) {
					width: 220px;
					height: 124px;
				}
			}
			@keyframes videoslide {
				0% {transform: translateX(-70px);}
				100% {transform: translateX(-1460px);}
			}
			@keyframes videoslide-small {
				0% {transform: translateX(-70px);}
				100% {transform: translateX(-700px);}
			}
			@keyframes videoslide-small-reverse {
				100% {transform: translateX(-70px);}
				0% {transform: translateX(-700px);}
			}
		}
	}
	@media screen and (max-height: 750px) and (min-width: 501px) {
		.handwriting {
			font-size: 29px;
		}
		h1 {
			font-size: 86px;
		}
		h3 {
			font-size: 28px;
		}
	}
}