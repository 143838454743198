.sl-wrapper .sl-counter {
	font-size: 18px !important;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
#chat {
	bottom: 0px;
	right: 0px;
	position: relative;
	#chat-wrapper {
		display: flex;
		margin-top: 20px;
	}
	#chat-attachment-list-right {
		width: 336px;
		max-width: 336px;
		min-width: 336px;
		margin-left: 30px;
		display: flex;
		flex-direction: column;
		a.show-all {
			font-size: 14px;
			text-align: center;
			margin-top: 10px;
		}
		h2 {
			margin-bottom: 20px;
		}
		.chat-received-attachment {
			img {
				width: 110px;
				float: left;
				border: 1px solid #fff;
				cursor: pointer;
			}
		}
	}
	.card {
		flex-grow: 1;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		max-height: calc(100vh - 165px);
		max-height: calc((var(--vh, 1vh) * 100) - 125px);
	}
	.contacts_body {
		padding: 0.75rem 0;
		overflow-y: auto;
		white-space: nowrap;
	}
	.msg_card_body {
		overflow-y: auto;
		min-height: 300px;
		padding: 20px 40px 15px 40px;
		background-color: #f5f6fa;
		border-radius: 5px;
		border-bottom: 1px solid #f5f6fa;
	}
	.card-header {
		border-radius: 15px 15px 0 0;
		border-bottom: 0;
	}
	.card-footer {
		margin-top: 10px;
	}
	.container {
		align-content: center;
	}
	.search {
		border-radius: 15px 0 0 15px;
		background-color: rgba(0, 0, 0, 0.3);
		border: 0;
		color: white;
	}
	.search:focus {
		box-shadow: none;
		outline: 0px;
	}
	.type_msg {
		background-color: rgba(0, 0, 0, 0.3);
		border: 0;
		color: white;
		height: 60px;
		overflow-y: auto;
	}
	.type_msg:focus {
		box-shadow: none;
		outline: 0px;
	}
	.attach_btn {
		border-radius: 15px 0 0 15px;
		background-color: rgba(0, 0, 0, 0.3);
		border: 0;
		color: white;
		cursor: pointer;
	}
	.send_btn {
		border-radius: 0 15px 15px 0;
		background-color: rgba(0, 0, 0, 0.3);
		border: 0;
		color: white;
		cursor: pointer;
	}
	.search_btn {
		border-radius: 0 15px 15px 0;
		background-color: rgba(0, 0, 0, 0.3);
		border: 0;
		color: white;
		cursor: pointer;
	}
	.contacts {
		list-style: none;
		padding: 0;
	}
	.contacts li {
		width: 100%;
		padding: 5px 10px;
		margin-bottom: 15px;
	}
	.active {
		background-color: rgba(0, 0, 0, 0.3);
	}
	.user_img {
		height: 70px;
		width: 70px;
		border: 1.5px solid #f5f6fa;
	}
	.user_img_msg {
		height: 40px;
		width: 40px;
	}
	.img_cont {
		position: relative;
		height: 70px;
		width: 70px;
	}
	.img_cont_msg {
		height: 40px;
		width: 40px;
		min-width: 40px;
		min-height: 40px;
		border-radius: 18px;
		background-image: url(#{$cdn}/images/static/leonie-profile-small-1.jpg);
		background-position: -10px -7px;
		background-size: 50px 50px;
		background-repeat: no-repeat;
	}
	.online_icon {
		position: absolute;
		height: 15px;
		width: 15px;
		background-color: #4cd137;
		border-radius: 50%;
		bottom: 0.2em;
		right: 0.4em;
		border: 1.5px solid white;
	}
	.offline {
		background-color: #c23616;
	}
	.user_info {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 15px;
	}
	.user_info span {
		font-size: 20px;
		color: white;
	}
	.user_info p {
		font-size: 12px;
		margin: 0;
		color: rgba(255, 255, 255, 0.6);
	}
	.video_cam {
		margin-left: 50px;
		margin-top: 5px;
	}
	.video_cam span {
		color: white;
		font-size: 20px;
		cursor: pointer;
		margin-right: 20px;
	}
	.msg_container {
		margin-top: auto;
		margin-bottom: auto;
		border-radius: 5px;
		background-color: #f5f6fa;
		position: relative;
		word-break: break-word;
		font-size: 14px;
		line-height: 24px;
		a {
			text-decoration: underline;
			color: $color-coral;
			font-size: 14px;
		}
		p {
			font-size: 14px;
			font-weight: unset;
			margin-top: unset;
			line-height: unset;
		}
		.my-gallery a {
			padding: 0;
			background-color: transparent;
		}
	}
	.msg_time {
		float: right;
		font-size: 10px;
		color: $color-dark;
		margin-top: 10px;
		display: block;
		line-height: 1;
	}
	.msg_container.send .msg_time {
		float: right;
	}
	.msg_head {
		position: relative;
	}
	@media (max-width: 576px) {
		.contacts_card {
			margin-bottom: 15px;
		}
	}
	.rounded-circle {
		border-radius: 50%;
	}
	.input-group {
		position: relative;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-align: stretch;
		align-items: stretch;
		width: 100%;
	}
	.message_sending {
		font-weight: 600;
		width: 100%;
		font-size: 16px;
		height: 40px;
		text-align: center;
		padding-top: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: black;
		.loader {
			color: black;
			margin-bottom: -5px;
			margin-right: 25px;
		}
	}
	.video_recommendation_image {
		a {
			position: relative;
			display: block;
			.material-symbols-rounded {
				position: absolute;
				left: 50%;
				top: 50%;
				font-size: 84px;
				transform: translate(-50%, -50%);
				padding: 0;
				color: #ffffff;
				opacity: 0.75;
			}
			img {
				max-width: 300px;
				height: auto;
				display: block;
				border-radius: 10px;
				border: none;
				box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
			}
		}
	}
	.video_recommendation {
		a {
			text-decoration: none;
			cursor: pointer;
		}
		.video_recommendation_excerpt,
		.video_recommendation_title {
			display: block;
			color: #000000;
		}
		.video_recommendation_title {
			font-weight: bold;
			margin-bottom: 10px;
		}
	}
	.attach_btn .badge-info {
		margin-top: -20px;
		margin-left: -2px;
		font-size: 13px;
	}
	.chat-closed {
		font-size: 16px;
		background: rgb(65, 63, 65);
		color: #f9f8fa;
		padding: 6px;
		border: 1px solid #333333;
		cursor: pointer;
	}
	span.unread-messages {
		font-size: 16px;
	}
	.chat-message {
		margin-bottom: 10px;
		font-size: 16px;
		.msg_timebreaker {
			width: 100%;
			text-align: center;
			color: $color-dark;
			font-size: 10px;
			margin-bottom: 6px;
			margin-top: 15px;
		}
		.message_attachment {
			.censor-overlay {
				position: absolute;
				height: 100%;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				font-size: 14px;
				color: #ffffff;
				text-decoration: none;
				.button-md {
					margin-bottom: 6px;
				}
			}
			img {
				max-width: 300px;
				width: 100%;
				cursor: pointer;
				width: 300px;
				height: 300px;
				object-fit: cover;
				border-radius: 10px;
				border: none;
				display: block;
				box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
			}
			video {
				max-width: 280px;
			}
		}
		.msg_container {
			background-color: $color-white;
			border-radius: 3px 10px 10px 10px;
			color: $color-dark;
			.emoji {
				width: 18px !important;
				height: 18px !important;
				margin-bottom: -3px !important;
			}
		}
		.msg_outer {
			display: flex;
			max-width: 75%;
			@media screen and (max-width: $break-desktop) {
				max-width: 100%;
			}
		}
		.msg_wrapper {
			display: flex;
			flex-direction: column;
			margin-left: 6px;
			box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			background-color: $color-white;
			padding: 10px;
			&.msg_media {
				padding: 0;
			}
			.message_attachment {
				.video-js,
				video {
					max-height: 350px;
					text-align: center;
				}
				video {
					max-width: 200px;
					position: relative;
				}
				.vjs-volume-panel {
					flex-grow: 1;
				}
				.vjs-fullscreen video,
				.video-js.vjs-fullscreen {
					max-width: none;
					max-height: none;
					height: 100vh;
				}
			}
		}
		&.from-me {
			.msg_wrapper {
				margin-left: 0px;
				align-items: flex-end;
				flex-grow: 1;
				background-color: #8a96a3;
			}
			.msg_outer {
				float: right;
			}
			.message_attachment img {
				border-radius: 10px;
				box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
			}
			.msg_container {
				border-radius: 10px 3px 10px 10px;
				background-color: #8a96a3;
				color: $color-white;
			}
		}
	}
	.trigger_emojipicker {
		width: 40px;
		height: 40px;
	}
	#EmojiPicker {
		position: absolute;
		bottom: 76px;
		right: 5px;
		z-index: 10;
	}
	#EmojiPicker .container-search {
		display: none;
	}

	#chat-sendmessage {
		position: relative;
		display: flex;
		align-items: center;
		.material-symbols-rounded {
			padding: 0;
		}
		.add_circle {
			align-self: flex-end;
			margin-bottom: 8px;
		}
		textarea {
			width: 100%;
			margin-left: 10px;
			border-radius: 15px;
			border: 1px solid #f5f6fa;
			resize: none;
			color: $color-dark;
			font-size: 14px;
			font-family: "Poppins", sans-serif;
			padding: 8px 50px 8px 12px;
			flex-grow: 1;
			&:focus {
				outline: none;
			}
		}
		#chat-textarea-buttons {
			position: absolute;
			right: 8px;
			bottom: 8px;
			display: flex;
			width: 95px;
			justify-content: end;
			.badge-info {
				position: absolute;
				left: 18px;
				top: -8px;
			}
			.icon {
				color: #8a96a3;
				font-size: 24px;
			}
		}
	}
	#chat-textarea-priceinfo {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 10px;
		div {
			width: 100%;
			font-size: 10px;
			width: auto;
		}
		#chat-textarea-paymentlink {
			font-size: 10px;
			color: $color-red;
		}
		@media screen and (max-width: 530px) {
			flex-direction: column;
			#chat-textarea-paymentlink {
				order: 2;
			}
			div {
				order: 1;
			}
		}
	}
}
#chat .chat-message.not-from-me + .from-me {
	margin-top: 20px;
}

#chat .chat-message.from-me + .not-from-me {
	margin-top: 20px;
}

#chat .not-from-me .msg_time {
	color: #8a96a3;
}

#chat .from-me .msg_time {
	color: #f5f6fa;
}

#chat .my-gallery a:hover img {
	transform: none;
}

.typing-indicator {
	font-size: 12px;
	margin-bottom: 12px;
}

@keyframes new-mail {
	from {
		bottom: -80px;
	}
	to {
		bottom: 50px;
	}
}

.new_mail-banner {
	width: 300px;
	height: 70px;
	display: flex;
	background-color: $color-grey1;
	position: fixed;
	animation-name: new-mail;
	animation-duration: 500ms;
	animation-delay: 4s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
	bottom: -80px;
	right: 50px;
	border-radius: 12px;
	z-index: 12;
	box-shadow:
		rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
		rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	cursor: pointer;
	transition-duration: 400ms;
	transition-timing-function: ease-in-out;
	@media screen and (max-width: 1024px) {
		display: none;
	}
	.new_mail-image,
	.new_mail-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.new_mail-image {
		img {
			height: 50px;
			width: 50px;
			object-fit: cover;
			border-radius: 6px;
			margin-left: 10px;
			box-shadow:
				rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
				rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
			transition-duration: 400ms;
			transition-timing-function: ease-in-out;
			border: 0;
		}
	}
	.new_mail-text {
		span {
			font-size: 14px;
			margin-left: 10px;
			opacity: 1;
			transition-duration: 400ms;
			transition-delay: 100ms;
			&:first-of-type {
				font-weight: 600;
				color: $color-coral;
			}
		}
	}
	.material-symbols-rounded {
		padding: 6px;
		position: absolute;
		right: 0;
		top: 0;
		font-size: 16px;
		opacity: 1;
		transition-duration: 400ms;
		transition-delay: 100ms;
	}
	&.instant {
		animation-delay: 0ms;
		animation-duration: 0ms;
	}
	&.closed {
		width: 70px;
		height: 70px;
		border-radius: 50px;
		transition-duration: 400ms;
		transition-timing-function: ease-in-out;
		img {
			border-radius: 50px;
			width: 60px;
			height: 60px;
			box-shadow: none;
			margin: 0;
			transition-duration: 400ms;
			transition-timing-function: ease-in-out;
			border: 5px solid $color-green;
		}
		span {
			font-size: 0;
			opacity: 0;
			transition-duration: 600ms;
			transition-delay: 0ms;
		}
		&.preclosed {
			transition: none;
			img {
				transition: none;
			}
			span {
				transition: none;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	#chat .msg_card_body {
		padding: 20px;
		padding-bottom: 0;
		.chat-message:last-child {
			padding-bottom: 10px;
		}
	}
	#chat .img_cont_msg {
		display: none;
	}
	#chat .chat-message .msg_outer {
		max-width: 80%;
	}
	#chat .chat-message .msg_wrapper {
		margin-left: 0;
	}
}

@media screen and (max-width: 1024px) {
	#chat .card {
		max-height: calc(100vh - 120px);
		max-height: calc((var(--vh, 1vh) * 100) - 60px);
	}
	#chat #chat-wrapper {
		margin-top: 0;
	}
	#chat .msg_card_body {
		border-radius: 0 0 5px 5px;
	}
}

@media screen and (max-width: 550px) {
	#chat .msg_card_body {
		margin: 0 -20px;
	}
	#chat .chat-message .msg_outer {
		max-width: 85%;
	}
	#chat .msg_card_body {
		border-radius: 0;
	}
	#chat #chat-sendmessage {
		margin-left: -8px;
		margin-right: -8px;
	}
	#chat #chat-sendmessage textarea::placeholder {
		font-size: 12px;
	}
}
