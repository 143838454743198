.gallery {
	display: grid;
	grid-template-columns: 345px 1fr;
	grid-gap: 30px;
	padding: 40px 0;
}

.gallery img {
	width: 100%;
	display: block;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
}

.gallery-overview img {
	object-fit: cover;
}

.gallery-overview h1 {
	padding: 15px 0;
	font-weight: 400;
	line-height: 1.25;
	border-bottom: 1px solid $color-grey1;
}

.gallery-overview button, .gallery-overview a.button {
	width: 100%;
	padding: 14px 0;
	line-height: 1;
	border: none;
	border-radius: 99px;
	background-image: $gradient-coral;
	font-size: 14px;
	text-transform: uppercase;
	color: $color-white;
	font-weight: normal;
    display: inline-block;
    text-align: center;
}

.gallery-overview .sticky-item {
	position: sticky;
	top: 60px;
	.icon-streamline-icon-download-thick-bottom32x32 {
		margin-right: 3px;
	}
}

.gallery .gallery-overview__image--small {
	display: none;
}

.bonus-banner-active .gallery-overview .sticky-item {
	top: 100px;
}

.gallery-overview__details {
	display: flex;
	justify-content: space-between;
	padding: 15px 0;
}

.gallery-overview__details-item span {
	display: block;
	line-height: 1.2;
}

.gallery-overview__details-item span:first-child {
	font-size: 11px;
	text-transform: uppercase;
}

.gallery-overview__details-item span:last-child {
	font-size: 15px;
}

.gallery-listing ul {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
	grid-gap: 30px;
	li {
		position: relative;
		cursor: pointer;
		.icon {
			position: absolute;
			left: 50%;
			top: 50%;
			color: #ffffff;
			font-size: 25px;
			margin-top: -10px;
			margin-left: -10px;
		}
	}
}

@media screen and (max-height: 900px) {
	.gallery {
		grid-template-columns: 35vh 1fr;
	}
	.gallery-listing ul {
		grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
	}
}

@media screen and (max-height: 850px) {
	.gallery-overview h1 {
		font-size: 19px;
	}
}

@media screen and (max-height: 750px) {
	.gallery {
		grid-template-columns: 355px 1fr;
	}
	.gallery .gallery-overview__image--big {
		display: none;
	}
	.gallery .gallery-overview__image--small {
		display: block;
	}
}

@media screen and (max-height: 500px) {
	.gallery-overview .sticky-item {
		position: static;
	}
}

@media screen and (max-width: 1024px) {
	.gallery {
		padding-top: 20px;
		grid-gap: 20px;
	}
	.gallery-overview .sticky-item {
		top: 60px;
	}
	.bonus-banner-active .gallery-overview .sticky-item {
		top: 100px;
	}
	.gallery-listing ul {
		grid-gap: 20px;
	}
}

@media screen and (max-width: 768px) {
	.gallery {
		grid-template-columns: 1fr;
	}
	.gallery-listing ul {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.gallery .gallery-overview__image--small {
		display: block;
	}
	.gallery .gallery-overview__image--big {
		display: none;
	}
}

@media screen and (max-width: 425px) {
	.gallery-listing ul {
		grid-template-columns: 1fr 1fr;
	}
}
