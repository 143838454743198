#whatsapp-page {
	margin-top: 0px;
	h3 {
		margin-top: 15px;
	}
	h2 {
		font-weight: normal;
		margin-top: 10px;
		margin-bottom: 25px;
	}
	#whatsapp-page-content {
		#whatsapp-page-content-right {margin-left: 0px; margin-top:30px;}
		flex-direction: column;
		> div {
			width: auto;
		}
	}
	@media screen and (max-width: 600px) {
		#whatsapp-page-header {
			height: 270px;
			background-size: cover;
		}
	}
	@media screen and (max-width: 400px) {
		#whatsapp-page-header {
			background-image: url(#{$cdn}/images/static/LPCMS_FullHeader_wa_mobile.jpg);
			background-size: cover;
			height: auto;
			img {
				visibility: hidden;
				display: block;
			}
		}
	}
}
#aboutme-page {
	h2 {
		font-size: 20px;
		font-style: italic;
	}
	#aboutme-page-header {
		grid-template-columns: 1fr;
		margin-top: 0px;
		#aboutme-page-header-left {
			img {
				margin-left: 0;
				height: 52vw;
				object-fit: cover;
				max-height: 380px;
			}
		}

		#aboutme-page-header-right {
			padding: 0px 23px;
			span {
				margin-top: 15px;
				margin-bottom: 10px;
			}
			#aboutme-page-header-right-bigtext {
				font-size: 22px;
			}
			.goldline {
				margin: 11px auto 17px auto;
			}
		}
	}
	#aboutme-page-content {
		padding: 0;
		margin: 0 auto;
		margin-top: 35px;

		.aboutme-page-content-media {
			margin: 0;
			margin-top: 20px;
			width: 100%;
			grid-template-columns: 1fr;
			.aboutme-page-content-media-hashtags {
				order: 2;
				margin: 0;
				margin-top: 20px;
				width: 100%;
				font-size: 15px;
				text-align: center;
				.goldline {
					margin-right: auto;
					margin-left: auto;
				}
			}
			img {
				margin-top: 15px;
				max-width: none;
			}
			video {
				order: 1;
				width: 100%;
			}
		}
	}
	#aboutme-page-signup {
		flex-direction: column;
		padding: 10px;
		font-size: 29px;
		text-align: center;
		line-height: 42px;
		margin-top: 30px;
		a {
			margin-top: 20px;
			padding: 7px 42px;
			margin-bottom: 20px;
		}
	}
}
#welcome-page {
	margin-top:0px;
	@media screen and (max-width: 600px) {
		#welcome-page-header {
			height: 270px;
			background-size: cover;
		}
	}
	@media screen and (max-width: 400px) {
		#welcome-page-header {
			background-image: url(#{$cdn}/images/static/LPCMS_FullHeader_forced_mobile.jpg);
			background-size: cover;
			height: auto;
			img {
				visibility: hidden;
				display: block;
			}
		}
	}
	#welcome-page-content {
		text-align: left;
		picture, img {
			max-width: none;
		}
		.button {
			padding: 15px 0px;
			width: 100%;
			font-size: 18px;
			margin-bottom: 20px;
		}
		> div {
			flex-direction: column;
			.button.green {
				margin-top: 20px;
				margin-bottom: 25px;
			}
			#welcome-page-content-right {
				.checkboxes {
					flex-direction: column;
					align-items: center;
					margin-top: 10px;
					> div {
						width: 200px;
						font-size: 17px;
						margin-bottom: 5px;
						text-align: left;
					}
				}
			}
			> div {
				width: auto;
			}
		}
	}
}
#subscription-page {
	#subscription-page-header {
		img, .icon-LPCMS_FuerDichRotate {
			display: none;
		}
	}
	#subscription-page-content {
		.flex-vsplit {
			flex-direction: column;
			&:nth-child(2) {
				padding-top: 15px;
				margin-top: 0;
				border-top: 0;
			}
		}
		.handwriting {
			text-align: center;
			font-size: 27px;
			margin-bottom: 10px;
		}
		.mobile-fullwidth {
			margin-left: -29px !important;
			margin-top: 20px;
		}
		#subscription-page-subscriptions {
			width: calc(100vw - 40px);
			margin-left: -22px;
			padding: 0px 20px;
			height: auto;
			h2 {
				font-size: 24px;
				width: auto;
			}
			#subscription-page-subscriptions-list {
				flex-direction: column;
				.subscription {
					width: 100%;
					height: 300px;
					margin-bottom: 25px;
					.subscription-letter {
						float: left;
						margin-top: 20px;
						margin-left: 60px;
						font-size: 60px;
					}
					.subscription-title {
						margin-bottom: 45px;
						margin-top: 36px;
						text-align: left;
						margin-left: 125px;
						font-size: 18px;
						line-height: 22px;
					}
					.subscription-amount {
						font-size: 19px;
					}
					.subscription-description {
						padding: 0px 6%;
						font-size: 15px;
						margin-bottom: 10px;
					}
					&.highlight {
						width: 100%;
						height: 300px;
						.subscription-letter {
							margin-top: 20px;
							margin-left: 50px;
						}
					}
					&:last-child {
						margin-bottom: 45px;
					}
				}
			}
		}
	}
	#subscription-page-footer {
		padding: 20px 0% 0px 0%;
		font-size: 17px;
		margin-top: 20px;
	}
}
#legal {
	margin: 0 0;
	h2 {
		font-size: 19px;
	}
		ul {
		margin: 0 0 25px 0;
	}
}
#telegram-page {
	margin-top: 0px;
	.button.lightblue {
		padding: 17px 0px;
		width: 100%;
		text-align: center;
	}
	.offset-wrapper {
		padding: 0px 0px 40px 0px;
	}
	#telegram-page-content {
		flex-direction: column;
		h2 {
			font-size: 22px;
			width: auto;
		}
		#telegram-page-content-left {
			padding: 0;
			margin: 0;
		}
		> div {
			width: auto;
		}
	}
	#telegram-page-bottom {
		display: block;
		padding-right: 0;
		background: none;
		margin-top: 0;
		#telegram-page-accordion {
			width: calc(100vw - 30px) !important;
		}
	}
	#telegram-page-premiumbanner {
		margin: 20px 0px 0px 0px;
		div {
			width: 100%;
			&:nth-child(2) {
				display: none;
			}
		}
	}
	@media screen and (max-width: 600px) {
		#telegram-page-header {
			height: 270px;
			background-size: contain;
		}
	}
	@media screen and (max-width: 400px) {
		#telegram-page-header {
			background-image: url(#{$cdn}/images/static/telegram/LPCMS-v2-Members_Telegramfree.jpg);
			background-size: contain;
			height: auto;
			img {
				visibility: hidden;
				display: block;
			}
		}
	}
}