#siteheader-top {
	display: flex;
	position: relative;
	align-items: center;
	height: 160px;
	border-bottom: 1px solid $color-grey2;
	> div {
		flex: 1;
	}
	#siteheader-isonline {
		position: absolute;
		right: 0;
	}
}
#siteheader-navi {
	position: relative;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: normal;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 80px;
	.sale--video {
		position: absolute;
		top: -10px;
		right: -20px;
		padding: 5px 8px 3px 8px;
		font-size: 10px;
		letter-spacing: 0.5px;
		font-weight: 600;
		transform: rotate(30deg);
	}
	#siteheader-logo {
		line-height: 1;
		.icon {
			font-size: 35px;
		}
	}
	ul.menu li a {
		font-size: 14px;
		padding: 0px 15px;
		font-weight: 400;
		position: relative;
		&:hover {
			color: $color-coral;
		}
	}
	#siteheader-navi-signuplink, #siteheader-navi-loginlink {
		cursor: pointer;
		font-size: 14px;
		padding: 8px 16px;
		border-radius: 99px;
	}
	#siteheader-navi-loginlink {
		right: 0px;
		font-weight: 400;
		color: $color-dark;
		&:hover {color: $color-coral;}
	}

	#siteheader-navi-signuplink {
		right: 100px;
		font-weight: 600;
		background-image: $gradient-coral;
		color: $color-white;
	}

	.btn-headermenu-loggedin {
		font-size: 24px;
		position: relative;
	}
	a, .btn-headermenu-loggedin {
		text-transform: uppercase;
	}
	#siteheader-navi-btn-wrapper {
		display: flex;
	}
	#siteheader-navi-btn-wrapper-loggedout {
		display: flex;
	}
	#siteheader-navi-btn-mail {
		.badge {
			display: none;
			top: 9px;
			right: 9px;
		}
	}
}

.landingpage header {
	box-shadow: none;
}

header {
	position: sticky;
	top: 0;
	z-index: 99;
	background: rgba(255,255,255,1);
	left: 0;
	right: 0;
	margin: 0 auto;
	box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
}
#site-wrapper {
	position: relative;
	transition: 0.45s ease-in-out;
}

@media screen and (max-width: 1104px) {
	#siteheader-navi {
		padding: 0;
    max-width: 984px;
	}
}

@media screen and (max-width: 1100px) {
	#siteheader-navi #siteheader-navi-loginlink {
		margin-left: 10px;
	}
	#siteheader-navi ul.menu li a {
		padding: 0 10px;
	}
}

@media screen and (max-height: 900px) {
	header {
		position: relative;
		top: unset;
	}
}
