#modal-payment {
	.modal-box {
		display: flex;
		min-height: 100vh;
		height: auto;
		width: auto;
		.modal-body {
			height: auto;
			display: flex;
			#modal-payment-maincontent {
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				padding-top: 20px;
				.lpmodal-left {
					height: auto;
					display: flex;
					display: block;
					background-image: none;
					text-align: center;
					height: auto;
					width: auto;
					img {
						clear: left;
					}
					h3 {
						margin-left: 20px;
						font-weight: 600;
						margin-bottom: 20px;
						@media screen and (max-width: 425px) {
							float: left;
						}
					}
				}
				#modal-payment-payment-forms {
					#modal-payment-forms-backbtn {
						position: absolute;
						right: 20px;
						top: -40px;
						left: auto;
						color: $color-dark;
					}
				}
			}
			.lpmodal-footer {
				position: relative;
				width: auto;
				#modal-payment-trustelements {
					justify-content: space-between;
					.lpmodal-payment-trustelement {
						margin-right: 0;

					}
				}
				flex-direction: column;
				padding: 40px 20px;
				#modal-payment-footertext {
					margin-top: 15px;
				}
			}
		}
	}
}
#modal-payment-done .modal-box, #modal-payment-error .modal-box {
	width: 100%;
	height: 100vh;
	.modal-body {
		.bgimg {
			background-size: 180vw auto;
			background-position: center;
			@media screen and (min-width: $break-ipad) {
				background-size: 120vw auto;
			}
		}
		#modal-payment-done-subtext, #modal-payment-error-subtext {
			width: 85%;
			font-weight: normal;
		}

		#modal-payment-done-backlink {
			font-weight: 600;
		}
	}
}