#members {
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	img {
		display: block;
	}
	#memberslider {
		img, picture {
			width: 100%;
		}
	}
	h2 {
		font-size: 24px;
		margin-bottom: 30px;
	}
	h3 {
		font-size: 24px;
		margin-bottom: 15px;
	}
	#member-subtext {
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;
		margin-top: 30px;
		> div {
			width: 49%;
			img {
				max-width: 100%;
			}
		}
		#member-subtext-left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.member-subtext-left_text {
				height: 100%;
    		display: flex;
    		flex-direction: column;
    		justify-content: center;
			}
			#member-whatsapp-desktop {
				height: 100%;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: left;
					border-radius: 5px;
				}
			}
		}
		#member-subtext-right {
			img {
				min-height: 265px;
				object-fit: cover;
				border-radius: 5px;
			}
			position: relative;
			ul.medialist {
				height: 100%;
				align-items: flex-end;
				grid-template-columns: 1fr;
				margin-bottom: 0;
				.medialist__item {
					width: 100%;
					max-width: none;
					margin: 0;
				}
				.medialist__item-categories, .medialist__item-title, .medialist__item-details {
					display: none;
				}
				.medialist__item-image {
					img, video, a {
						width: 100%;
						max-height: none;
					}
					.icon {
						display: none;
					}
				}
			}
			#video-of-week-teaser {
				text-transform: uppercase;
				font-weight: 600;
				margin-bottom: 5px;
				font-size: 12px;
			}
			#video-of-week-title {
				font-size: 16px;
			}
			#video-of-week-details {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				color: $color-white;
				font-size: 14px;
				position: absolute;
				border-radius: 5px;
				bottom: 0;
				left: 0;
				right: 0;
				padding: 20px;
				z-index: 2;
				background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 70%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 70%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#99000000',GradientType=0 ); /* IE6-9 */

				.button {
					padding: 12px 24px;
					line-height: 1;
					font-weight: 400;
				}
				#video-of-week-save {
					font-size: 12px;
					text-align: center;
					display: flex;
					justify-content: flex-end;
					flex-direction: column;
					align-items: center;
					white-space: nowrap;
					margin-left: 12px;
					> div {
						margin-top: 5px;
					}
				}
			}
		}
	}
	#member-videocategories {
		padding-top: 60px;
		margin-top: 60px;
		background-color: $color-grey1;
	}
}

@media screen and (max-width: $break-desktop) {
	#members {
		margin-top: 0px;
		h3 {
			margin-bottom: 10px;
		}
		h2 {
			font-size: 20px;
			margin-top: 25px;
		}
		#member-subtext {
			flex-direction: column;
			> div {
				width: 100%;
			}
			#member-subtext-right {
				margin-top: 20px;
				img {
					width: 100%;
					min-height: 220px;
				}
				#video-of-week-teaser {
					font-size: 14px;
				}
				#video-of-week-title {
					font-size: 17px;
				}
				.phone-fullwidth {
					ul.medialist {
						.medialist__item-image {
							img, video, a {
								width: 140%;
								margin-left: -20%;
								max-width: none;
							}
						}
					}
				}
				#video-of-week-details {
					flex-direction: column;
					width: calc(100% - 40px);
					padding: 20px;
					text-align: center;
					.button {
						margin: 5px;
					}
					> div {
						width: 100%;
					}
					#video-of-week-save {
						width: 100%;
						margin-left: 0;
					}
				}
			}
		}
		#member-whatsapp-mobile {
			position: relative;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			a {
				position: relative;
				display: block;
				height: 100%;
				width: 100%;
			}
		}
		#member-videocategories {
			padding-top: 25px;
		}
	}
}
@media screen and (max-width: 320px) {
	#members #member-videos-new ul li:nth-child(1) {
		grid-column: 1;
	}
}
._container_stickybanner {
	position: relative;
    background: #fff;
    box-sizing: inherit;
		display: block;
		background-image: url(#{$cdn}/images/static/members/aktionen/lpcms--bonus_slim-bg.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}

._content_stickybanner {
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 2.1px;
	color: #fff;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

._img_stickybanner {
    max-height: 100%;
}


._link_stickybanner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: -1;
		text-decoration: none;
    color: inherit;
}

._container_gradient{
	background-color: $color-dark;
	width: 100%;
}

._sticky-button {
    color: #deff00;
		text-decoration: underline;
		margin-left: 15px;
		font-weight: 600;
}

@media screen and (max-width: $break-desktop) {
	._img_stickybanner {
		max-width: 90%;
	}
}
.aktionspage {
	font-family: "Poppins", sans-serif;
	background-size: contain;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	width: 100vw;
	margin-left: calc(((100vw - 100%)/2)*-1);
	background-size: 100%;

	&#silvester, &#newyear {
		.aktionspage-centered {
			background-color: transparent;
			top: 190px;
			height: calc(100% - 190px);
			width: 60%;
			top: 0;
			transform: translate(-50%);
			@media screen and (max-width: 1650px) {
				height: calc(100% - 90px);
			}
			img {
				max-width: 1040px;
				display: block;
				width: 100%;
				margin: 0 auto;
			}
			.button {
				text-transform: uppercase;
				background-image: linear-gradient(82deg, #921725 0%, #b82334 50%);
				box-shadow: 0px 2px 15px 0px #b82334;
				font-weight: 400;
				padding: 3px 24px 0 24px;
				height: 44px;
				margin-top: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50px;
				width: 245px;
				font-size: 18px;
				margin: 0 auto;
				letter-spacing: 0.54px;
			}
			.aktionspage-bottomtext {
				bottom: 30px;
				margin-bottom: 0;
				width: 100%;
				.aktionspage-memberlink {
					color: rgba(226, 226, 226, 0.719);
					text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
				}
			}
		}
	}
	&#newyear {
		.aktionspage-centered {
			top: 10%;
			height: calc(100% - 30px);
			span {
				color: #B82334;
				font-size: 18px;
				letter-spacing: 18px;
				text-indent: 18px;
				display: block;
				text-transform: uppercase;
				font-weight: 600;
				margin-bottom: 40px;
			}
			h1 {
				color: #fff;
				font-size: 100px;
				text-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
				margin-bottom: 10px;
			}
			h2 {
				text-transform: uppercase;
				font-size: 36px;
				letter-spacing: 24px;
				text-indent: 24px;
				text-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
				font-weight: 400;
				margin-bottom: 10px;
			}
		}
		.aktionspage-bgimg {
			width: 100vw;
			height: 100%;
			max-height: 95vh;
			display: block;
			object-fit: cover;
			object-position: bottom;
		}
	}
	.aktionspage-bgimg {
		width: 100vw;
		height: 100%;
		display: block;
	}
	h3 {
		color: $color-leonie-red;
		letter-spacing: 20px;
		font-size: 18px;
		margin-top: 30px;
	}
	h2 {
		line-height: 1.4;
		font-size: 32px;
		color: white;
	}
	.aktionspage-description {
		text-align: center;
		background-color: rgba(5, 5, 5, 0.65);
		.aktionspage-topimage {
			max-width: 480px;
			width: 50%;
			margin-top: 15px;
		}
		.aktionspage-description-top {
			padding: 20px 60px 30px 60px;
		}
	}
	.aktionspage-centered {
		max-width: 800px;
		width: 60%;
		position: absolute;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.button {
			background: $color-leonie-red;
			padding: 14px 80px 12px 80px;
			border-radius: 5px;
			font-size: 18px;
			display: inline-block;
			margin-top: 15px;
		}
	}
	.aktionspage-memberlink {
		color: rgba(255, 255, 255, 0.8);
		font-size: 16px;
	}
	.aktionspage-bottomtext {
		margin: 20px auto 0 auto;
	}
}
.noadvent {
	#adventskalender {
		background: linear-gradient(243deg,#2e4553,#070f1c);
	}
	#advent-topl, #advent-bottomr, .advent-headerimage {
		display: none;
	}
}
#adventskalender {
	font-family: "Poppins", sans-serif;
	background: linear-gradient(243deg, #2E4553 0%, #070F1C 100%);
	background-image: url(#{$cdn}/images/static/lpcms_sectionbg--xmas.jpg);
	&.silvester {
		background-image: url(#{$cdn}/images/static/lpcms_sectionbg--countdown-2.jpg);
		#advent-description {
			#advent-description-top {
				h3 {
					color: #ffffff;
				}
			}
		}
	}
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
  margin-left: calc(((100vw - 100%)/2)*-1);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	.lpcms-player .video-js.vjs-default-skin .vjs-control-bar {
		display: none;
	}
	.medialist__item-image {
		img, video {
			max-height: none;
		}
	}
	.sale--video {
		background-image: linear-gradient(82deg, #921725 0%, #b82334 50%);
	}
	.button {
		display: block;
		width: 220px;
		font-size: 16px;
		line-height: 1;
		margin: 0 auto;
		padding: 14px 28px 14px 28px;
		border-radius: 50px;
		letter-spacing: 0.54px;
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
	}
	.advent-videopackimg {
		width: 25%;
		position: absolute;
		left: 42%;
		bottom: -6%;
		z-index: 3;
	}
	ul.medialist {
		display: grid;
		grid-template-columns: 2.062fr 1fr;
		grid-template-rows: 1fr 1fr;
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		max-width: 1040px;
		margin-bottom: 0;
		margin-top: 40px;
		li.medialist__item {
			margin-bottom: 0px;
			width: 100%;
			margin-right: 0;
			position: relative;
			background-color: transparent;
			.medialist__item-infos {
				padding: 0;
			}
			.aspect-ratio-259-460 {
				height: 100%;
				padding-top: 0;
				overflow: visible;
				.aspect-ratio-inner {
					position: relative;
					width: auto;
					height: auto;
				}
			}
			&:nth-child(1) {
				max-width: 100%;
				height: 100%;
				grid-row: 1 / span 2;
				.medialist__item-image {
					height: 100%;
					img, a, video {
						max-height: 100%;
						height: 100%;
					}
				}
				img, a {
					max-height: 100%;
				}
			}
			.medialist__item-categories, .medialist__item-details {
				display: none;
			}
			.medialist__item-title {
				text-align: left;
				border-bottom: 0;
				background-color: rgba(16, 16, 20, 0.25);
				padding: 8px;
				height: 42px;
				position: absolute;
				z-index: 2;
				bottom: 0px;
				left: 0px;
				right: 0px;
				a {
					color: #ffffff;
					font-size: 15px;
					height: auto;
					font-weight: 300;
					max-width: 295px;
				}
			}
		}
	}
	#advent-topl {
		background-image: url(#{$cdn}/images/static/lpcms-xmas--cornerL.png);
		position: absolute;
		top: 0;
		left: 0;
		width: 518px;
		height: 382px;
		z-index: 1;
	}
	#advent-bottomr {
		background-image: url(#{$cdn}/images/static/lpcms-xmas--cornerR.png);
		position: absolute;
		bottom: 0;
		right: 0;
		width: 518px;
		height: 382px;
		z-index: 1;
	}
	#advent-mainimage {
		position: relative;
		max-width: 900px;
		width: 50%;
		min-width: 650px;
		display: flex;
		justify-content: center;
		.lpcms-player {
			width: 100%;
			.vjs-big-play-button {
				font-size: 110px;
			}
		}
		video:focus {
			outline: none;
		}
	}
	#advent-description {
		max-width: 900px;
		width: 50%;
		min-width: 650px;
		text-align: center;
		h3 {
			color: $color-dark;
		}
		#advent-description-top {
			padding: 40px;
			h3 {
				letter-spacing: 14px;
				font-size: 14px;
				text-transform: uppercase;
				font-weight: 600;
			}
			h2 {
				line-height: 1.25;
				font-size: 24px;
				font-weight: 400;
				margin-top: 8px;
				color: #FFFFFF;
			}
		}
		#advent-description-bottom {
			.button {
				text-transform: uppercase;
				background-image: linear-gradient(82deg, #ffffff 0%, #ffffff 100%);
				font-weight: 600;
				color: #d83034;
			}
			#advent-memberlink {
				color: #ffffff;
				font-size: 12px;
				display: inline-block;
				margin-top: 30px;
				margin-bottom: 100px;
			}
		}
	}
	.advent-starter-door {
		width: 95vh;
		max-width: 900px;
		min-width: 650px;
		position: relative;
		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}
	#advent-starter-bottomtext {
		color: $color-white;
		font-size: 17px;
		text-align: center;
		max-width: 600px;
		line-height: 26px;
		margin-bottom: 100px;
		font-weight: 300;
	}
	#advent-starter-centered {
		position: absolute;
		top: 42%;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		left: 50%;
		transform: translate(-50%, -55%);
		.button {
			background: rgb(146,23,37);
			background: linear-gradient(82deg, rgba(146,23,37,1) 0%, rgba(184,35,52,1) 100%);
			box-shadow: 0 2px 15px #B82334;
			padding: 16px 32px 13px 32px;
		}
		#advent-starter-daynumber {
			font-size: 210px;
			color: white;
			font-weight: 600;
		}
	}
}
.advent-headerimage {
	min-width: 300px;
	max-width: 360px;
	width: 25%;
	margin-bottom: -60px;
	margin-top: 5px;
	z-index: 1;
	position: relative;
	img {
		width: 100%;
	}
	.advent-headerimage-day {
		font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    position: absolute;
    right: 15%;
    top: 5%;
    width: 50px;
    text-align: center;
	}
}
#cam-menu-top-right {
	display: flex;
	justify-content: space-between;
    width: 490px;
    align-items: center;
	a {
		color: #101014;
		text-align: center;
		text-decoration: none;
		position: relative;
		display: inline-block;
    	margin-bottom: -5px;
  	}

	a:hover, a.active {
		font-weight: 600;
		&:after {
			width: 38px;
			height: 2px;
			background-color: $color-coral;
			border-radius: 3px;
			content: " ";
			position: absolute;
			left: 50%;
			margin-left: -15px;
			bottom: -3px;
		}
	}
	li {
		display: inline-block;
	}
}
#mainlisting-cams {
	input[type=checkbox] {
		display: none;
	}
	input[type=checkbox] + label::before {
		display: none;
	}
	:checked + label.cam_filter {
		background: $color-dark !important;
		color: $color-white !important;
		&:after {
			content: "\e939";
			font-family: "puricons" !important;
			padding: 0px 5px;
			font-size: 10px;
			margin-left: 3px;
			margin-right: -5px;
		}
	}
	label.cam_filter {
		padding: 3px 8px;
		display: inline-block;
		margin-top: 6px;
		margin-right: 4px;
		font-size: 17px;
		white-space: nowrap;
		background-color: $color-grey1;
		cursor: pointer;
		&.active, &:hover {
			background: $color-dark;
			color: $color-white;
			&.active:after {
				content: "\e939";
				font-family: "puricons" !important;
				padding: 0px 5px;
				font-size: 10px;
				margin-left: 3px;
				margin-right: -5px;
			}
		}
	}
	#cam-filters-active {
		margin-bottom: 20px;
		span {
			margin-right: 5px;
			font-size: 17px;
		}
	}

	#cam-filter-menu {
		background-color: #F8F8F8;
		box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 5px;
		padding: 30px;
		margin-bottom: 30px;
		position: relative;
		#cam-filter-menu-features {
			display: flex;
			flex-direction: column;
			margin-right: 40px;
			margin-left: 20px;
			.icon {
				margin-right: 5px;
			}
			a {
				width: 136px;
				height: 46px;
				border-radius: 3px;
				color: $color-white;
				background-image: url(#{$cdn}/images/static/categories/cat_bg_allevideo.png);
				background-repeat: no-repeat;
				background-size: contain;
				display: inline-block;
				padding: 12px;
				margin-bottom: 15px;
				&:nth-child(2) {
					background-image: url(#{$cdn}/images/static/categories/cat_bg_topvideos.png);
				}
			}
		}
		#cam-filter-mobile-close {
			.vertical-banner {
				color: $color-coral;
				font-size: 17px;
				letter-spacing: 1px;
				width: 108px;
				position: absolute;
				left: 0px;
				top: 62px;
				text-align: right;
				text-transform: uppercase;
			}
			.close-icon {
				display: none;
			}
		}

		.cam-filter-menu-categories {
			margin-right: 30px;
			h4 {
				font-size: 12px;
				font-weight: normal;
			}
			&:nth-child(1) {
				margin-left: 40px;
			}
			&:nth-child(4) {
				min-width: 270px;
			}
			label.cam_filter {
				margin-right: 15px;
				margin-bottom: 1px;
			}
			div {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				max-height: 240px;
				flex-wrap: wrap;
			}
		}
		.cam-categories {
			display: flex;
			flex-wrap: wrap;
			margin-left: 50px;
		}
		.filter_container {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			justify-content: center;
			align-items: flex-end;
			.cam_filter_dropdown, .cam_filter_delete {
				box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 5px;
				border-radius: 3px;
				padding: 8px 0;
				margin-bottom: 10px;
				font-size: 15px;
				cursor: pointer;
				border: 0;
				width: 265px;
				.icon {
					font-size: 15px;
					margin-right: 8px;
					cursor: pointer;
				}
				&.toggletrigger.active .icon {
					color: $color-white;
				}
			}
			.cam_filter_dropdown {
				background-color: $color-coral;
				color: $color-white;
			}
			.cam_filter_delete {
				background-color: #F1F1F1;
				color: $color-dark;
			}
		}


		.searchform {
			position: relative;
			width: 454px;
			img {
				margin-top: 30px;
				margin-bottom: -6px;
			}
			.icon {
				position: absolute;
				right: 12px;
				top: 12px;
				font-size: 19px;
				cursor: pointer;
			}
		}
		input.searchbox {
			height: 43px;
			width: 430px;
		}
	}
}
.lSSlideWrapper .lSAction {
	.lSPrev, .lSNext {
		font-family: 'puricons' !important;
		speak-as: never;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 50px;
		padding: 25px 15px 25px 3px;
		color: rgba(255, 255, 255, 0.5);
		height: 50px;
		opacity: 1;
		background: rgba(16, 16, 20, 0.5);
		margin-top: -50px;
	}
	.lSPrev {
		left: 0;
		&:before {
			content: "\e91e";
		}
	}
	.lSNext {
		left: calc(100% - 45px);
		&:before {
			content: "\e91f";
		}
	}
}

/*deprecated*/
#members #memberslider img {
	display: none;
}
#members .lSSlideWrapper #memberslider img {
	display: inline-block;
}
@media screen and (max-width: $break-phone) {
	#members #memberslider {
		min-height: 450px;
	}
}
/*end*/

.member-slider-wrapper {
	min-height: 200px;
}
@media screen and (min-width: 1500px) {
	.member-slider-wrapper {
		min-height: 400px;
	}
}
@media screen and (max-width: $break-phone) {
	.member-slider-wrapper {
		min-height: 450px;
	}
}
.member-slider-wrapper {
	.member-slider img {
		display: none;
		width: 100%;
	}
	.lSSlideWrapper {
		.member-slider img {
			display: block;
		}
		.lSAction {
			.lSPrev, .lSNext {
				background: none;
			}
		}
	}
}
.lSSlideOuter .lSPager.lSpg {
	margin: -30px 0 0 0 !important;
    padding-bottom: 10px !important;
	> li {
		font-size: 0;
		line-height: 0;
		display: block;
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		border-radius: 2px;
		background: #fff;
		opacity: .6;
		height: 5px;
		width: 24px;
		margin-right: 10px;
		&.active {
			opacity: 0.99;
		}
		a {
			display: none !important;
		}
	}
}