#videopage-container {
	&.bought {
		#videopage-metadata {
			div:nth-child(2) {
				visibility: hidden;
			}
		}
	}
	margin-top: 40px;
	&.theater-mode {
		#videopage-media {
			width: 100%;
			margin-bottom: 20px;
		}
	}
	h1 {
		font-size: 24px;
		line-height: 1.4;
		margin-bottom: 20px;
		margin-top: 10px;
		font-weight: 600;
	}
	#videopage-buyvideo {
		color: #ffffff;
		background-image: $gradient-coral;
		font-size: 22px;
   		padding: 16px 80px;
		margin-top: -34px;
		z-index: 11;
		border: 0;
		border-radius: 99px;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	#videopage-media {
		width: calc(100% - 365px);
		float: left;
		margin-bottom: 20px;
		.addcomment {
			background-color: #1e94a0;
			padding: 18px 64px 16px 64px;
			font-size: 17px;
			font-weight: 600;
			margin-bottom: 10px;
			border-radius: 5px;
			color: $color-white;
		}
		#video-preview-mainimage {
			position: relative;
			.vjs-has-started {
				width: auto;
				height: auto;
			}
			.sale--video {
				font-size: 24px;
				padding: 10px 18px 10px 18px;
				top: 20px;
				right: 20px;
			}
			a {
				display: block;
			}
			img, video {
				width: 100%;
				vertical-align: middle;
			}
			video {
				object-fit: cover;
				border-radius: 5px;
				box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
			}
			&.is-vertical {
				text-align: center;
				background-color: #000000;
				border-radius: 5px;
				video {
					object-fit: contain;
					max-height: 600px;
				}
			}
		}
	}
	#videopage-videodata {
		width: calc(100% - 395px);
		float: left;
		.icon {
			display: inline-block;
			margin-right: 3px;
			font-size: 18px;
			color: #8A96A3;
		}
		#videopage-information {
			display: grid;
			grid-gap: 30px;
			grid-template-columns: 1fr 270px;
			#videopage-description {
				font-size: 16px;
				font-weight: 300;
				line-height: 24px;
			}
			#videopage-meta-right {
				> div {
					margin-bottom: 30px;
					display: flex;
					flex-wrap: wrap;
					align-content: center;
				}
				#videopage-metadata {
					display: flex;
					font-size: 14px;
					font-weight: 300;
					div {
						&:first-of-type {
							margin-right: 20px;
						}
						&.rebated {
							position: relative;
							.metadata-title {
								text-transform: none;
								color: $color-dark;
								.sale--video {
									font-size: 10px;
									position: absolute;
									letter-spacing: normal;
									padding: 7px 9px 5px 9px;
									top: -30px;
									left: 0;
									display: inline-block;
								}
								span {
									text-decoration: line-through;
									text-decoration-color: $color-red;
								}
							}
						}
						.metadata-title {
							font-size: 10px;
							font-weight: 600;
							text-transform: uppercase;
							margin-bottom: 6px;
						}
					}
				}
				#videopage-metadata-categories {
					.category {
						font-size: 12px;
						padding: 4px 16px;
						background-color: #F5F6FA;
						color: $color-dark;
						border-radius: 50px;
						font-weight: 600;
						margin-right: 5px;
						margin-bottom: 5px;
					}
				}
				#videopage-metadata-hashtags {
					.hashtag {
						font-size: 13px;
						font-weight: 600;
						margin-right: 6px;
					}
				}
			}
		}
		#videopage-commentcontainer {
			h2 {font-size: 18px;}
			background-color: #F5F6FA;
			padding: 30px;
			margin: 40px 0;
			@media screen and (min-width: $break-desktop) {
				display: block !important;
				border-radius: 5px;
			}
			.addcomment-notice {
				font-size: 12px;
				margin-top: 15px;
				margin-bottom: 10px;
			}
			form#video-add-comment {
				display: flex;
				position: relative;
				.icon-streamline-icon-send-email18x18 {
					position: absolute;
					bottom: 20px;
					right: 20px;
					cursor: pointer;
					background: none;
					border: none;
					font-size: 24px;
					color: #8A96A3;
					padding: 0;
					margin-right: 0;
				}
			}
			textarea {
				border-radius: 5px;
				border: none;
				width: 100%;
				box-sizing: border-box;
				resize: none;
				min-height: 60px;
				padding: 18px 65px 18px 20px;
				font-family: "Poppins", sans-serif;
				font-size: 16px;
				line-height: 24px;
				font-weight: 300;
				color: $color-dark;
			}
			.videopage-comment-author {
				font-size: 11px;
				margin-bottom: 4px;
				font-weight: 600;
				span {
					font-weight: normal;
				}
			}
			.videopage-comment-content {
				font-weight: 300;
				line-height: 24px;
			}
			.videopage-comment {
				border-bottom: 1px solid #E1E1E1;
				padding-bottom: 20px;
				margin-top: 20px;
			}
			.videopage-comment-reply {
				display: flex;
				padding-left: 15px;
				margin-top: 15px;
				.videopage-comment-reply-author-image {
					background-image: url(#{$cdn}/images/static/leonie-profile-small-1.jpg);
					min-width: 32px;
					height: 32px;
					background-size: 32px;
					background-repeat: no-repeat;
					margin-right: 10px;
					border-radius: 99px;
				}
				.videopage-comment-reply-author {
					font-size: 11px;
					margin-bottom: 4px;
					font-weight: 600;
					color: $color-dark;
				}
				.videopage-comment-reply-content {
					font-weight: 300;
    			line-height: 24px;
				}
			}
		}
	}
	#videopage-recommendations {
		float: right;
		width: 330px;
		.medialist {
			grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
		}
		li.medialist__item {
			margin-right: 0;
			@media screen and (min-width: 1025px) {
				width: 100%;
			}
			.medialist__item-image img, .medialist__item-image video {
				width: 100%;
			}
		}
	}

	#videopage-player {
		width: 100%;
		margin-bottom: 20px;
		&.is-vertical {
			.video-js.vjs-fluid,
			.video-js.vjs-16-9,
			.video-js.vjs-4-3,
			video.video-js,
			video.vjs-tech {
				max-height: calc(100vh - 120px);
				position: relative !important;
				width: 100%;
				height: auto !important;
				max-width: 100% !important;
				padding-top: 0 !important;
				line-height: 0;
			}
			.vjs-control-bar {
				line-height: 1;
			}
			.vjs-fullscreen {
				.video-js.vjs-fluid,
				.video-js.vjs-16-9,
				.video-js.vjs-4-3,
				video.video-js,
				video.vjs-tech {
					max-height: calc(100vh);
				}
			}
		}
		#videopage-player-video {
			width: 100%;
		}

		ul {
			li {
				display: list-item;
			}
		}
	}
}
#modal-buy-video .modal-box, #modal-buy-gallery .modal-box {
	height: auto;
	margin-top: 100px;
	width: 675px;
	#modal-buy-video-topimage, #modal-buy-gallery-topimage {
		width: 160px;
		height: 160px;
		position: absolute;
		top: -80px;
		left: 50%;
		margin-left: -80px;
	}
	.modal-body {
		padding: 70px 54px 17px 54px;
		background-image: url(#{$cdn}/images/static/Kleenex_Box.png);
		background-repeat: no-repeat;
		background-position: bottom right;
		background-position-x: -5px;
    	background-position-y: -30px;
	}
	.close-modal {
		text-align: left;
	}
	h1 {
		font-size: 62px;
		height: auto;
	}
	button {
		background-color: $color-gold;
		font-weight: 600;
		box-shadow: $color-grey1 0px 1px 5px;
		color: $color-white;
		border-radius: 3px;
		border: 0;
		cursor: pointer;
		font-family: "Poppins", sans-serif;
		text-align: center;
		padding: 12px 62px;
		font-size: 17px;
		margin-top: 17px;
		margin-bottom: 40px;
	}
}

#videopage-container #videopage-videodata #videopage-commentcontainer form#video-add-comment textarea:focus {
	outline: none;
	+ button.icon-streamline-icon-send-email18x18 {
	color: $color-coral;
	}
}

#download-video {
	display: none;
}

#videopage-media {
	#video-preview-mainimage img {
		border-radius: 5px;
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
	}
	.lSSlideOuter {
		border-radius: 5px;
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
	}
}

#videopage-container.gehirnjogging-landing {
	#videopage-media, #videopage-videodata {
		width: 100%;
		float: none;
		max-width: 1000px;
		margin: 0 auto;
	}	
	.centered {
		margin-bottom: 20px;
	}
	#videopage-videodata #videopage-information {
		display: block;
	}
	#videopage-buyvideo {
		max-width: 440px;
	}
}

@media screen and (max-width: 1350px) {
	#videopage-container #videopage-videodata #videopage-information {
		grid-template-columns: 1fr;
		grid-gap: 40px;
		#videopage-meta-right #videopage-metadata {
			justify-content: flex-start;
			div {
				margin-right: 20px;
			}
		}
		#videopage-meta-right {
			display: grid;
			grid-template-columns: 200px 1fr;
			grid-gap: 50px;
			div {
				margin-bottom: 0;
			}
		}
	}
}

@media screen and (max-width: 1100px) {
	#videopage-container #videopage-videodata #videopage-information {
		#videopage-meta-right {
			grid-template-columns: 1fr;
			grid-gap: 25px;
		}
	}
}

@media screen and (max-width: 1024px) {
	#videopage-container #videopage-videodata #videopage-information {
		#videopage-meta-right {
			grid-template-columns: 200px 1fr;
			grid-gap: 50px;
			margin-bottom: 30px;
		}
	}
	#videopage-container #videopage-media #video-preview-mainimage {
		&.is-vertical {
			border-radius: 0;
			box-shadow: none;
		}
		video {
			border-radius: 0;
			box-shadow: none;
		}
	}
	#videopage-media {
		#video-preview-mainimage img {
			border-radius: 0;
			box-shadow: none;
		}
		.lSSlideOuter {
			border-radius: 0;
			box-shadow: none;
		}
	}
}

@media screen and (max-width: 768px) {
	#videopage-container #videopage-videodata #videopage-information {
		#videopage-meta-right {
			grid-template-columns: 1fr;
			grid-gap: 25px;
		}
	}
}

@media screen and (max-width: 375px) {
	#videopage-container #videopage-videodata #videopage-information #videopage-meta-right #videopage-metadata div {
		margin-right: 10px;
	}
}

/*


	#videoplayer.theater-mode {
		position: absolute;
		padding: 1%;
		left: 0px;
		width: 98%;
		height: 98%;
		top: 0px;
		background: rgba(0,0,0,0.7);
		z-index: 10;
	}
	.theater-mode #lp-mainvid {
		flex: 1;
		height: 98%;
		padding-top: 0;
	}*/
