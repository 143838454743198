.lvrs i {
	font-size: 24px;
	color: #8A96A3;
}

.lvrs p {
	font-weight: 300;
	line-height: 24px;
	margin-bottom: 20px;
}

.lvrs p:last-of-type {
	margin-bottom: 0;
}

.lvrs {
	display: grid;
	grid-template-columns: 1fr minmax(300px, 30.556%);
	grid-column-gap: 40px;
	margin-bottom: 30px;
	margin-top: 30px;
	grid-template-areas: "stream sidebar";
	max-width: 800px;
	&.content-limited {
		min-width: unset;
		@media screen and (max-width: 1024px) {
			max-width: 460px;
		}
	}
}

.lvrs__stream {
	grid-area: stream;
	.video-js .vjs-progress-control:hover .vjs-play-progress .vjs-time-tooltip {
		display: none;
	}
}

.lvrs__sidebar {
	grid-area: sidebar;
}

.lvrs__sidebar-logo {
	position: absolute;
	top: 0;
	width: 100%;
	box-sizing: border-box;
	padding: 6.82%;
	text-shadow: 2px 2px 10px rgba(0,0,0,0.5);
	.icon-Lover_Logo-BeMy-Main {
		color: $color-white;
		font-size: 48px;
	}
}

.lvrs img {
	max-width: 100%;
	display: block;
}

.lvrs {
	.lSSlideWrapper .lSAction .lSPrev, .lSSlideWrapper .lSAction .lSNext {
		font-size: 45px;
		display: inline-table;
		height: 0;
		padding: 30px 0px 30px 0px;
		color: rgba(255, 255, 255, 0.7);
		margin-top: -8px;
		transform: translate(0, -50%);
	}
	.lSSlideOuter .lSPager.lSpg {
		margin: -35px 0 0 0 !important;
		padding-bottom: 15px !important;
		> li {
			width: 12px;
			margin-right: 4px;
			opacity: 0.5;
		}
	}
	.show-more {
		width: 110px;
		display: block;
		margin: 0 auto;
		text-align: center;
	}
}

.lvrs .premium-audio {
	width: 100%;
	background-color: $color-coral;
	display: flex;
	height: auto;
	border-radius: 5px;
	background-image: url(#{$cdn}/images/static/speech_bg.jpg);
	background-size: cover;
	background-position: center;
	.vjs-control-bar {
		margin: 40px;
		position: unset;
		height: 60px;
		background-color: $color-white;
		color: $color-dark;
		border-radius: 5px;
		box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.3);
		button {
			color: $color-coral;
			font-size: 20px;
			width: 29px;
			margin: 0 24px 0 12px;
		}
		.vjs-load-progress {
			border-radius: 50px;
			div {
				border-radius: 50px;
			}
		}
		.vjs-play-progress {
			background-color: $color-coral;
			color: $color-coral;
			border-radius: 50px;
			&::before {
				font-size: 14px;
			}
		}
		.vjs-progress-holder {
			height: 5px;
			border-radius: 50px;
			margin: 0;
		}
		.vjs-time-control {
			padding-left: 24px;
			padding-right: 24px;
			line-height: 0;
			top: 50%;
			font-size: 12px;
		}
	}
}

.lvrs__tabs {
	display: flex;
	justify-content: space-around;
	height: 48px;
	padding: 0 20px;
	border-bottom: 1px solid #f1f1f1;
}

.lvrs__tabs-item {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px;
	margin-bottom: -1px;
	margin-top: 1px;
}

.lvrs__tabs-item.active {
	border: 1px solid #f1f1f1;
	border-radius: 5px 5px 0 0;
	border-bottom-color: white;
	padding: 5px;
}

.lvrs__tabs-item.active i {
	color: #FF6F61;
}

.lvrs__tabs-item a {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	border-radius: 5px;
	transition: 350ms;
}

.lvrs__tabs-item a:hover {
	background-color: rgba(255, 111, 97, 0.2);
	transition: 350ms;
}

.lvrs__title {
	margin: 30px 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 0 20px;
	position: relative;
	display: none;
}

#lvrs__sortmenu {
	position: absolute;
	display: flex;
	bottom: -80px;
	background-color: #F5F6FA;
	flex-direction: column;
	right: 20px;
	text-align: right;
	padding: 15px;
	border-radius: 5px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
	li:last-of-type a {
		margin-bottom: 0;
	}
	a {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin-bottom: 10px;
		color: #8A96A3;
	}
	span {
		letter-spacing: 0;
		text-transform: none;
		margin-left: 15px;
	}
}

.lvrs__title i {
	font-size: 18px;
}

.lvrs__title span {
	text-transform: uppercase;
	letter-spacing: 1.85px;
}

.lvrs__grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 1px;
}

.lvrs__grid-item {
	position: relative;
	i {
		position: absolute;
		font-size: 42px;
		color: $color-white;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.lvrs__postinfo span {
	text-align: center;
	font-size: 11px;
	color: #8A96A3;
	line-height: 18px;
	padding: 0 15px;
	background: $color-white;
	display: inline-block;
	margin-top: 10px;
}

.lvrs__postinfo {
	margin: 30px 0 20px 0;
	padding: 0 20px;
	a {
		text-decoration: underline;
		background-color: #FF6F61;
		padding: 5px 10px;
		border-radius: 5px;
		color: #ffffff;
		font-size: 14px;
	}
}

.lvrs__postinfo-date {
	margin-bottom: 30px;
	width: 100%;
	border-bottom: 1px solid #8A96A3;
	text-align: center;
	height: 18px;
}

.lvrs__postinfo-text {
	padding: 0 20px;
}

.lvrs__postcontent {
	position: relative;
	.icon-streamline-icon-lock-818x18 {
		position: absolute;
		font-size: 42px;
		color: $color-white;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.lvrs__postcontent img {
	width: 100%;
	max-height: 80vh;
	height: auto;
	object-fit: cover;
	display: block;
}

.premium-video-duration {
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.35);
	color: white;
	display: flex;
  align-items: flex-end;
	padding: 8px 16px;
	font-size: 14px;
	&::before {
		content: "\E96B";
		margin-right: 4px;
		font-family: "puricons";
	}
}

.lvrs__postinteractives {
	display: flex;
	margin: 15px 0;
	padding-left: 20px;
}

.lvrs__postinteractives span {
	margin-left: 8px;
	font-size: 14px;
	color: #8A96A3;
}

.lvrs__postinteractives-item {
	display: flex;
	align-items: center;
	margin-right: 30px;
}

.lvrs__postinteractives-item.lvrs__postinteractives-likebtn {
	min-width: 85px;
	cursor: pointer;
	i {
		color: $color-coral;
	}
}

.lvrs__postinteractives-item.lvrs__postinteractives-likebtn.active i {
	color: $color-coral;
}

.lvrs__postcommentarea {
	padding: 20px;
	background-color: #F5F6FA;
	margin-bottom: 40px;
	border-radius: 5px;
}

.lvrs__postcomment {
	margin-bottom: 20px;
	p {
		font-size: 14px;
		margin-top: 0;
	}
}

.lvrs__postcomment span {
	font-size: 11px;
	margin-bottom: 4px;
	display: inline-block;
}

.lvrs__postcomment__showall {
	font-size: 12px;
	color: #8A96A3;
	display: block;
	margin-top: 30px;
	margin-left: 20px;
	text-decoration: underline;
	cursor: pointer;
}

.lvrs__postcomment__answer {
	margin-top: 20px;
	margin-left: 20px;
	display: grid;
	grid-template-columns: 40px 1fr;
	grid-gap: 10px;
	padding-right: 50px;
	img {
		border-radius: 50px;
		width: 40px;
		height: 40px;
		object-fit: cover;
	}
}

.lvrs__postcomment__textarea {
	margin-top: 20px;
	position: relative;
	textarea {
		border-radius: 5px;
		border: none;
		width: 100%;
		box-sizing: border-box;
		resize: none;
		min-height: 60px;
		padding: 18px 65px 18px 20px;
		font-family: "Poppins", sans-serif;
		font-size: 16px;
		line-height: 24px;
		font-weight: 300;
		&:focus {
			outline: none;
			+ button {
				color: $color-coral;
			}
		}
	}
	button {
		display: inline-block;
		position: absolute;
		bottom: 20px;
		right: 20px;
		background-color: transparent;
		border: none;
		font-size: 24px;
		color: #8A96A3;
		padding: 0;
	}
}

.lvrs__sidebar-item {
	width: 100%;
	position: sticky;
	top: 100px;
	img {
		width: 100%;
		object-fit: cover;
		object-position: top;
		max-height: 85vh;
	}
	.lvrs__sidebar-infos {
		position: absolute;
		bottom: 0;
		padding: 6.82%;
		width: 100%;
		box-sizing: border-box;
		background-image: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%);
		div {
			display: flex;
			margin-bottom: 8px;
		}
		i {
			font-size: 16px;
			color: $color-white;
			margin-right: 8px;
			text-shadow: 0px 1px 1px rgba(0,0,0,0.3);
		}
		span {
			font-size: 16px;
			line-height: 22px;
			font-weight: 300;
			color: $color-white;
			text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
		}
		button {
		color: #ffffff;
		background-image: linear-gradient(79deg, #FF617C 0%, #FF6F61 100%);
		border: none;
		border-radius: 99px;
		font-size: 16px;
		padding: 11px 32px;
		display: block;
		margin: 20px auto 0 auto;
		width: 80%;
		max-width: 250px;
		}
	}
	a {
		font-weight: bold;
		&.heading {
			font-size: 22px;
			margin-top: 40px;
			margin-bottom: 10px;
			display: inline-block;
		}
		.bonus_amount {
			position: absolute;
			top: 11%;
			width: 60%;
			text-align: center;
			font-size: 3.5vw;
			margin-left: 20%;
			color: #cccccc;
			line-height: 1.2;
			span {
				display: block;
			}
			i {
				color: #cccccc;
			}
		}
	}
	.medialist {
		display: block;
		.medialist__item {
			margin-bottom: 20px;
			.medialist__item-details {
				display: none;
			}
		}
	}
}

.lvrs-join {
	h2 {
		font-size: 24px;
		line-height: 26px;
		font-weight: 400;
		margin-bottom: 20px;
	}
	img {
		display: block;
		width: 100%;
	}
}

.lvrs-join__title {
	margin: 0 auto;
	max-width: 1024px;
	h1 {
		font-size: 46px;
		font-weight: 400;
		line-height: 55px;
		text-align: center;
		background-color: $color-white;
		display: inline-block;
		margin-top: -50px;
		padding: 32px 32px 0 32px;
	}
}

.lvrs-join__content {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.lvrs-join__section {
	margin: 60px;
	&.left {
		span {
			font-size: 16px;
			line-height: 22px;
			font-weight: 300;
		}
		div {
			margin-bottom: 12px;
			display: flex;
		}
		i {
			color: $color-coral;
			font-size: 20px;
			margin-right: 12px;
		}
		p {
			font-size: 16px;
			font-weight: 300;
			line-height: 24px;
			margin-top: 32px;
		}
	}
	&.right {
		input {
			display: none;
		}
		input[type="radio"]:checked + label {
			background-image: linear-gradient(180deg, #FF6F61 0%, #F5645F 100%);
			color: $color-white;
			.small {
				color: $color-white;
			}
		}
		label {
			position: relative;
			box-sizing: border-box;
			width: 100%;
			padding: 13px 16px 9px 16px;
			border-radius: 5px;
			background-color: #F5F6FA;
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
			transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
			cursor: pointer;
			&:hover {
				background-image: linear-gradient(180deg, #FF6F61 0%, #F5645F 100%);
				color: $color-white;
				.small {
					color: $color-white;
				}
			}
			div {
				display: flex;
				flex-direction: column;
			}
			span {
				font-size: 18px;
				font-weight: 400;
				letter-spacing: 0.54px;
				&.small {
					font-size: 12px;
					font-weight: 300;
					letter-spacing: 0.36px;
					color: #8A96A3;
					margin-top: 4px;
					transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
				}
			}
		}
		p.small {
			font-size: 12px;
			font-weight: 300;
			letter-spacing: 0.36px;
			line-height: 15px;
			color: #8A96A3;
			text-align: center;
			margin-top: 20px;
		}
		button {
			color: $color-white;
			background-image: linear-gradient(180deg, #FF6F61 0%, #F5645F 100%);
			border: none;
			border-radius: 5px;
			font-size: 16px;
			padding: 12px 32px;
			display: block;
			margin: 20px auto 0 auto;
			width: 50%;
		}
	}
}

#lvrs__backtogrid {
	background-color: #F5F6FA;
	padding: 12px 24px;
	border-radius: 50px;
	display: flex;
	margin: 25px auto 10px auto;
	max-width: 85px;
	justify-content: center;
	+ .lvrs__post .lvrs__postinfo {
		margin: 0 0 20px 0;
	}
}

.lvrs__savings {
	position: absolute;
	top: 0;
	right: 95px;
	width: 60px;
	i {
		font-size: 55px;
		color: #8A96A3;
	}
}
.lvrs-join__section.right label .lvrs__savings span {
	position: absolute;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.37px;
	text-align: center;
	top: 6px;
	color: $color-white;
}

@media screen and (min-width: 1560px) {
	.lvrs__sidebar-item a .bonus_amount {
		font-size: 54.6px;
	}
}

@media screen and (max-width: 1200px) {
	.lvrs-join img {
		height: 225px;
		object-fit: cover;
		object-position: top;
	}
	.lvrs-join__title {
		margin: 0;
	}
	.lvrs-join h1 {
		font-size: 32px;
		line-height: 48px;
		background-color: unset;
		text-align: left;
		margin-top: 0;
		padding: 20px 0 0 40px;
	}
	.lvrs-join h2 {
		font-size: 22px;
	}
	.lvrs-join__section {
		margin: 40px;
	}
}

@media screen and (max-width: 1024px) {
	.lvrs {
		grid-template-columns: 1fr;
		grid-column-gap: 0;
		grid-template-areas: "sidebar" "stream";
		.premium-audio .vjs-control-bar {
			margin: 20px;
		}
	}
	.lvrs__title, .lvrs__postinfo-text {
		padding: 0;
	}
	.lvrs__postinfo {
		padding: 0;
	}
	.lvrs-join img {
		height: unset;
		max-height: 210px
	}
	.lvrs-join__image {
		margin: 0 -20px;
	}
	.lvrs-join__content {
		grid-template-columns: 1fr;
	}
	.lvrs-join__section.right p.small {
		text-align: left;
	}
	#lvrs__sortmenu {
		right: 0;
	}
	.lvrs__sidebar {
		margin: -30px -20px 20px -20px;
	}
	.sidebar_banner {
		display: none;
	}
}

@media screen and (max-width: 1024px) and (min-width: 461px) {
	.lvrs__sidebar-logo {
		padding: 4%;
		.icon-Lover_Logo-BeMy-Main {
			font-size: 10vw;
		}
	}
	.lvrs__sidebar-item .lvrs__sidebar-infos {
		padding: 4%;
		div {
			margin-bottom: 1.8vw;
		}
		span {
			font-size: 2.3vw;
			line-height: 2.5vw;
			font-weight: 400;
		}
	}
}

@media screen and (max-width: 650px) and (min-width: 461px) {
	.lvrs__sidebar-logo .icon-Lover_Logo-BeMy-Main {
		font-size: 8vw;
	}
	.lvrs__sidebar-item .lvrs__sidebar-infos div {
		align-items: center;
	}
	.lvrs__sidebar-item .lvrs__sidebar-infos button {
		margin-top: 1.5vw;
		font-size: 14px;
		padding: 10px 32px;
		width: 50%;
		font-size: 14px;
	}
}

@media screen and (max-width: 768px) {
	.lvrs-join h1 {
		font-size: 24px;
		line-height: 32px;
	}
}

@media screen and (max-width: 460px) {
	.lvrs__sidebar-logo .icon-Lover_Logo-BeMy-Main {
		font-size: 16vw;
	}
	.lvrs__sidebar-item img {
		max-height: 65vh;
		object-position: 0 -30px;
		min-height: 500px;
	}
}

@media screen and (max-width: 425px) {
	.lvrs__postinteractives {
		padding-left: 0;
		justify-content: space-evenly;
		&-item {
			margin-right: 0;
		}
	}
	.lvrs__postcommentarea {
		border-radius: 0;
	}
	.lvrs__postcomment__answer {
		padding-right: 0;
	}
	.lvrs .premium-audio {
		border-radius: 0;
	}
	.lvrs__grid, .lvrs__postcontent, .lvrs__postcommentarea, .lvrs__tabs {
		margin-left: -20px;
		margin-right: -20px;
	}
	.lvrs__grid-item i {
		font-size: 24px;
	}
	.lvrs-join__section {
		margin: 30px 0;
	}
	.lvrs-join h1 {
		padding-left: 0;
		font-weight: 600;
	}
	.lvrs-join h2 {
		font-size: 20px;
	}
	.lvrs-join__section.right p.small {
		margin-top: 40px;
	}
}

@media screen and (max-width: 320px) {
	.lvrs__sidebar-item .lvrs__sidebar-infos span {
		font-size: 14px;
	}
	.lvrs__savings {
		right: 80px;
	}
}

@media screen and (max-height: 900px) and (min-width: 1025px) {
	.lvrs__sidebar-item {
		top: 40px;
	}
	.lvrs__sidebar-item img {
		max-height: 90vh;
	}
}

.premium-audio {
	&.video-js .vjs-control:focus {
		outline: none;
	}
	&.vjs-paused .vjs-big-play-button {
		display: none;
	}
	&.vjs-paused .vjs-control-bar {
		display: flex;
	}
}
