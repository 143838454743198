.medialist--gallery {

	.medialist .medialist__item .medialist__item-image .medialist__item-imageinfo {
		display: none;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.7);
		color: #fff;
		font-size: 11px;
		padding:0 10px;
	}

	.medialist .medialist__item .medialist__item-image .medialist__item-imageinfo dt {
		border-color: rgba(255, 255, 255, 0.3);
	}

	.medialist .medialist__item .medialist__item-image:hover .medialist__item-play {
		display: block!important;
	}

	.medialist .medialist__item .medialist__item-image .medialist__item-play {
		display: none;
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background-image: url('#{$cdn}/static/images/sites/global/play.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 60px 60px;
		cursor: pointer;
		pointer-events: none;
		opacity: 0.7;
	}

	.medialist__item div img {
		width: 100%;
	}
	.medialist .medialist__item .medialist__item-title {
		order: 1;
		font-weight: normal;
		font-size: 16px;
		padding: 5px 0;
		overflow:hidden;
		text-overflow:ellipsis;
		a {
			height: 18px;
		}
	}

	.medialist .medialist__item .medialist__item-title a {
		display: block;
		overflow:hidden;
		text-overflow:ellipsis;
	}

	.medialist--third .medialist__item .medialist__item-title {
		padding: 4px 0;
		font-size: 14px;
	}

	.medialist--quarter .medialist__item .medialist__item-title {
		padding: 2px 0;
		font-size: 13px;
	}

	.medialist .medialist__item .medialist__item-description {
		order: 1;
		padding: 5px 0;
		border-top: 1px solid rgba(0, 0, 0, 0.2);
		opacity: 0.5;
		font-size: 13px;
		overflow:hidden;
		text-overflow:ellipsis;
	}

	.medialist--third .medialist__item .medialist__item-description {
		padding: 4px 0;
		font-size: 12px;
	}

	.medialist--quarter .medialist__item .medialist__item-description {
		padding: 2px 0;
		font-size: 11px;
	}

	.medialist .medialist__item .medialist__item-details {
		order: 1;
		padding: 5px 0;
		display: flex;
		align-items:baseline;
		border-top: 1px solid rgba(0, 0, 0, 0.2);
		font-size: 12px;
		line-height: 1.5;
	}

	.medialist .medialist__item .medialist__item-details .medialist__item-detail {
		width: 100%;
	}

	.medialist .medialist__item .medialist__item-details .medialist__item-detail a {
		font-weight: normal;
	}

	.medialist .medialist__item .medialist__item-details .medialist__item-detail span {
		display: inline-block;
	}

	.medialist .medialist__item .medialist__item-details .medialist__item-detail span.s {
		font-size: 11px;
		margin-left: 3px;
	}

	.medialist .medialist__item .medialist__item-details .medialist__item-detail span.l {
		font-size: 15px;
		font-weight: normal;
	}

	.medialist--quarter .medialist__item .medialist__item-details .medialist__item-detail span.l {
		font-size: 14px;
	}

	.medialist .medialist__status {
		display: inline-block;
		font-weight: 600;
		font-size: 12px;
		height: 16px;
		line-height: 16px;
		padding:0 5px;
	}

	.medialist .medialist__status--online {
		background-color: #5bb336!important;
		color: #fff!important;
	}

	.medialist .medialist__item .medialist__item-image img {
		display: block;
		width: 100%;
	}

	.medialist .medialist__item .medialist__item-image .medialist__imagebadge {
		position: absolute;
		top:5px;
		left:5px;
		font-weight: 600;
		font-size: 12px;
		height: 16px;
		line-height: 16px;
		padding:0 5px;
		pointer-events: none;
	}

	.medialist .medialist__item .medialist__item-discount {
		position: absolute;
		top:4px;
		right:4px;
		background-color: rgba(0, 0, 0, 0.4);
		color: #fff;
		text-align: center;
		line-height: 14px;
		padding: 6px;
		pointer-events: none;
	}

	.medialist .medialist__item .medialist__item-discount div:first-child {
		font-weight: 600;
		font-size: 19px;
	}

	.medialist .medialist__item .medialist__item-discount div:last-child {
		font-weight: 600;
		font-size: 11px;
	}

	.medialist--half .medialist__item .medialist__item-discount {
		line-height: 19px;
	}

	.medialist--half .medialist__item .medialist__item-discount div:first-child {
		font-size: 24px;
	}

	.medialist--half .medialist__item .medialist__item-discount div:last-child {
		font-size: 13px;
	}

	.medialist--quarter .medialist__item .medialist__item-discount {
		line-height: 12px;
		padding: 4px;
	}

	.medialist--quarter .medialist__item .medialist__item-discount div:first-child {
		font-size: 16px;
	}

	.medialist--quarter .medialist__item .medialist__item-discount div:last-child {
		font-size: 10px;
		font-weight: normal;
	}

	.medialist--classic .medialist__item .medialist__item-title {
		order:0;
		padding: 5px 2px 0px 2px;
	}

	.medialist--classic .medialist__item {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		background-color: rgba(0, 0, 0, 0.1);
		-moz-border-radius: 14px;
		-webkit-border-radius: 14px;
		border-radius: 14px;
		padding: 0 4px 4px 4px;

	}

	.medialist--classic .medialist__item .medialist__item-image {
		margin-top: 5px;
	}

	.medialist--classic .medialist__item:hover {
		cursor: pointer;
	}

	.medialist--classic .medialist__item .medialist__item-description {
		display: none;
	}

	.medialist--classic .medialist__item .medialist__item-details {
		opacity: 1;
		padding-top: 4px;
		padding-bottom: 2px;
	}

	.medialist--classic .medialist__item .medialist__item-details .medialist__item-detail {
		text-align: center;
		margin:0 2px;
		padding: 5px 0 4px 0;
		line-height: 14px;
		-moz-border-radius: 4px; /*FF1+*/
		-webkit-border-radius: 4px; /*Saf3+,Chrome*/
		border-radius: 4px; /*Opera10.5,IE 9*/
	}

	.medialist--classic .medialist__item .medialist__item-details a.medialist__item-detail {
		display: block;
	}

	.medialist--classic .medialist__item .medialist__item-details .medialist__item-detail:hover {
		opacity: 0.5
	}

	.medialist--classic .medialist__item .medialist__item-details .medialist__item-detail span {
		display: block!important;
		margin:0!important;
		font-size: 12px!important;
		font-weight: 600;
	}

	.medialist--classic.medialist--quarter .medialist__item .medialist__item-details .medialist__item-detail span {
		font-size: 11px!important;
	}

	.medialist--classic .medialist__item .medialist__item-discount {
		background-color: #fef791;
		color: #797242;
		-moz-box-shadow: 0px 0px 5px #aaa; /*FF3.5+*/
		-webkit-box-shadow: 0px 0px 5px #aaa; /*Saf3.0+,Chrome*/
		box-shadow: 0px 0px 5px #aaa; /*Opera10.5,IE9*/
		padding-bottom: 3px;
	}

	.medialist--classic .medialist--hidden-classic {
		display: none!important;
	}

	.medialist .medialist--visible-classic {
		display: none!important;
	}

	.medialist--classic .medialist--visible-classic {
		display: block!important;
	}
}
