#siteheader-navi-menu-profile {
	display: none;
	position: absolute;
	width: 240px;
  right: 20px;
	z-index: 2;
	top: 40px;
	margin: 0 60px;
	@media screen and (max-width: 1104px) {
		margin: 0;
		right: 0;
	}
  padding-top: 30px;
	#siteheader-navi-menu-profile-inner {
		a {
			text-transform: unset;
		}
		display: flex;
		flex-direction: column;
		font-size: 14px;
		padding: 24px;
		background-color: $color-white;
		box-shadow: 0 4px 16px hsla(0,0%,4%,.2);
		border-radius: 8px;
		#siteheader-navi-menu-profile-banner {
			display: flex;
		}
		#siteheader-navi-menu-profile-accountinfos {
			margin-bottom: 20px;
			.accountname {
				font-size: 16px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: block;
				text-align: right;
				&:first-of-type {
					font-weight: 600;
				}
				&:last-of-type {
					font-size: 12px;
					padding-bottom: 20px;
					margin-bottom: 20px;
					border-bottom: 1px solid #f1f1f1;
				}
			}
			.coins {
				font-size: 16px;
				font-weight: normal;
				display: flex;
				align-items: baseline;
				justify-content: space-evenly;
				div {
					display: flex;
				}
				p {
					margin: 0 0 0 4px;
					font-weight: 400;
				}
			}
			#siteheader-navi-menu-profile-accountinfos-paymenttrigger {
				border-bottom: 1px solid $color-grey1;
				padding-bottom: 20px;
				margin: 10px 12px 0 12px;
				text-align: center;
				.button {
					padding: 8px 32px;
					line-height: 1;
					display: block;
				}
			}
		}
		#siteheader-navi-menu-profile-mycontent, #siteheader-navi-menu-profile-accountsettings {
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid #f1f1f1;
		}
		.linklist {
			display: flex;
			flex-direction: column;
			a {
				line-height: 26px;
				display: inline-block;
				white-space: nowrap;
				&:hover {
					color: $color-primary;
				}
			}
		}
		.icon {
			font-size: 20px;
			color: $color-coral;
		}
	}
}
#siteheader-navi-btn-profile:hover + #siteheader-navi-menu-profile, #siteheader-navi-menu-profile:hover {
	display: block;
}
.tab-navigation-h1 {
	float: left;
  margin-top: 15px;
}
.tab-navigation {
	float: right;
  margin-top: 26px;
	margin-bottom: 25px;
	width: 215px;
    display: flex;
	justify-content: space-between;

	.selected {
		font-weight: 600;
		display: inline-block;
		position: relative;
		&:after {
			width: 30px;
			height: 3px;
			background-color: $color-coral;
			border-radius: 3px;
			content: " ";
			position: absolute;
			left: 50%;
			margin-left: -15px;
			bottom: -1px;
		}
	}
}

#chat-medialist {
	> div {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.chat-received-attachment {
			width: 24%;
			img {
				width: 100%;
				float: none;
				cursor: pointer;
			}
		}
	}
}

#modal-livecam-external {
	iframe {
		width: 100%;
		height: 100%;
		overflow: hidden;
		border: 0;
	}
	.close-modal {
		background: black;
		top: 3px;
		right: 3px;
		.close-icon {
			color: #ffffff;
		}
	}
}
#modal-verifyuser {
	.modal-box {
		height: auto;
	}
	a {
		color: $color-coral;
		font-weight: 600;
	}
	.alert {
		display: none;
	}
}
@import "payment_desktop";
@import "chat_desktop";
@import "members";
@import "featherlight";
@import "featherlight.gallery";
.lpcms-player .premium-video, .lpcms-player .premium-video video.vjs-tech {
	width: 100%;
	max-height: calc(100vh - 68px);
	max-height: calc((var(--vh, 1vh) * 100) - 68px);
	transition: height 1s, max-height 1s;
}
.lpcms-player .premium-video {
	video.vjs-tech {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
	}
	&.vjs-fullscreen {
		video.vjs-tech {
			max-height: none;
		}
	}
}
/*
.lvrs__stream {
	width: 1020px;
}
*/
.icon.icon-streamline-icon-lock-818x18 {
	cursor: pointer;
}
.lvrs__postcontent {
	img, video {
		pointer-events: none;
		-moz-user-select: -moz-none;
		-khtml-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.lpcms-slider {
		overflow: hidden;
		.lSSlideOuter .lSPager.lSpg {
			justify-content: center;
		}
		.lpcms-player {
			height: calc(100% - 15px);
			.premium-video {
				height: 100%;
			}
		}
		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 10px;
			&.lSPager {
				gap: 0px;
			}
			&.lightSlider-active {
				overflow: hidden;
				gap: 0px;
			}
			li {
				width: 100%;
				position: relative;
				.premium-video-duration {
					bottom: 15px;
				}
				img {
					max-width: 100%;
					display: block;
					max-height: 600px;
					height: 90vh;
					object-fit: cover;
				}
			}
		}
	}
}
#lvrs__sortmenu {
	display: none;
	z-index: 1;
}
.lvrs__postinteractives .lvrs__postinteractives-item.lvrs__postinteractives-likebtn.active i.icon-streamline-icon-love-it-alternate18x18:before {
	content: "\e974";
}
.lpcms-player .vjs-poster {
	background-size: cover;
}
.featherlight {
	button:focus {
		outline: none;
	}
	.lightbox-bottom {
		.lvrs__postinteractives {
			justify-content: center;
			margin: 16px 0;
			.lvrs__postinteractives-item {
				max-width: 170px;
				background-color: white;
				padding: 10px 20px;
				border-radius: 50px;
				margin-right: 0;
				cursor: pointer;
				span {
					margin-left: 10px;
				}
				&.lvrs__postinteractives-comments {
					margin-left: 20px;
					span {
						text-wrap: nowrap;
					}
				}
			}
		}
	}
	.featherlight-content {
		width: 100%;
		height: 100%;
		margin: 0;
		border-bottom: 0;
		padding: 0;
		max-height: 100%;
	}
	.featherlight-next, .featherlight-previous {
		top: 40%;
		bottom: 40%;
		&:hover {
			background: none;
		}
		span {
			display: inline-block;
			text-shadow: none;
			color: $color-white;
		}
	}
	.featherlight-previous {
		right: auto;
		width: 70px;
	}
	.featherlight-next {
		left: auto;
		width: 70px;
	}
	.featherlight-inner {
		height: 100%;
		display: flex;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		.lpcms-player {
			height: calc(100vh - 68px);
			height: calc((var(--vh, 1vh) * 100) - 68px);
			transition: height 1s, max-height 1s;
			display: flex;
			align-items: center;
		}
		img, video {
			pointer-events: none;
			-moz-user-select: -moz-none;
			-khtml-user-select: none;
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		img {
			max-height: calc(100vh - 68px);
			max-height: calc((var(--vh, 1vh) * 100) - 68px);
			transition: height 1s, max-height 1s;
			display: block;
			max-width: 100%;
			margin: 0 auto;
		}
		.lpcms-player .premium-video {
			background: none;
			height: 100%;
		}
	}
}
.lvrs-join__section.right label span {
	.linethrough {
		font-size: 12px;
	}
}
@import "../../node_modules/vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

.new_videos {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 5px;
	img, video {
		width: 100%;
		height: 100%;
	}
	video {
		object-fit: cover;
	}
	.right-side {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		grid-gap: 5px;
	}
}
