#loginform-bottomlinks {
	margin-top: 72px;
}
#signupform-accept-agb {
	margin-top: 12px;
	color: #8A96A3;
	font-weight: 300;
	line-height: 16px;
	font-size: 12px;
	a {
		text-decoration: underline;
		color: #8A96A3;
	}
}
#loginform-syncpage-1, #loginform-syncpage-2, #loginform-syncpage-3 {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 120px);
	height: 100%;
	background: $color-white;
	padding: 120px 60px 60px 60px;
	font-size: 22px;
	a {
		text-decoration: underline;
	}
	h2 {
		margin-bottom: 30px;
		font-size: 22px;
	}
	.syncpage-bottom {
		text-align: center;
		border-top: 2px solid $color-grey-15;
		margin-top: 30px;
		padding-top: 30px;
		font-size: 17px;
		.icon {
			margin-right: 7px;
		}
		.syncpage-checkbox {
			width: 425px;
			margin: 0 auto;
			label {
				&:before {
					margin-top: -2px;
					margin-right: -23px;
				}
			}
		}
		button {
			margin-top: 30px;
			padding: 18px 93px;
			font-weight: normal;
			font-size: 17px;
		}
		.handwriting {
			line-height: 60px;
			font-size: 36px;
		}
		.syncpage-bottom-icon {
			margin-top: 35px;
			font-size: 46px;
			padding-top: 75px;
			border-top: 2px solid $color-grey-15;
			.icon {
				color: $color-grey1;
			}
		}
	}
}

#loginform-syncpage-2 {
	.syncpage-bottom {
		button + div {
			margin-top: 40px;
		}
	}
}
#loginform-syncpage-3 {
	.syncpage-bottom {
		button {
			display: flex;
			align-items: center;
			margin: 30px auto;
			justify-content: center;
		}
	}
	.syncpage-bottom {
		.syncpage-checkbox {
			width: 260px;
			max-width: 260px;
		}
	}
}
#loginform-mainpage {
	width: 100%;
}
#modal-login {
	.alert {
		display: none;
	}
	form {
		display: flex;
		width: 100%;
	}
}
#pppsync-sendtoken, #pppsync-verifytoken, #pppsync-newpass {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 120px);
	height: 100%;
	background: $color-white;
	padding: 120px 60px 60px 60px;
	font-size: 22px;
	a {
		text-decoration: underline;
	}
	h2 {
		margin-bottom: 30px;
		font-size: 22px;
	}
	.syncpage-bottom {
		text-align: center;
		border-top: 2px solid $color-grey-15;
		margin-top: 30px;
		padding-top: 30px;
		font-size: 17px;
		.icon {
			margin-right: 7px;
		}
		.syncpage-checkbox {
			width: 425px;
			margin: 0 auto;
			label {
				&:before {
					margin-top: -2px;
					margin-right: -23px;
				}
			}
		}
		button {
			margin-top: 30px;
			padding: 18px 93px;
			font-weight: normal;
			font-size: 17px;
		}
		.handwriting {
			line-height: 60px;
			font-size: 36px;
		}
		.syncpage-bottom-icon {
			margin-top: 35px;
			font-size: 46px;
			padding-top: 75px;
			border-top: 2px solid $color-grey-15;
			.icon {
				color: $color-grey1;
			}
		}
	}
}

#pppsync-newpass {
	.syncpage-bottom {
		button {
			display: flex;
			align-items: center;
			margin: 30px auto;
			justify-content: center;
		}
	}
	.syncpage-bottom {
		.syncpage-checkbox {
			width: 260px;
			max-width: 260px;
		}
	}
}