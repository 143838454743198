#loginform-syncpage-1, #loginform-syncpage-2, #loginform-syncpage-3, #pppsync-sendtoken, #pppsync-verifytoken, #pppsync-newpass {
	padding: 40px 20px 20px 20px;
	width: calc(100% - 40px);
	font-size: 17px;
	.syncpage-bottom {
		.syncpage-bottom-icon {
			display: none;
		}
		.handwriting {
			line-height: 40px;
			font-size: 29px;
		}
		.syncpage-checkbox {
			width: auto;
		}
		button {
			width: 100%;
		}
	}
}