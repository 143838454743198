$cdn: '';
$color-coral: #FF6F61;
$color-gold: #b89c6c;
$color-grey1: #f1f1f1;
$color-grey2: #ececec;
$color-white: #ffffff;
$color-grey-15: rgba(16, 16, 20, 0.15);
$color-grey-50: rgba(16, 16, 20, 0.5);
$color-red: #f54646;
$color-leonie-red: #F44646;
$color-green: #ace81a;
$color-lightblue: #32AFED;

$color-primary: #FF6F61;
$color-dark: #0A0A0A;
$color-bright: #F9F9F9;

$gradient-coral: linear-gradient(79deg, #FF617C 0%, #FF6F61 100%);

$break-desktop: 1024px;
$break-ipad: 768px;
$break-phone: 480px;

@import "icons";
@import "animations";
@import "modal";
@import "medialist";
@import "pagination";
@import "alerts";


@font-face {
	font-family: "Leonie_Accent";
	src: url(fonts/Leonie_Accent.woff);
	font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('/fonts/poppins-v19-latin-200.woff2') format('woff2'),
       url('/fonts/poppins-v19-latin-200.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('/fonts/poppins-v19-latin-300.woff2') format('woff2'),
       url('/fonts/poppins-v19-latin-300.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/poppins-v19-latin-regular.woff2') format('woff2'),
       url('/fonts/poppins-v19-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('/fonts/poppins-v19-latin-500.woff2') format('woff2'),
       url('/fonts/poppins-v19-latin-500.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('/fonts/poppins-v19-latin-600.woff2') format('woff2'),
       url('/fonts/poppins-v19-latin-600.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/fonts/poppins-v19-latin-700.woff2') format('woff2'),
       url('/fonts/poppins-v19-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('/fonts/poppins-v19-latin-800.woff2') format('woff2'),
       url('/fonts/poppins-v19-latin-800.woff') format('woff');
}

html,
body {
	color: $color-dark;
	font-family: "Poppins", sans-serif;
	margin: 0;
	max-width: 100vw;
}
a {
	color: $color-dark;
	text-decoration: none;
}
h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	font-weight: 600;
}
ul {
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		margin: 0;
		padding: 0;
		list-style: none;
	}
	&.dropdown-menu {
		background: $color-grey1;
		position: absolute;
		padding-right: 20px;
		flex-direction: column;
		z-index: 999;
		top: 50px;
		right: -1px;
		white-space: nowrap;
		border-radius: 3px;
		li {
			margin: 13px;
			a {
				&.selected {
					color: $color-grey-15;
				}
			}
		}
		&:before {
			content: "";
			position: absolute;
			right: 11px;
			top: -10px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 10px 10px;
			border-color: transparent transparent $color-grey1 transparent;
			z-index: 9999;
		}
	}
}
.desktop-only {
	@media screen and (max-width: $break-desktop) {
		display: none !important;
	}
}
.mobile-only {
	@media screen and (min-width: 1025px) {
		display: none !important;
	}
}
.phone-only {
	@media screen and (min-width: 481px) {
		display: none !important;
	}
}
.except-phone {
	@media screen and (max-width: $break-phone) {
		display: none !important;
	}
}
.force-hide {
	display: none !important;
}
.mobile-fullwidth {
	@media screen and (max-width: $break-desktop) {
		width: 100vw !important;
		margin-left: -20px !important;
		&#blackfriday {
			margin-left: 0 !important;
		}
	}
}
.phone-fullwidth {
	@media screen and (max-width: $break-phone) {
		width: 100vw !important;
		margin-left: -20px !important;
		&#blackfriday {
			margin-left: 0 !important;
		}
	}
}

.hidden {
	display: none;
	opacity: 0;
	transition: opacity 0.4s ease-in-out;
}
.shown {
	opacity: 1;
	display: flex;
}
.clearer {
	clear: both;
}
.vertical-banner {
	transform: rotate(-90deg);
}
.centered {
	display: flex;
	align-content: center;
	justify-content: center;
}
.center {
	text-align: center;
}
.italic {
	font-style: italic;
}
.red {
	color: $color-red !important;
}
.button.lightblue {
	background-color: $color-lightblue;
}
div.pageheader-with-menu {
	display: flex;
	align-items: baseline;
	margin: 40px 0 20px 0;
	h1 {
		flex-grow: 1;
	}
	.dropdown {
		position: relative;
		flex-grow: 0;
		margin-left: 10px;
		cursor: pointer;
	}
}
.badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
	border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badge-pill {
	padding-right: 0.6em;
	padding-left: 0.6em;
	border-radius: 10rem;
}
.badge-info {
	color: #fff;
	background-color: #17a2b8;
}
.badge-info-red {
	color: #fff;
	background-color: $color-red;
  font-size: 8px;
  padding: 5px 5px;
	border-radius: 99px;
  position: absolute;
  top: 3px;
  right: -14px;
  border: 2px solid #fff;
}

.text-fade-wrapper {
	position: relative;
}
.btn-text-toggle {
	color: $color-coral;
	left: 50%;
	text-decoration: none;
	position: absolute;
	bottom: 0px;
	margin-left: -44px;
	z-index: 100;
	text-align: center;
}
.show-faded-text p.faded {
	max-height: 2000px;
}
.show-faded-text .text-fade {
	background: none;
	margin-top: -80px;
}
p {
	margin: 0;
	margin-top: 16px;
	font-weight: 300;
	font-size: 16px;
	line-height: 1.5;
	&.black {
		font-weight: 600;
	}
}
p.faded {
	position: relative;
	max-height: 100px;
	overflow: hidden;
}
.text-fade {
	background: linear-gradient(
		to bottom,
		rgba(255,255,255, 0) 0%,
		rgba(255,255,255, 1) 75%
	);
	height: 100px;
	margin-top: -100px;
	position: relative;
}

#siteheader-navi-btn-notifications {
	display: none;
}
.icon-streamline-icon-alarm-bell-118x18, .invisible {
	visibility: hidden;
}
a.fullwidth {
	font-size: 18px;
	font-weight: 600;
	display: inline-block;
	text-align: center;
	width: 100%;
}
div.goldline {
	height: 2px;
	width: 50px;
	background-color: $color-coral;
	border-radius: 3px;

}
.handwriting {
	font-family: "Leonie_Accent";
	font-size: 40px;
	color: $color-coral;
}
.gold {
	color: $color-coral;
}
.flex-vsplit {
	display: flex;
	flex-direction: row;
	> div {
		flex-basis: 48%;
		&:nth-child(1) {
			padding-right: 2%;
		}
		&:nth-child(2) {
			padding-left: 2%;
		}
	}
}
.linethrough {
	text-decoration: line-through;
	text-decoration-color: $color-dark;
	color: $color-red;
}

.page-item {
	button {
		background: white;
		border: 0;
		font-size: 22px;
		&:focus {
			outline: none;
		}
	}
}
@import "app_desktop";
@import "pppstyle";
@import "app_mobile";
@import "pwa";
@import "lightslider";
.clearcache3 {
	text-align: center;
}

.brandname {
	white-space: nowrap;
}

.big-headline {
	font-size: 48px;
	line-height: 1.2;
	font-weight: 700;
	letter-spacing: -0.5px;
	margin-bottom: 30px;
	@media screen and (max-width: $break-desktop) {
		font-size: 36px;
	}
	@media screen and (max-width: $break-ipad) {
		font-size: 32px;
	}
	@media screen and (max-width: $break-phone) {
		font-size: 28px;
		margin-bottom: 16px;
	}
}

#amateur-profile {
	margin-top: 40px;
	h1 {
		font-size: 42px;
	}
	h2 {
		margin-bottom: 20px;
		font-size: 26px;
		font-weight: 500;
	}
	#swiper-girls ul.medialist.handyvideos-only {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
	.amateurinfos {
		display: flex;
		padding: 60px;
		margin-bottom: 60px;
		border-radius: 12px;
		background-color: #f9f9f9;
		box-shadow: 0 6px 12px rgb(138 150 163 / 12%);
		img {
			border-radius: 8px;
			max-width: 400px;
			max-height: 400px;
			object-fit: cover;
			object-position: top;
			width: 100%;
			margin-right: 60px;
		}
		.amateur-profile-cambutton {
			display: inline-flex;
			flex-direction: column;
			margin-top: 60px;
			:first-child {
				padding: 12px 24px;
				background-color: $color-coral;
				color: $color-bright;
				margin-bottom: 4px;
				border-radius: 99px;
				line-height: 1;
				font-weight: 500;
    		letter-spacing: 0.5px;
			}
			:last-child {
				font-size: 12px;
				align-self: center;
			}
		}
	}
	@media screen and (max-width: $break-desktop) {
		h1 {
			font-size: 32px;
		}
		.amateurinfos {
			padding: 20px;
			img {
				max-width: 300px;
				max-height: unset;
				margin-right: 40px;
			}
			.amateur-profile-cambutton {
				margin-top: 40px;
			}
		}
	}
	@media screen and (max-width: $break-ipad) {
		h1 {
			font-size: 28px;
		}
		h2 {
			font-size: 22px;
		}
		.amateurinfos {
			margin-bottom: 40px;
		}
	}
	@media screen and (max-width: 650px) {
		margin-top: 20px;
		h1 {
			font-size: 24px;
		}
		.amateurinfos {
			flex-direction: column;
			padding: 0;
			background-color: unset;
			box-shadow: none;
			&-text {
				padding: 0 8px 8px 8px;
				p {
					text-align: justify;
					font-size: 14px;
				}
			}
			img {
				margin-right: 0;
				max-height: 250px;
				max-width: unset;
				margin-bottom: 20px;
				border-radius: 8px;
				box-shadow: 0 6px 12px rgb(138 150 163 / 12%);
			}
			p {
				margin-top: 6px;
			}
			.amateur-profile-cambutton {
				display: flex;
				align-items: center;
				margin-top: 28px;
				:first-child {
					font-size: 14px;
				}
			}
		}
	}
}