@media screen and (max-width: $break-desktop) {
	.offset-wrapper {
		padding: 0px 0px 40px 0px;
	}
	.simple-lightbox, .simple-lightbox * {
		touch-action: auto !important;
	}
	.sl-wrapper .sl-image {
		touch-action: auto;
	}
	body {
		margin: 0;
	}
	#site-wrapper, .content-limited {
		max-width: initial;
		min-width: initial;
		padding: 0;
	}

	.content-limited, .content-limited.px1200 {
		padding: 0 20px;
	}

	#siteheader-navi {
		display: none;
	}
	h1 {
		font-size: 19px;
    	height: auto;
	}

	.download-gallery {
		display: none;
	}
	div.pageheader-with-menu {
		flex-wrap: wrap;
		justify-content: flex-end;
		margin-bottom: 20px;
		margin-top: 30px;
		@media screen and (max-width: 450px) {
			justify-content: space-between;
			.dropdown {
				margin-left: 0;
			}
		}
		.dropdown {
			padding: 8px 16px;
		}
		h1 {
			@media screen and (max-width: 600px) {
				flex-basis: 100%;
				margin-bottom: 20px;
			}
		}
	}
	.inputbox-multi {
		width: 100%;
	}
	.inputbox {
		display: flex;
		&:before {
			width: calc(100% - 40px);
		}
		input {
			width: 100%;
		}
	}
	#signupform-newsletter + label {
		margin-top: 15px;
		font-size: 13px;
	}
	ul.medialist {
		li.medialist__item {
			.medialist__item-image {
				img {
					max-width: 100%;
				}
				.icon-LPCMS_exklusiv2 {
					padding: 7px;
					font-size: 10px;
				}
			}
		}
	}
	#chat {
		#chat-attachment-list-right {
			display: none;
		}
		#chat-sendmessage #chat-textarea-buttons {
			width: 58px;
			.icon-streamline-icon-smiley-happy-alternate18x18 {
				display: none;
			}
		}
	}
	.tab-navigation-h1 {
		float: none;
		padding-bottom: 10px;
		padding-top: 30px;
		margin-top: 0;
	}
	.tab-navigation {
		float: none;
		width: 200px;
		margin: 15px auto 20px auto;
	}
	#chat-medialist {
		> div .chat-received-attachment {
			width: 49%;
			@media screen and (min-width: 500px) {
				width: 32%;
			}
		}
	}
	@import "siteheader_mobile";
	@import "staticpages_mobile";
	@import "videopage_mobile";
	@import "payment_mobile";
	@import "members_mobile";
	@import "modal_mobile";
	@import "loggedout_mobile";
	@import "sitefooter_mobile";
}
