@font-face {
  font-family: 'puricons';
  src:  url(fonts/puricons.eot);
  src:  url(fonts/puricons.eot#iefix) format('embedded-opentype'),
    url(fonts/puricons.ttf) format('truetype'),
    url(fonts/puricons.woff) format('woff'),
    url(fonts/puricons.svg#puricons) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  src: url(fonts/material-symbols.woff2) format('woff2');
}

.material-symbols-rounded {
  font-family: 'Material Symbols Rounded';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  color: $color-dark;
  padding: 12px;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'puricons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-LPCMS_big-brand-en:before {
  content: "\e99f";
}
.icon-LPCMS_OnlineRotate-en:before {
  content: "\e99d";
}
.icon-LPCMS_exklusiv2-en:before {
  content: "\e99e";
}
.icon-streamline-icon-messages-bubble-heart-2-alternate32x32:before {
  content: "\e90c";
}
.icon-streamline-icon-messages-bubble-heart-1-alternate18x18:before {
  content: "\e90a";
}
.icon-streamline-icon-time-clock-circle-alternate18x181:before {
  content: "\e90b";
}
.icon-lpcms-Radio_unchecked:before {
  content: "\e907";
}
.icon-lpcms-Radio_checked:before {
  content: "\e908";
}
.icon-puricons_Crown2020:before {
  content: "\e9ae";
}
.icon-streamline-icon-empty-frame140x140:before {
  content: "\e9af";
}
.icon-streamline-icon-check-2-alternate140x140:before {
  content: "\e9b0";
}
.icon-streamline-icon-check-circle-1140x140:before {
  content: "\e9b1";
}
.icon-streamline-icon-cloud-check140x140:before {
  content: "\e9b2";
}
.icon-streamline-icon-cloud-download140x140:before {
  content: "\e9b3";
}
.icon-streamline-icon-house-entrance140x140:before {
  content: "\e9b4";
}
.icon-streamline-icon-house-entrance-alternate140x140:before {
  content: "\e9b5";
}
.icon-Lover_Logo-Main:before {
  content: "\e9a8";
}
.icon-Lover_Logo-Second:before {
  content: "\e9a9";
}
.icon-Lover_Logo-BeMy-Main:before {
  content: "\e9aa";
}
.icon-Lover_Logo-BeMy-Second:before {
  content: "\e9ab";
}
.icon-Lover_Logo-YouAre-Main:before {
  content: "\e9ac";
}
.icon-Lover_Logo-YouAre-Second:before {
  content: "\e9ad";
}
.icon-lp-lover-v:before {
  content: "\e9a7";
}
.icon-lp-highlight-label:before {
  content: "\e9a2";
}
.icon-streamline-icon-paragraph-normal32x32:before {
  content: "\e9a3";
}
.icon-streamline-icon-camera-studio32x32:before {
  content: "\e9a4";
}
.icon-streamline-icon-microphone-podcast32x32:before {
  content: "\e9a5";
}
.icon-streamline-icon-picture-landscape32x32:before {
  content: "\e9a6";
}
.icon-streamline-icon-download-thick-bottom32x32:before {
  content: "\e961";
}
.icon-streamline-icon-resize-expand-corner32x32:before {
  content: "\e9a1";
}
.icon-streamline-icon-bin24x24:before {
  content: "\e922";
}
.icon-streamline-icon-filter-224x24:before {
  content: "\e972";
}
.icon-LPCMS_cam-dildo:before {
  content: "\e973";
}
.icon-streamline-icon-love-it24x24:before {
  content: "\e974";
}
.icon-streamline-icon-love-it-alternate18x181:before {
  content: "\e975";
}
.icon-streamline-icon-button-refresh-arrow24x24:before {
  content: "\e976";
}
.icon-LPCMS_back:before {
  content: "\e971";
}
.icon-streamline-icon-alarm-bell-118x18:before {
  content: "\e92d";
}
.icon-streamline-icon-accounting-coins-stack-118x18:before {
  content: "\e92c";
}
.icon-streamline-icon-attachment18x18:before {
  content: "\e92e";
}
.icon-streamline-icon-award-star-head18x18:before {
  content: "\e92f";
}
.icon-streamline-icon-award-wall-star18x18:before {
  content: "\e930";
}
.icon-streamline-icon-book-library-218x18:before {
  content: "\e931";
}
.icon-streamline-icon-button-pause18x18:before {
  content: "\e932";
}
.icon-streamline-icon-button-pause-alternate18x18:before {
  content: "\e933";
}
.icon-streamline-icon-button-play-118x18:before {
  content: "\e934";
}
.icon-streamline-icon-button-play-alternate18x18:before {
  content: "\e935";
}
.icon-streamline-icon-calendar-318x18:before {
  content: "\e936";
}
.icon-streamline-icon-check18x18:before {
  content: "\e937";
}
.icon-streamline-icon-check-118x18:before {
  content: "\e938";
}
.icon-streamline-icon-close18x18:before {
  content: "\e939";
}
.icon-streamline-icon-controls-pause18x18:before {
  content: "\e93a";
}
.icon-streamline-icon-controls-play18x18:before {
  content: "\e93b";
}
.icon-streamline-icon-controls-stop18x18:before {
  content: "\e93c";
}
.icon-streamline-icon-conversation-smile-type-118x18:before {
  content: "\e93d";
}
.icon-streamline-icon-coupon-cut18x18:before {
  content: "\e93e";
}
.icon-streamline-icon-discount-bubble18x18:before {
  content: "\e93f";
}
.icon-streamline-icon-drawer-envelope18x18:before {
  content: "\e940";
}
.icon-streamline-icon-envelope18x18:before {
  content: "\e941";
}
.icon-streamline-icon-envelope-pigeon18x18:before {
  content: "\e942";
}
.icon-streamline-icon-filter-118x18:before {
  content: "\e943";
}
.icon-streamline-icon-gesture-swipe-horizontal-318x18:before {
  content: "\e944";
}
.icon-streamline-icon-headphones-customer-support18x18:before {
  content: "\e945";
}
.icon-streamline-icon-information-circle18x18:before {
  content: "\e946";
}
.icon-streamline-icon-lock-818x18:before {
  content: "\e947";
}
.icon-streamline-icon-login-keys18x18:before {
  content: "\e948";
}
.icon-streamline-icon-logout-218x18:before {
  content: "\e949";
}
.icon-streamline-icon-love-fire18x18:before {
  content: "\e94a";
}
.icon-streamline-icon-love-gift-box-heart18x18:before {
  content: "\e94b";
}
.icon-streamline-icon-love-it-alternate18x18:before {
  content: "\e94c";
}
.icon-streamline-icon-mailbox-full18x18:before {
  content: "\e94d";
}
.icon-streamline-icon-matches-fire18x18:before {
  content: "\e94e";
}
.icon-streamline-icon-messages-bubble-alternate18x18:before {
  content: "\e94f";
}
.icon-streamline-icon-messages-bubble-quotation18x18:before {
  content: "\e950";
}
.icon-streamline-icon-messages-bubble-quotation-alternate18x18:before {
  content: "\e951";
}
.icon-streamline-icon-messages-bubble-text-alternate18x18:before {
  content: "\e952";
}
.icon-streamline-icon-money-wallet-open18x18:before {
  content: "\e953";
}
.icon-streamline-icon-movies-film18x18:before {
  content: "\e954";
}
.icon-streamline-icon-navigation-menu18x18:before {
  content: "\e955";
}
.icon-streamline-icon-notes-upload18x18:before {
  content: "\e956";
}
.icon-streamline-icon-picture-polaroid-landscape18x18:before {
  content: "\e957";
}
.icon-streamline-icon-quill-circle-118x18:before {
  content: "\e958";
}
.icon-streamline-icon-rating-star18x18:before {
  content: "\e959";
}
.icon-streamline-icon-read-email-circle-alternate18x18:before {
  content: "\e95a";
}
.icon-streamline-icon-remove-circle-alternate18x18:before {
  content: "\e95b";
}
.icon-streamline-icon-search-118x18:before {
  content: "\e95c";
}
.icon-streamline-icon-search-alternate18x18:before {
  content: "\e95d";
}
.icon-streamline-icon-search-circle18x18:before {
  content: "\e95e";
}
.icon-streamline-icon-send-email18x18:before {
  content: "\e95f";
}
.icon-streamline-icon-single-man18x18:before {
  content: "\e960";
}
.icon-streamline-icon-single-man-actions-setting18x18:before {
  content: "\e962";
}
.icon-streamline-icon-smiley-happy-alternate18x18:before {
  content: "\e963";
}
.icon-streamline-icon-stamps-famous18x18:before {
  content: "\e964";
}
.icon-streamline-icon-tag-new18x18:before {
  content: "\e965";
}
.icon-streamline-icon-time-clock-circle-alternate18x18:before {
  content: "\e966";
}
.icon-streamline-icon-time-clock-file-warning18x18:before {
  content: "\e967";
}
.icon-streamline-icon-time-stopwatch-quarter18x18:before {
  content: "\e968";
}
.icon-streamline-icon-touch-id-lock18x18:before {
  content: "\e969";
}
.icon-streamline-icon-video-edit-clock18x18:before {
  content: "\e96a";
}
.icon-streamline-icon-video-player-1-alternate18x18:before {
  content: "\e96b";
}
.icon-streamline-icon-view18x18:before {
  content: "\e96c";
}
.icon-streamline-icon-view-off18x18:before {
  content: "\e96d";
}
.icon-streamline-icon-volume-control-medium18x18:before {
  content: "\e92a";
}
.icon-lpcms-The_Magic_Muffin:before {
  content: "\e90d";
}
.icon-lpcms_batchNFKZ:before {
  content: "\e909";
}
.icon-lpcsm-payment_kk:before {
  content: "\e902";
}
.icon-lpcsm-payment_ls:before {
  content: "\e903";
}
.icon-lpcsm-payment_sf:before {
  content: "\e904";
}
.icon-lpcsm-payment_ps:before {
  content: "\e905";
}
.icon-lpcsm-payment_pp:before {
  content: "\e906";
}
.icon-logo-mailpremium:before {
  content: "\e99c";
}
.icon-LPCMS_FuerDichRotate:before {
  content: "\e977";
}
.icon-LPCMS_Ltelegram:before {
  content: "\e997";
}
.icon-LPCMS_Lwhatsapp:before {
  content: "\e91c";
}
.icon-LPCMS_OnlineRotate:before {
  content: "\e91d";
}
.icon-LPCMS_SliderArrow-left:before {
  content: "\e91e";
}
.icon-LPCMS_SliderArrow-right:before {
  content: "\e91f";
}
.icon-LPCMS_SliderArrow-up:before {
  content: "\e90e";
}
.icon-LPCMS_SliderArrow-down:before {
  content: "\e90f";
}
.icon-LPCMS_SliderBullet:before {
  content: "\e920";
}
.icon-LPCMS_big-brand:before {
  content: "\e921";
}
.icon-LPCMS_brandneu:before {
  content: "\e901";
}
.icon-LPCMS_chevron-down:before {
  content: "\e916";
}
.icon-LPCMS_chevron-up:before {
  content: "\e917";
}
.icon-LPCMS_exklusiv1:before {
  content: "\e918";
}
.icon-LPCMS_exklusiv2:before {
  content: "\e919";
}
.icon-LPCMS_facebook:before {
  content: "\e923";
}
.icon-LPCMS_google:before {
  content: "\e924";
}
.icon-LPCMS_instagram:before {
  content: "\e926";
}
.icon-LPCMS_Mainbrand:before {
  content: "\e927";
}
.icon-LPCMS_neu:before {
  content: "\e928";
}
.icon-LPCMS_pricelabel:before {
  content: "\e929";
}
.icon-LPCMS_twitter:before {
  content: "\e9a0";
}
.icon-LPCMS_whatsapp:before {
  content: "\e92b";
}
.icon-LPCMS_telegram-2:before {
  content: "\e998";
}
.icon-LPCMS_telegram:before {
  content: "\e999";
}
.icon-LPCMS_telegram-3:before {
  content: "\e99b";
}
.icon-LPCMS_youtube:before {
  content: "\e99a";
}
.icon-LPCMS_HD:before {
  content: "\e925";
}
.icon-LPCMS_4K:before {
  content: "\e900";
}
.icon-LPCMS_playHD:before {
  content: "\e91a";
}
.icon-LPCMS_play4K:before {
  content: "\e91b";
}