@font-face {
	font-family: "videojs-theaterMode";
	speak: none;
	src: url("data:application/font-sfnt;base64,AAEAAAALAIAAAwAwT1MvMg8SB2gAAAC8AAAAYGNtYXAXVtKIAAABHAAAAFRnYXNwAAAAEAAAAXAAAAAIZ2x5ZpVu9XQAAAF4AAAAnGhlYWQSAf6DAAACFAAAADZoaGVhC8IHxwAAAkwAAAAkaG10eBO3AAAAAAJwAAAAGGxvY2EAdgBKAAACiAAAAA5tYXhwAAkACwAAApgAAAAgbmFtZZlKCfsAAAK4AAABhnBvc3QAAwAAAAAEQAAAACAAAwU9AZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADpAQPA/8AAQAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAADAAAAAwAAABwAAQADAAAAHAADAAEAAAAcAAQAOAAAAAoACAACAAIAAQAg6QH//f//AAAAAAAg6QD//f//AAH/4xcEAAMAAQAAAAAAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAAAAAAAAAAIAADc5AQAAAAACAAD/wAW3A8AABAAIAAABESERITchESEFJfttBJOS+kkFtwMu/SQC3JL8AAAAAAACAAD/wAgAA8AABAAIAAABESERITchESEHM/maBmbN+AAIAALz/ZoCZs38AAAAAAABAAAAAAAAL/heSV8PPPUACwQAAAAAANVx3QMAAAAA1XHdAwAA/8AIAAPAAAAACAACAAAAAAAAAAEAAAPA/8AAAAgAAAAAAAgAAAEAAAAAAAAAAAAAAAAAAAAGBAAAAAAAAAAAAAAAAgAAAAW3AAAIAAAAAAAAAAAKABQAHgA2AE4AAAABAAAABgAJAAIAAAAAAAIAAAAAAAAAAAAAAAAAAAAAAAAADgCuAAEAAAAAAAEABwAAAAEAAAAAAAIABwBgAAEAAAAAAAMABwA2AAEAAAAAAAQABwB1AAEAAAAAAAUACwAVAAEAAAAAAAYABwBLAAEAAAAAAAoAGgCKAAMAAQQJAAEADgAHAAMAAQQJAAIADgBnAAMAAQQJAAMADgA9AAMAAQQJAAQADgB8AAMAAQQJAAUAFgAgAAMAAQQJAAYADgBSAAMAAQQJAAoANACkaWNvbW9vbgBpAGMAbwBtAG8AbwBuVmVyc2lvbiAxLjAAVgBlAHIAcwBpAG8AbgAgADEALgAwaWNvbW9vbgBpAGMAbwBtAG8AbwBuaWNvbW9vbgBpAGMAbwBtAG8AbwBuUmVndWxhcgBSAGUAZwB1AGwAYQByaWNvbW9vbgBpAGMAbwBtAG8AbwBuRm9udCBnZW5lcmF0ZWQgYnkgSWNvTW9vbi4ARgBvAG4AdAAgAGcAZQBuAGUAcgBhAHQAZQBkACAAYgB5ACAASQBjAG8ATQBvAG8AbgAuAAAAAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA==")
			format("truetype"),
		url("data:application/font-woff;base64,d09GRgABAAAAAASsAAsAAAAABGAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABPUy8yAAABCAAAAGAAAABgDxIHaGNtYXAAAAFoAAAAVAAAAFQXVtKIZ2FzcAAAAbwAAAAIAAAACAAAABBnbHlmAAABxAAAAJwAAACclW71dGhlYWQAAAJgAAAANgAAADYSAf6DaGhlYQAAApgAAAAkAAAAJAvCB8dobXR4AAACvAAAABgAAAAYE7cAAGxvY2EAAALUAAAADgAAAA4AdgBKbWF4cAAAAuQAAAAgAAAAIAAJAAtuYW1lAAADBAAAAYYAAAGGmUoJ+3Bvc3QAAASMAAAAIAAAACAAAwAAAAMFPQGQAAUAAAKZAswAAACPApkCzAAAAesAMwEJAAAAAAAAAAAAAAAAAAAAARAAAAAAAAAAAAAAAAAAAAAAQAAA6QEDwP/AAEADwABAAAAAAQAAAAAAAAAAAAAAIAAAAAAAAwAAAAMAAAAcAAEAAwAAABwAAwABAAAAHAAEADgAAAAKAAgAAgACAAEAIOkB//3//wAAAAAAIOkA//3//wAB/+MXBAADAAEAAAAAAAAAAAAAAAEAAf//AA8AAQAAAAAAAAAAAAIAADc5AQAAAAABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAgAA/8AFtwPAAAQACAAAAREhESE3IREhBSX7bQSTkvpJBbcDLv0kAtyS/AAAAAAAAgAA/8AIAAPAAAQACAAAAREhESE3IREhBzP5mgZmzfgACAAC8/2aAmbN/AAAAAAAAQAAAAAAAC/4XklfDzz1AAsEAAAAAADVcd0DAAAAANVx3QMAAP/ACAADwAAAAAgAAgAAAAAAAAABAAADwP/AAAAIAAAAAAAIAAABAAAAAAAAAAAAAAAAAAAABgQAAAAAAAAAAAAAAAIAAAAFtwAACAAAAAAAAAAACgAUAB4ANgBOAAAAAQAAAAYACQACAAAAAAACAAAAAAAAAAAAAAAAAAAAAAAAAA4ArgABAAAAAAABAAcAAAABAAAAAAACAAcAYAABAAAAAAADAAcANgABAAAAAAAEAAcAdQABAAAAAAAFAAsAFQABAAAAAAAGAAcASwABAAAAAAAKABoAigADAAEECQABAA4ABwADAAEECQACAA4AZwADAAEECQADAA4APQADAAEECQAEAA4AfAADAAEECQAFABYAIAADAAEECQAGAA4AUgADAAEECQAKADQApGljb21vb24AaQBjAG8AbQBvAG8AblZlcnNpb24gMS4wAFYAZQByAHMAaQBvAG4AIAAxAC4AMGljb21vb24AaQBjAG8AbQBvAG8Abmljb21vb24AaQBjAG8AbQBvAG8AblJlZ3VsYXIAUgBlAGcAdQBsAGEAcmljb21vb24AaQBjAG8AbQBvAG8AbkZvbnQgZ2VuZXJhdGVkIGJ5IEljb01vb24uAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4ALgAAAAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=")
			format("woff");
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1; /* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.lpcms-player .video-js.vjs-default-skin.vjs-fluid, .lpcms-player .video-js.vjs-default-skin {
	* {
		outline: none !important;
	}
	.vjs-volume-tooltip {
		display: none !important;
	}
	.vjs-thumbnail {
		width: 200px;
	}
	.vjs-progress-control:hover .vjs-time-tooltip {
		font-size: 12px;
		padding-bottom: 6px;
		top: -33px;
	}
	.vjs-quality-selector, .vjs-http-source-selector {
		display: block;
		.vjs-menu-button {
			margin: 0;
			padding: 0;
			height: 100%;
			width: 100%;
		}
		.vjs-icon-placeholder {
			font-family: "VideoJS";
			font-weight: normal;
			font-style: normal;
		}
		.vjs-icon-placeholder:before {
			content: "\f110";
		}
	}
	.vjs-quality-changing .vjs-big-play-button {
		display: none;
	}
	.vjs-quality-changing .vjs-control-bar {
		display: flex;
		visibility: visible;
		opacity: 1;
	}


	.vjs-theater-mode-control-open,
	.vjs-theater-mode-control-close {
		font-family: "videojs-theaterMode";
		float: right;
		cursor: pointer;
	}

	.vjs-theater-mode-control-open.vjs-control:before, .vjs-theater-mode-control-close.vjs-control:before {
		content: " ";
		height: 18px;
		width: 28px;
		display: block;
		border: 2px solid $color-white;
		margin-left: 6px;
	}
	.vjs-menu-button-popup .vjs-menu {
		bottom: 19px;
		height: auto;
		padding-bottom: 24px;
		.vjs-menu-content {
			position: relative;
			font-size: 12px;
			border-radius: 3px;
			background-color: $color-grey-50;
			padding: 10px;
			bottom: 0;
		}
		li {
			text-align: left;
		}
		li.vjs-selected, li.vjs-menu-item:hover {
			background-color: transparent;
			color: $color-coral;
		}
	}
	.vjs-current-time {
		padding-right: 8px;
		&:after {
			content: "/";
			font-size: 14px;
			line-height: 43px;
			margin-left: 7px;
		}
	}
	.vjs-duration {
		padding-left: 0;
		color: rgba(228, 228, 228, 0.69);
		flex-grow: 1;
    	text-align: left;
	}
	.vjs-time-control {
		display: block;
		line-height: 43px;
		font-size: 14px;
	}
	.vjs-remaining-time {
		display: none;
	}
	.vjs-big-play-button {
		border: 0;
		background-color: transparent;
		border-radius: 0;
		font-size: 150px;
		line-height: 1em;
		height: 1em;
		width: 1em;
		left: 50%;
		top: 50%;
		margin-top: -0.5em;
		margin-left: -0.5em;
	}
	.vjs-menu li.vjs-menu-item:focus {
		background: none;
	}
	.vjs-control-bar {
		height: 43px;
		background-color: $color-grey-50;
	}
	.vjs-play-control {
		width: 45px;
	}
	.vjs-time-controls {
		-webkit-box-ordinal-group: 9;
		-moz-box-ordinal-group: 9;
		-ms-flex-order: 9;
		-webkit-order: 9;
		order: 9;
	}
	.vjs-current-time,
	.vjs-no-flex .vjs-current-time {
		display: block;
	}
	.vjs-duration,
	.vjs-no-flex .vjs-duration {
		display: block;
	}
	.vjs-time-divider {
		display: block;
	}
	.vjs-slider {
		background-color: #1b1d1f;
	}
	.vjs-slider {
		margin: 0;
	}
	.vjs-control.vjs-progress-control {
		height: 5px;
		width: 100%;
	}
	.vjs-skin-twitchy:hover .vjs-control.vjs-progress-control {
		height: 3em;
		top: -3em;
	}
	.vjs-skin-twitchy:hover .vjs-control.vjs-progress-control .vjs-play-progress,
	.vjs-skin-twitchy:hover .vjs-control.vjs-progress-control .vjs-load-progress {
		height: 3em;
	}
	.vjs-progress-control {
		display: border-box;
		position: absolute;
		top: -5px;
		left: 0;
		right: 0;
	}
	.vjs-load-progress div {
		background: $color-grey1;
	}
	.vjs-load-progress {
		background: $color-grey1;
	}
	.vjs-play-progress {
		background-color: $color-coral;
	}
	.vjs-progress-control:hover .vjs-progress-holder {
		font-size: 1em;
	}
	.vjs-play-progress:before {
		content: "\f111";
		position: absolute;
		font-size: 16px;
		top: -6px;
		right: -10px;
	}
	.vjs-progress-holder {
		height: 100%;
		-webkit-transition: height 0.5s;
		-moz-transition: height 0.5s;
		-ms-transition: height 0.5s;
		-o-transition: height 0.5s;
		transition: height 0.5s;
	}

	.vjs-progress-control:hover .vjs-play-progress .vjs-time-tooltip {
		display: none;
	}
	.vjs-progress-control .vjs-mouse-display .vjs-time-tooltip {
		background-color: rgba(255, 255, 255, 0.8);
		border-radius: 0.3em;
		color: #000;
		font-size: 12px;
		font-family: Arial, Helvetica, sans-serif;
		padding: 6px 8px 6px 8px;
		pointer-events: none;
	}
	.vjs-volume-bar.vjs-slider-horizontal {
		width: 8em;
		height: 5px;
	}
	.vjs-volume-control {
		align-items: center;
	}
	.vjs-volume-panel {
		width: 12em !important;
		.vjs-volume-control.vjs-volume-horizontal {
			height: 100% !important;
			width: 10em !important;
			opacity: 1;
			visibility: visible;
		}
		.vjs-volume-control {
			margin-left: 9px;
		}
	}

	.vjs-volume-level {
		background-color: $color-white;
	}
	.vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level {
		width: 100%;
		height: 5px;
		&:before {
			font-size: 16px;
			top: -6px;
		}
	}
	.vjs-volume-bar.vjs-slider-horizontal .vjs-volume-handle {
		left: 4.3em;
	}
	.vjs-mute-control {
		width: 2.5em;
	}
	.vjs-volume-bar .vjs-volume-handle:before {
		font-size: 2em;
		top: -0.35em;
		left: -0.1em;
		content: "▮";
	}
	.vjs-custom-control-spacer {
		display: flex;
		-webkit-box-flex: auto;
		-moz-box-flex: auto;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}
	.vjs-fullscreen-control {
		text-align: right;
		padding-right: 5px;
		-webkit-box-ordinal-group: 10;
		-moz-box-ordinal-group: 10;
		-ms-flex-order: 10;
		-webkit-order: 10;
		order: 10;
	}
	.vjs-fullscreen-control:before {
		position: relative;
	}
	.vjs-button > .vjs-icon-placeholder:before {
		font-size: 27px;
		margin-top: -2px;
	}
	.vjs-menu-button > div.vjs-playback-rate-value {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.vjs-chromecast-button .vjs-icon-placeholder {
		width: 24px;
    	height: 24px;
	}
}