ul.pagination {
	display: flex;
	justify-content: center;
	margin-bottom: 45px;
	font-size: 14px;
	font-weight: 500;
}
// .page-link[rel=next], .page-link[rel=prev] {
// 	color: $color-primary;
// }

.page-item {
	display: block;
	text-align: center;
	margin-right: 4px;
	border-radius: 5px;
	border: 1px solid transparent;
	&.disabled {
		&.dots {
			width: 15px;
			min-width: 15px;
		}
	}
	&:last-of-type {
		margin-right: 0;
	}
}

.page-item.active {
	border-color: $color-primary;
	color: $color-primary;
}
.page-item.disabled, .page-item.active, ul.pagination li a {
	line-height: 34px;
	width: 34px;
	min-width: 34px;
	display: block;
}

@media screen and (max-width: 600px) {
	ul.pagination {
		li:first-of-type, li:last-of-type {
			display: none;
		}
		.page-item:nth-last-child(2) {
			margin-right: 0;
		}
	}
}