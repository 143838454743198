.topnav-right a {
	margin-right: 15px;
}

#siteheader-mobile {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	.siteheader-mobile-icons {
		display: flex;
		.mailer-loggedout-link {
			position: relative;
			.badge {
				display: block;
			}
		}
	}
	.badge-info-red {
		top: 9px;
		right: 9px;
		display: none;
	}
	a {
		display: flex;
		align-items: center;
	}
	.material-symbols-rounded {
		cursor: pointer;
		padding: 12px;
	}
	.icon {
		font-size: 24px;
		&.icon-LPCMS_Mainbrand {
			font-size: 30px;
			padding-left: 12px;
		}
		&.icon-streamline-icon-navigation-menu18x18, &.icon-streamline-icon-single-man18x18, &.icon-streamline-icon-alarm-bell-118x18 {
			padding: 12px;
			cursor: pointer;
		}
	}
}
#mobile-mainmenu {
	display: flex;
	height: 100%;
	width: 270px;
	position: fixed;
	z-index: 999;
	margin-right: -270px;
	flex-direction: column;
	top: 0;
	right: 0;
	background-color: $color-white;
	overflow-x: hidden;
	transition: 0.55s ease-in-out;
	.close-icon {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 0;
	}
	.inner {
		height: 100%;
		.mobile-mainmenu-signup-login {
			padding: 56px 20px 24px 20px;
		}
		#mobile-mainmenu-signuplink, #mobile-mainmenu-loginlink {
			padding: 9px 24px;
			border-radius: 99px;
			text-align: center;
			font-weight: normal;
			font-size: 16px;
			display: block;
			line-height: 1;
		}
		#mobile-mainmenu-signuplink {
			background-color: $color-coral;
			color: $color-white;
			border: 2px solid $color-coral;
			margin-bottom: 8px;
		}
		#mobile-mainmenu-loginlink {
			border: 2px solid $color-dark;
			color: $color-dark;
		}
		#mobile-mainmenu-accountinfos {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			padding: 0 20px 24px 20px;
			margin-top: 56px;
			h2 {
				color: $color-dark;
				font-size: 14px;
				font-weight: 400;
				margin-bottom: 4px;
			}
			.coins {
				color: $color-coral;
				i {
					font-size: 18px;
					margin-right: 5px;
				}
				span {
					font-size: 20px;
					font-weight: 500;
				}
			}
			#mobile-mainmenu-accountinfos-paymenttrigger {
				margin-bottom: 4px;
				.button {
					padding: 4px 16px;
					font-weight: 500;
				}
			}
		}
		#mobile-mainmenu-linklist-account {
			padding: 0 20px;
			.mobile-mainmenu-linklist-mailer .badge {
				display: none;
				right: 9px;
				top: 9px;
				padding: 5px 5px;
				border-radius: 99px;
			}
			.linklist {
				display: flex;
				justify-content: space-between;
				border-top: 1px solid #f1f1f1;
				padding: 12px 0 24px 0;
				.material-symbols-rounded {
					padding: 12px 12px 6px 12px;
				}
				a {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 10px;
					position: relative;
					span {
						line-height: 1;
					}
				}
			}
		}
		#mobile-mainmenu-linklist-site {
			.linklist {
				display: flex;
				flex-direction: column;
				padding: 0 20px;
				a {
					font-size: 16px;
					position: relative;
					line-height: 44px;
					font-weight: 400;
					text-indent: 25px;
					border-bottom: 1px solid rgba(241, 241, 241, 1);
					&:first-of-type {
						border-top: 1px solid rgba(241, 241, 241, 1);
					}
					.sale--video {
						position: absolute;
						top: -12px;
						right: 40px;
						padding: 5px 8px 3px 8px;
						font-size: 11px;
						letter-spacing: 0.5px;
						font-weight: 600;
						float: right;
						margin-right: 85px;
					}
				}
			}
		}
		#mobile-mainmenu-banner {
			display: none;
		}
		#mobile-mainmenu-bottomlinks {
			display: flex;
			justify-content: space-between;
			margin-top: 25px;
			padding: 8px 20px 20px 20px;
			.material-symbols-rounded {
				padding: 12px 12px 6px 12px;
			}
			a {
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 12px;
				.icon {
					margin-bottom: 8px;
    			font-size: 20px;
				}
			}
		}
	}

	&.open {
		margin-right: 0px;
		box-shadow: -20px 0px 25px 0px rgba(10,10,10,0.15);
	}
}
header {
	width: 100%;
	left: 0;
	right: 0;
	padding-left: 0;
	padding-right: 0;
}
.mobile-mainmenu-linklist-mailer {
	position: relative;
}
