
#videopage-container {
	display: flex;
	flex-direction: column;
	margin-top: 0px;
	.vjs-theater-mode-control-open {
		display: none;
	}
	h1 {
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 15px;
	}
	#videopage-media {
		width: 100%;
		float: none;
		order: 1;
		margin-bottom: 0px;
		.video-preview-mainimage img {
			width: 100%;
		}
		.addcomment {
			padding: 12px 16px;
			font-size: 14px;
		}
	}
	#videopage-recommendations {
		width: 100%;
		margin-top: 15px;
		float: none;
		order: 3;
		li.medialist__item {
			.medialist__item-image img, .medialist__item-image video {
				width: 100%;
			}
		}
	}
	#videopage-videodata {
		float: none;
		width: 100%;
		order: 2;
		margin-top: 10px;
		.video-price {
			float: right;
		}
		#videopage-switchbottomcontent {
			display: flex;
			justify-content: space-around;
			margin-top: 12px;
			color: $color-dark;
			div {
				&.active {
					font-weight: 600;
					display: flex;
					flex-direction: column;
					align-items: center;
					&:after {
						content: "";
						display: block;
						height: 2px;
						border-radius: 3px;
						width: 35px;
						margin-top: 2px;
						background-color: $color-coral;
					}
				}
			}
		}
		#videopage-information {
			margin-top: 10px;
			flex-direction: column;
			#videopage-description {
				width: auto;
				font-size: 16px;
			}
			#videopage-meta-right {
				max-width: none;
			}
		}
		#videopage-commentcontainer {
			padding: 20px;
			margin-top: 15px;
			width: calc(100vw - 40px);
			margin-left: -20px;
			margin-bottom: 0;
			textarea {
				font-size: 15px;
			}
			h2 {
				font-size: 18px;
			}
		}
	}
	#videopage-buyvideo {
		padding: 14px 32px;
		font-size: 16px;
		border-radius: 99px;
		margin-top: 20px;
		margin-bottom: 10px;
		width: 100%;
		line-height: 1;
	}
}
