ul.medialist.medialist_cam {
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
	li.medialist__item {
		&.medialist__cam {
			aspect-ratio: 4 / 3;
			.medialist_cam__item-image {
				overflow: hidden;
			}
			.sale--video {
				right: auto;
			}
		}
		&:nth-child(3n+0) {
			margin-right: 0;
		}
		.medialist__item-categories {
			font-size: 11px;
			margin-top: 10px;
			line-height: 16px;
			.icon {
				font-size: 16px;
				float: left;
				margin-right: 6px;
				margin-top: -1px;
			}
			a.medialist__item-category {
				color: #59595a;
				font-weight: 400;
				text-transform: uppercase;
				letter-spacing: 1px;
			}
		}
		.medialist__cam-details {
			position: absolute;
			bottom: 0px;
			left: 0px;
			display: flex;
			z-index: 4;
			width: 100%;
			height: 31px;
			.medialist__cam-title {
				padding: 10px;
				color: $color-white;
				z-index: 11;
				font-size: 12px;
				font-weight: normal;
				width: 45%;
				background: transparentize($color-dark, 0.5);
				overflow: hidden;
				white-space: nowrap;
				border-radius: 0 0 0 5px;
				display: flex;
				align-items: center;
				a {
					text-overflow: ellipsis;
					overflow: hidden;
					color: $color-white;
					font-size: 14px;
				}
			}
			.medialist__item-detail {
				font-size: 14px;
				background: $color-grey1;
				color: #59595a;
				width: 45%;
				padding: 0 5px;
				display: flex;
				align-items: center;
				&.medialist__item-detail-date {
					flex-grow: 1;
					align-self: flex-start;
					.icon-LPCMS_brandneu {
						color: $color-red;
					}
				}
				&.medialist__item-detail-comments, &.medialist__item-detail-duration {
					align-self: flex-end;
				}
				&.medialist__item-detail-duration {
					margin-left: 15px;
				}
				.medialist__item-detail-coins {
					font-size: 9px;
					line-height: 11px;
					color: #59595a;
					margin-top: 1px;
				}
				.livecam_start {
					color: $color-coral;
					font-size: 11px;
					font-weight: 600;
					line-height: 11px;
				}
			}
			.cam-add-to-favorites {
				border-radius: 0 0 5px 0;
			}
		}
	}
	&.purchases {
		.sale--video {
			display: none;
		}
	}
}
.dropdown_cam {
	background-color: $color-coral;
	border-radius: 3px;
	padding: 4px 30px 4px 30px;
	font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
	display: flex;
	align-items: center;
	color: $color-white;
	text-transform: uppercase;
	cursor: pointer;
	.icon {
		font-size: 15px;
		margin-right: 8px;
		cursor: pointer;
	}
	&.active {
		color: $color-dark;
		background-color: #F1F1F1;
	}
	&.toggletrigger.active .icon {
		color: $color-dark;
	}
}
ul.imagegallery {
	li {
		img {
			cursor: pointer;
			width: 100%;
		}
	}
}
.sale--video {
	position: absolute;
	top: 5px;
	right: 5px;
	color: $color-white;
	background-color: #FF0032;
	padding: 6px;
	font-size: 13px;
	font-weight: 600;
	z-index: 11;
	border-radius: 5px;
	line-height: 1;
}

.medialist_cam__item-image {
	position: relative;
	img {
		display: block;
		visibility: visible;
		width: 100%;
		border-radius: 5px;
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
		&.hidden {
			display: none;
		}
	}
	a {
		display: block;
	}
	video {
		width: 100%;
		max-height: 300px;
		display: none;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
	}
	&.has-video {
		&:hover, &:active {
			-webkit-touch-callout: none;
			video {
				display: block;
			}
			img {
				visibility: hidden;
			}
		}
	}
	&.is-vertical {
		a {
			display: flex;
			justify-content: center;
			background-color: #000000;
			img {
				height: 100%;
			}
			video {
				object-fit: contain;
				height: 100%;
			}
		}
	}
	.icon-streamline-icon-rating-star18x18 {
		position: absolute;
		top: 10px;
		left: 10px;
		color: $color-white;
		background-color: $color-dark;
		padding: 6.5px 9px;
		font-size: 12px;
		z-index: 11;
		border-radius: 5px;
	}

	.medialist_cam__topicons {
		position: absolute;
		top: 5px;
		left: 5px;
		right: 5px;
		.icon {
			color: $color-white;
			float: right;
			font-size: 24px;
			z-index: 11;
			margin-left: 10px;
		}
	}
	.icon-streamline-icon-love-it-alternate18x18, .icon-streamline-icon-love-it24x24 {
		cursor: pointer;
		color: $color-coral;
		background-color: $color-dark;
		font-size: 17px;
		width: 10%;
		z-index: 5;
		display: flex;
		align-items: center;
		justify-content: center;
		&:before {
			height: 17px;
			width: 17px;
		}
	}
}

ul.medialist {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 48px 16px;
	margin-bottom: 40px;
	li.medialist__item {
		display: flex;
    	flex-direction: column;
		&:hover .medialist__item-image .icon-streamline-icon-rating-star18x18{
			width: 102px;
			transition: 200ms;
		}
		&:hover .medialist__item-image .icon-streamline-icon-rating-star18x18 span {
			opacity: 1;
			transition-property: opacity;
  		transition-duration: 200ms;
  		transition-timing-function: ease-in-out;
  		transition-delay: 100ms;
		}
		&.medialist__cam {
			.sale--video {
				right: auto;
				top: 0;
			}
		}
		.medialist__item-infos {
			padding-top: 12px;
			height: 100%;
		}
		.medialist__item-categories {
			font-size: 11px;
			line-height: 16px;
			.icon {
				font-size: 16px;
				float: left;
				margin-right: 6px;
				margin-top: -1px;
				color: #8A96A3;
			}
			a.medialist__item-category {
				color: #8A96A3;
				font-weight: 400;
				text-transform: uppercase;
				letter-spacing: 1px;
			}
		}
		.medialist__item-title {
			font-size: 15px;
			font-weight: 400;
			padding-bottom: 10px;
			line-height: 20px;
			@media screen and (max-width: 1440px)  {
				font-size: 14px;
			}
			a {
				/* autoprefixer: off */
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
		.medialist__item-details {
			display: flex;
			.medialist__item-detail {
				font-size: 12px;
				letter-spacing: 0.5px;
				color: #8A96A3;
				&.medialist__item-detail-date {
					flex-grow: 1;
					align-self: flex-start;
					.icon-LPCMS_brandneu {
						color: $color-red;
					}
				}
				&.medialist__item-detail-comments, &.medialist__item-detail-duration {
					display: flex;
					align-items: center;
					i {
						margin-right: 5px;
					}
				}
				&.medialist__item-detail-duration {
					margin-left: 15px;
				}
			}
		}
	}
	&.purchases {
		.sale--video {
			display: none;
		}
	}
	&.handyvideos-only {
		grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
		.medialist__item-image.is-vertical a video {
			max-height: none;
			object-fit: cover;
		}
	}
}
#swiper-girls ul.medialist.handyvideos-only {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	@media screen and (min-width: 1024px) and (max-width: 1300px) {
		&medialist__item:nth-child(5) {
			display: flex;
		}
	}
}
@media screen and (min-width: 1024px) and (max-width: 1550px) {
	#swiper-girls .handyvideos-only li.medialist__item:nth-child(6) {
		display: flex;
	}
}
@media screen and (min-width: 1024px) and (max-width: 1300px) {
	#swiper-girls .handyvideos-only li.medialist__item:nth-child(5) {
		display: flex;
	}
}

#video-filter-menu {
	background-color: $color-white;
	box-shadow: 0px 3px 16px rgba(138, 150, 163, 0.12);
	padding: 20px;
	border-radius: 5px;
	margin-bottom: 30px;
	position: relative;

	.video-filter-menu-left {
		margin-right: 30px;
		span {
			font-size: 12px;
			display: block;
			margin-bottom: 12px;
			color: #8A96A3;
			text-transform: uppercase;
		}
	}

	.video-filter-menu-right {
		width: 450px;
		a img {
			width: 100%;
			display: block;
			border-radius: 3px;
			height: 100px;
			object-fit: cover;
		}
	}

	#video-filter-menu-btnsleft {
		display: flex;
		flex-direction: column;
		margin-right: 40px;
		margin-left: 20px;
		.icon {
			margin-right: 5px;
		}
		a {
			width: 136px;
			height: 46px;
			border-radius: 3px;
			color: $color-white;
			background-image: url(#{$cdn}/images/static/categories/cat_bg_allevideo.png);
			background-repeat: no-repeat;
			background-size: contain;
			display: inline-block;
			padding: 12px;
			margin-bottom: 15px;
			&:nth-child(2) {
				background-image: url(#{$cdn}/images/static/categories/cat_bg_topvideos.png);
			}
		}
	}
	#video-filter-menu-categories {
		display: flex;
    flex-wrap: wrap;
		a {
			font-size: 16px;
			line-height: 1.5;
			font-weight: 400;
			color: #8A96A3;
			background-color: #F5F6FA;
			border-radius: 100px;
			padding: 4px 14px;
			display: inline-block;
			margin-bottom: 6px;
			margin-right: 6px;
			border: 2px solid #F5F6FA;
			transition: 400ms ease-out;
			&:hover {
				background-color: $color-white;
				border: 2px solid #8A96A3;
				transition: 250ms ease-in;
			}
			&#video-filter-menu-handyvideos {
				background-image: url(#{$cdn}/images/static/categories/cat_bg_handy.png);
				background-repeat: no-repeat;
				background-size: contain;
				display: none;
			}
			&#video-filter-menu-allvideos, &#video-filter-menu-topvideos {
				background-color: $color-coral;
				border-color: $color-coral;
				color: $color-white;
				&:hover {
					color: $color-coral;
					background-color: $color-white;
				}
			}
		}
	}
	.searchform {
		position: relative;
		width: 450px;
		margin-bottom: 15px;
		img {
			margin-top: 30px;
			margin-bottom: -6px;
		}
		.icon {
			position: absolute;
			right: 12px;
			top: 12px;
			font-size: 19px;
			cursor: pointer;
		}
	}
	input.searchbox {
		height: 43px;
		width: 100%;
		box-sizing: border-box;
	}
}
ul.imagegallery {
	li {
		img {
			cursor: pointer;
			width: 100%;
		}
	}
}
.aspect-ratio-inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.aspect-ratio-259-460 {
	padding-top: 259px / 460px * 100%;
	overflow: hidden;
	height: 0;
}
.aspect-ratio-428-760 {
	padding-top: 428px / 760px * 100%;
	overflow: hidden;
	height: 0;
}

.aspect-ratio-320-220 {
	padding-top: 320px / 220px * 100%;
	overflow: hidden;
	height: 0;
}

.medialist__item-image {
	position: relative;
	img {
		display: block;
		opacity: 1;
		visibility: visible;
		width: 100%;
		border-radius: 8px;
		&.hidden {
			display: none;
		}
	}
	a {
		display: block;
	}
	video {
		width: 100%;
		max-height: 300px;
		min-height: 100%;
		visibility: hidden;
		opacity: 0;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 8px;
	}
	&.has-video {
		img, video {
			transition: 500ms;
		}
		&:hover, &:active {
			-webkit-touch-callout: none;
			video {
				visibility: visible;
				opacity: 1;
			}
			img {
				opacity: 0;
				visibility: hidden;
			}
		}
	}
	&.is-vertical {
		a {
			display: flex;
			justify-content: center;
			background-color: #000000;
			border-radius: 8px;
			img {
				height: 100%;
			}
			video {
				object-fit: contain;
				height: 100%;
			}
		}
	}
	.icon-streamline-icon-rating-star18x18 {
		position: absolute;
		width: 24px;
		box-sizing: border-box;
		top: 5px;
		left: 5px;
		color: $color-white;
		background-color: $color-dark;
		padding: 6px;
		font-size: 12px;
		z-index: 11;
		border-radius: 6px;
		transition: 200ms;
		transition-delay: 100ms;
		span {
			opacity: 0;
			transition-property: opacity;
  		transition-duration: 200ms;
  		transition-timing-function: ease-in-out;
			text-transform: uppercase;
			font-family: "Poppins", sans-serif;
			margin-left: 6px;
			font-weight: 700;
			letter-spacing: 2px;
		}
	}
}

/* ### MEDIALIST PORTRAIT START ### */

#bilderlisting h1 {
	padding: 40px 0 20px 0;
}

.medialist-portrait {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
	grid-gap: 20px;
	padding-bottom: 30px;
}

.medialist-portrait li {
	min-width: 0;
	display: flex;
	flex-direction: column;
}

.medialist-portrait img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 5px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}

.medialist-portrait__title {
	white-space: nowrap;
}

.medialist-portrait__title a {
	font-size: 15px;
	line-height: 1.25;
	font-weight: 600;
	letter-spacing: -0.63px;
	padding: 10px 0;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
}

.medialist-portrait__details {
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid #F1F1F1;
	padding-top: 10px;
	align-items: center;
}

.medialist-portrait__details span {
	margin-left: 5px;
	color: rgba(16, 16, 20, 0.3);
	font-size: 14px;
	padding: 0;
	&.material-symbols-rounded {
		margin-bottom: 2px;
	}

}

@media screen and (max-width: 1024px) {
	#bilderlisting h1 {
		padding: 20px 0 20px 0;
		font-weight: 400;
	}
	.medialist-portrait {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media screen and (max-width: 768px) {
	.medialist-portrait {
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	}
}

@media screen and (max-width: 500px) {
	#site-main #videopage-container #videopage-media #video-preview-mainimage .sale--video {
		font-size: 18px;
		padding: 11px 16px 8px 16px;
		top: 15px;
		right: 15px;
	}
}

@media screen and (max-width: 425px) {
	.medialist-portrait {
		grid-gap: 15px;
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 320px) {
	.medialist-portrait {
		grid-template-columns: 1fr;
		grid-gap: 20px;
	}
}

/* ### MEDIALIST PORTRAIT ENDE ### */
.medialist-portrait__thumbnail {
	position: relative;
	height: 100%;
}
@import "medialist_mobile";



/* ### VIDEOSEITE START ### */

#video-overview {
	.featured-video {
		position: relative;
		height: 0;
		padding-top: calc(470 / 1440 * 100%);
		margin-bottom: 40px;
		.new-batch, .play-button i {
			position: absolute;
			z-index: 1;
		}
		.new-batch {
			left: 40px;
			top: 0;
			font-size: 135px;
			line-height: 1;
			color: #7FA9B5;
			.icon-lp-highlight-label {
				position: relative;
				div {
					display: flex;
					flex-direction: column;
					align-items: center;
					position: absolute;
					top: 50%;
					transform: translate(3%, -60%);
				}
			}
			.icon-puricons_Crown2020 {
				font-size: 22px;
				color: $color-white;
				margin-bottom: 8px;
			}
			span {
				font-size: 19px;
				text-transform: uppercase;
				font-family: "Poppins", sans-serif;
				color: $color-white;
				letter-spacing: 2.7px;
				text-indent: 2.7px;
			}
		}
		.play-button i {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 150px;
			color: rgba(255, 255, 255, 0.5);
		}
		video {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
    	object-fit: cover;
    	object-position: center;
		}
	}
	.video-headline {
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		a:first-of-type {
			font-size: 24px;
			font-weight: 600;
		}
		a:last-of-type {
			font-size: 14px;
			text-decoration: underline;
			transition: 250ms ease-in-out;
			&:hover {
				color: #8A96A3;
				transition: 250ms ease-in-out;
			}
		}
	}
	ul.medialist {
		margin-bottom: 60px;
	}
	.votw {
		margin-bottom: 40px;
		position: relative;
		display: grid;
    grid-template-columns: 1fr 1fr;
		background-color: rgba(138, 150, 163, 0.1);
		border-radius: 5px;
		.medialist.votw__video {
			margin-bottom: 0;
			display: block;
			.sale--video {
				display: none;
			}
			.medialist__item {
				border-radius: 5px 0 0 5px;
				.medialist__item-infos {
					display: none;
				}
				.medialist__item-image {
					border-radius: 5px 0 0 5px;
					img {
						border-radius: 5px 0 0 5px;
					}
					video {
						border-radius: 5px 0 0 5px;
						max-height: none;
						height: 100%;
					}
				}
			}
		}
		.votw__info {
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.votw__limited {
				margin-bottom: 30px;
				> span {
					letter-spacing: 6px;
					margin-bottom: 5px;
					text-align: center;
					display: block;
					text-transform: uppercase;
					font-size: 16px;
				}
				.votw__time {
					display: flex;
					justify-content: center;
					&-item {
						display: flex;
						justify-content: center;
						flex-direction: column;
						align-items: center;
						margin-right: 10px;
						&:last-of-type {
							margin-right: 0;
						}
						span:first-of-type {
							font-size: 45px;
							font-weight: 400;
							letter-spacing: 13.5px;
							text-indent: 13.5px;
						}
						span:last-of-type {
							font-size: 12px;
							letter-spacing: 0.45px;
							line-height: 1;
						}
					}
				}
			}
			.votw__details {
				max-width: 500px;
				margin: 0 auto 30px auto;
				.votw__title {
					font-size: 20px;
					font-weight: 400;
					color: #8A96A3;
					display: block;
					margin-bottom: 10px;
				}
				.votw__meta {
					display: flex;
					justify-content: center;
					font-size: 12px;
					color: #8A96A3;
					&-comments {
						margin-right: 15px;
					}
				}
			}
		}
		.button {
			display: inline-block;
			text-transform: uppercase;
			background-color: #101014;
			background-image: none;
			font-size: 16px;
			padding: 9px 46px;
			border: 2px solid #101014;
			box-shadow: 0px 2px 15px 0px rgba(16, 16, 20, 0.4);
			transition: 500ms ease-in-out;
			&:hover {
				background-color: transparent;
				color: #101014;
				box-shadow: none;
				transition: 250ms ease-in-out;
			}
		}
		.votw__batch {
			position: absolute;
			left: 50%;
			top: 20px;
			transform: translateX(-50%);
			font-size: 42px;
			background-color: #FF0032;
			border-radius: 5px;
			font-weight: 600;
			color: $color-white;
			padding: 10px 20px;
		}
	}
	.exclusive-videos, .magic-videos {
		background-color: rgba(138, 150, 163, 0.1);
		padding: 60px 0;
		margin-bottom: 60px;
		position: relative;
		.bg-icon {
			position: absolute;
			top: 50%;
			right: 2%;
			height: 95%;
			z-index: -1;
			transform: translateY(-50%);
		}
		h2 {
			margin-bottom: 20px;
			font-size: 30px;
			font-weight: 700;
		}
		p {
			margin-bottom: 52px;
			max-width: 900px;
			font-weight: 600;
		}
		ul {
			margin-bottom: 40px;
		}
		.more-link {
			text-align: center;
			text-decoration: underline;
			font-size: 14px;
			color: #8A96A3;
			display: block;
		}
	}
	.category__listing {
		display: flex;
		flex-wrap: wrap;
		margin-right: -8px;
		margin-bottom: 52px;
		li {
			padding: 0;
			background-color: transparent;
			border: 1px solid $color-dark;
			border-radius: 5px;
			text-align: center;
			margin: 0 8px 8px 0;
			a {
				padding: 8px 16px;
				display: block;
				font-weight: 500;
				color: $color-dark;
			}
		}
	}
}

@media screen and (max-width: 1560px) {
	#video-overview {
		.votw {
			.votw__batch {
				font-size: calc(22px + (42 - 22) * ((100vw - 768px) / (1560 - 768)));
			}
			.votw__info {
				.votw__limited .votw__time-item span:first-of-type {
				font-size: calc(24px + (45 - 24) * ((100vw - 768px) / (1560 - 768)));
				letter-spacing: calc(9.5px + (13.5 - 9.5) * ((100vw - 768px) / (1560 - 768)));
				text-indent: calc(9.5px + (13.5 - 9.5) * ((100vw - 768px) / (1560 - 768)));
				}
				.votw__details .votw__title {
					font-size: calc(14px + (18 - 14) * ((100vw - 768px) / (1560 - 768)));
				}
				.button {
					font-size: calc(14px + (16 - 14) * ((100vw - 768px) / (1560 - 768)));
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	#video-overview {
		.votw {
			.votw__batch {
				top: 10px;
				padding: 5px 10px;
			}
			.votw__info .votw__limited {
				margin-bottom: 15px;
				> span {
					font-size: 14px;
					margin-bottom: 0;
				}
			}
			.votw__info .votw__details {
				margin-bottom: 15px;
				padding: 0 25px;
				max-width: 400px;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	#video-overview {
		.content-limited {
			overflow: hidden;
			&.featured-video-container {
				padding: 0;
			}
		}
		.featured-video {
			.play-button i {
				font-size: 14.6484375vw;
			}
			.new-batch {
				left: 20px;
				font-size: 110px;
				.icon-puricons_Crown2020 {
					font-size: 20px;
				}
				span {
					font-size: 14px;
				}
				.icon-lp-highlight-label div {
					transform: translate(4%, -60%);
				}
			}
		}
		.votw {
			.votw__info {
				.votw__limited {
					margin-bottom: 10px;
					> span {
						font-size: 12px;
					}
					.votw__time-item span:last-of-type {
						font-size: 10px;
					}
				}
				.votw__details {
					margin-bottom: 10px;
					.votw__title {
						margin-bottom: 5px;
					}
					.votw__meta {
						display: none;
					}
				}
				.button {
					padding: 6px 38px;
				}
			}
		}
		.exclusive-videos, .magic-videos {
			h2 {
				margin-bottom: 10px;
			}
			p {
				margin-bottom: 35px;
			}
		}
		ul.medialist {
			grid-template-columns: repeat(8, 300px);
			&.handyvideos-only {
				grid-template-columns: repeat(8, 225px);
			}
		}
		.category__listing {
			flex-wrap: nowrap;
			li {
				margin-bottom: 0;
				padding: 0;
				&:first-child {
					margin-left: 20px;
				}
				a {
					display: block;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#video-overview {
		.featured-video {
			padding-top: calc(164 / 375 * 100%);
			.play-button i {
				font-size: 14.6484375vw;
			}
			.new-batch {
				font-size: 90px;
				.icon-puricons_Crown2020 {
					font-size: 18px;
				}
				span {
					font-size: 10px;
				}
				.icon-lp-highlight-label div {
					transform: translate(6%, -70%);
				}
			}
		}
		.votw {
			display: flex;
			flex-direction: column-reverse;
			margin-left: -20px;
			margin-right: -20px;
			padding: 40px 20px;
			.medialist.votw__video .medialist__item .medialist__item-image .sale--video {
				left: 10px;
				right: unset;
				top: 10px;
				font-size: 32px;
				padding: 10px 15px;
				border-radius: 5px;
			}
			.votw__info {
				.votw__limited .votw__time-item span:first-of-type {
				font-size: calc(33px + (46 - 33) * ((100vw - 375px) / (768 - 375)));
				letter-spacing: 13.5px;
    		text-indent: 13.5px;
				}
			}
			.medialist.votw__video {
				margin-bottom: 0;
				display: block;
				.medialist__item {
					.medialist__item-infos {
						display: block;
					}
					.medialist__item-image {
						border-radius: 5px 5px 0 0;
						.sale--video {
							display: block;
						}
						.icon {
							display: none;
						}
						video {
							border-radius: 5px 5px 0 0;
							max-height: none;
						}
					}
				}
			}
			.votw__info {
				.votw__details {
					display: none;
				}
				.votw__limited {
					margin-bottom: 20px;
					> span {
						font-size: 15px;
						margin-bottom: 5px;
					}
				}
				div:last-of-type {
					display: none;
				}
			}
			.votw__batch {
				display: none;
			}
		}
		ul.medialist {
			grid-template-columns: repeat(8, 335px);
			grid-gap: 8px;
		}
	}
}

@media screen and (max-width: 425px) {
	#video-overview {
		.swiper-container {
			ul.medialist {
				li {
					width: calc(100% - 40px) !important;
				}
				&.handyvideos-only {
					li {
						width: 225px !important;
					}
				}
			}
		}
		.video-headline a:first-of-type {
			font-size: 20px;
		}
		.featured-video {
			margin-bottom: 35px;
			.new-batch {
				font-size: 70px;
				.icon-puricons_Crown2020 {
					font-size: 14px;
				}
				span {
					font-size: 10px;
					letter-spacing: 1px;
    			text-indent: 1px;
				}
			}
		}
		.votw {
			padding: 35px 20px;
			margin-bottom: 30px;
			.medialist.votw__video .medialist__item .medialist__item-image {
				overflow: visible;
				.sale--video {
					left: -10px;
					top: 10px;
					font-size: 24px;
				}
			}
			.votw__info {
				.votw__limited .votw__time-item span:first-of-type {
				letter-spacing: 9.5px;
    		text-indent: 9.5px;
				}
				.votw__limited > span {
					margin-bottom: 0;
				}
			}
		}
		.exclusive-videos, .magic-videos {
			padding: 35px 0;
			margin-bottom: 35px;
			h2 {
				font-size: 24px;
				margin-bottom: 10px;
			}
			p {
				margin-bottom: 20px;
				font-size: 15px;
			}
			ul.medialist {
				margin-bottom: 20px;
			}
		}
		.category__listing {
			margin-bottom: 50px;
			margin-top: 15px;
		}
	}
}

@media screen and (max-width: 375px) {
	#video-overview {
		.votw {
			.votw__info .votw__limited .votw__time-item span:first-of-type {
				font-size: 8.799999999999999vw;
			}
			.button {
				font-size: 15px;
			}
		}
		ul.medialist {
			grid-template-columns: repeat(8, 100%);
		}
	}
}

@media screen and (max-width: 1024px)  {
	#video-overview .swiper-container {
		margin-left: -20px;
		margin-right: -20px;
		&#swiper-categories {
			margin-left: 0;
			margin-right: 0;
		}
		&.swiper-container-css-mode>.swiper-wrapper>.swiper-slide {
			scroll-snap-align: center center;
		}
		ul.medialist {
			display: flex;
			grid-template-columns: none;
			grid-gap: 0px;
			padding-bottom: 35px;
			margin-bottom: 0;
			li {
				width: 300px;
				height: auto;
				&:first-child {
					margin-left: 20px;
				}
			}
			&.handyvideos-only {
				li {
					width: 225px;
				}
			}
		}
	}
}
@media screen and (min-width: 1104px)  {
	#video-overview .swiper-container {
		overflow: visible;
		&.content-limited {
			padding: 0 80px;
		}
		ul.medialist {
			overflow: visible;
		}
	}

	.handyvideos-only {
		@media (max-width: 1550px)  {
			li.medialist__item:nth-child(6) {
				display: none;
			}
		}
		@media (max-width: 1300px)  {
			li.medialist__item:nth-child(5) {
				display: none;
			}
		}
	}
}