.modal {
	position: relative;
}
#modal-buy-video, #modal-buy-gallery {
	height: 100vh;
	.modal-box {
		width: 90%;
		.modal-body {
			text-align: center;
			background-size: 384px 183px;
			background-position: bottom -4px right -4px;
		}
		h1 {
			font-size: 37px;
		}
		#modal-buy-video-topimage, #modal-buy-gallery-topimage {
			width: 96px;
			height: 96px;
			margin-left: -48px;
			top: -48px;
		}
		button {
			font-size: 18px;
			padding: 12px 23px;
		}
	}
}
.modal-box {
	margin: 0 auto;
}
.lpmodal .modal-box {
	width: 100%;
	.modal-body {
		.lpmodal-left {
			display: none;
		}
		.lpmodal-right {
			width: 100%;
			h2 {
				margin-top: 20px;
				margin-bottom: 20px;
			}
			> form, > div.form {
				padding: 20px;
			}
		}
	}
}

#modal-login, #modal-signup  {
	&.modal {
	 background-color: $color-white;
	}
	.modal-box {
		width: 100%;
	}
	.lpmodal-left {
		display: none;
	}
	.lpmodal-right {
		width: 100%;
		h2 {
			margin-top: 0;
			margin-bottom: 16px;
		}
		form, .form {
			max-width: 450px;
			margin: 0 auto;
		}
		#signupform-accept-agb {
			font-size: 12px;
			line-height: 18px;
		}
		label {
			font-size: 13px;
		}
	}
}
