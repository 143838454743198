#modal-payment {
	.modal-box {
		min-height: 650px;
		max-height: 800px;
		height: 90vh;
		.modal-body {
			flex-direction: column;
			justify-content: flex-end;
			.alert {
				font-size: 14px;
				min-width: unset;
			}
			.input-box {
				&:before {
					width: 288px;
				}
			}
			#payment-error-message,
			#payment-success-message,
			.payment-form,
			#payment-elv-new-bic-field,
			.hidden-bic,
			#payment-elv-new-pinform {
				display: none;
			}
			#payment-elv-new {
				font-size: 14px;
				iframe {
					border: 0;
					min-height: 520px;
				}
			}
			#modal-payment-maincontent {
				display: flex;
				flex-grow: 1;
				.lpmodal-left {
					color: white;
					@media screen and (max-width: 1024px) {
						color: $color-dark;
					}
				}
				h2 {
					margin-bottom: 6px;
					margin-left: 30px;
					margin-top: 30px;
				}
				h3 {
					font-weight: normal;
					font-size: 17px;
					margin-left: 30px;
				}
				.lpmodal-right {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					#modal-payment-payment-forms {
						display: none;
						min-width: 330px;
						.payment-form {
							margin-top: 10px;
							input {
								border: 1px solid #8a96a3;
								margin-bottom: 12px;
								margin-top: 0;
								font-family: "Poppins", sans-serif;
								&::placeholder {
									color: #8a96a3;
									font-weight: 300;
								}
							}
							&#payment-cc-new {
								a {
									text-decoration: underline;
								}
							}
							.wpwl-form {
								font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
								color: $color-dark;
								border-radius: 3px;
								input {
									font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
									&::placeholder {
										color: gray;
										font-weight: unset;
									}
								}
								.wpwl-group-expiry {
									width: 50%;
									float: left;
								}
								.wpwl-group-cvv {
									width: 50%;
									float: right;
									.wpwl-wrapper-cvv {
										width: 100%;
									}
								}
								.wpwl-group-submit {
									clear: both;
									.wpwl-wrapper-submit {
										display: flex;
										justify-content: center;
									}
								}
								.wpwl-group,
								.wpwl-wrapper {
									width: 100% !important;
								}
								.wpwl-group-expiry,
								.wpwl-group-cvv {
									width: 45% !important;
								}
								.wpwl-wrapper input,
								.wpwl-control-iframe {
									border: 1px solid #8a96a3;
									border-radius: 3px;
									background-color: transparent;
									font-size: 17px;
									padding: 12px 12px 8px 12px;
									height: 45px;
								}
								.wpwl-group-brand,
								.wpwl-label {
									display: none !important;
								}
								.wpwl-control-cardHolder,
								.wpwl-control-expiry {
									padding-left: 6px;
								}
								.wpwl-group-cardHolder,
								.wpwl-group-expiry {
									margin-bottom: 0;
								}
								.wpwl-button {
									display: flex;
									align-items: center;
								}
							}
						}
						.payment-form iframe {
							width: 100%;
						}
						#modal-payment-forms-amountinfo {
							font-size: 16px;
							margin-bottom: 30px;
							#modal-payment-forms-amountinfo-description {
								font-size: 12px;
								display: block;
								text-transform: uppercase;
								font-weight: 600;
								color: #8a96a3;
							}
							#modal-payment-forms-amountinfo-amount {
								border-bottom: 1px solid $color-grey1;
								padding-bottom: 10px;
								margin-bottom: 10px;
								display: flex;
								justify-content: space-between;
								align-items: center;
								#modal-payment-forms-amountinfo-coins {
									font-size: 24px;
									color: $color-dark;
									float: left;
									font-weight: 600;
								}
								#modal-payment-forms-amountinfo-euros {
									float: right;
									font-size: 24px;
									color: #8a96a3;
								}
							}
							#modal-payment-forms-amountinfo-boni {
								display: none;
								.modal-payment-forms-amountinfo-bonus {
									color: #8a96a3;
									.icon {
										color: #6dd5d7;
										margin-right: 5px;
									}
								}
							}
						}
					}

					div.form {
						width: 330px;
						@media screen and (max-width: 425px) {
							box-sizing: border-box;
							width: 100%;
						}
					}
					#modal-payment-selectoption {
						width: 100%;
						#payment-saved-methods {
							span {
								margin-bottom: 10px;
								display: block;
								&.bonus-payment {
									margin-bottom: 0;
								}
							}
						}
						.payment-option {
							cursor: pointer;
							padding: 8px 12px 8px 12px;
							font-size: 16px;
							background-image: linear-gradient(180deg, #8a96a30d 0%, #8a96a326 100%);
							margin-bottom: 8px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							border-radius: 3px;
							img {
								float: right;
								width: 75px;
							}
							&.inactive {
								opacity: 0.5;
							}
							&.saved {
								display: flex;
								justify-content: space-between;
								img {
									margin-top: 0px;
									float: none;
								}
							}
						}
					}
					#modal-payment-continue {
						display: none;

						#modal-payment-loadformbtn {
							background-image: linear-gradient(79deg, #ff617c, #ff6f61);
							margin: 24px auto 0 auto;
							display: block;
							border-radius: 99px;
							padding: 10px 48px;
							text-transform: uppercase;
							font-size: 14px;
							min-width: 200px;
							margin-bottom: 10px;
						}
					}
					#modal-payment-selectamount {
						width: 100%;
						display: none;
						.payment-amount {
							cursor: pointer;
							display: flex;
							border: 1px solid $color-grey1;
							border-radius: 3px;
							background-color: $color-white;
							justify-content: space-between;
							margin-bottom: 8px;
							align-items: center;
							.radiobtn-styled,
							.payment-amount-coins,
							.payment-amount-euros {
								margin-top: 8px;
								margin-bottom: 8px;
							}
							.payment-amount-euros {
								margin-right: 12px;
								text-align: right;
							}
							.radiobtn-styled {
								flex-grow: 0;
								margin-left: 12px;
							}
							label {
								display: flex;
								flex-grow: 1;
								justify-content: space-between;
								margin-top: 0;
								align-items: center;
								cursor: pointer;
								.payment-amount-bonus {
									background-color: #6dd5d7;
									color: white;
									font-size: 12px;
									padding: 6px 14px 6px 14px;
									display: flex;
									flex-direction: column;
									justify-content: center;
									align-items: center;
									font-weight: 600;
									.payment-amount-bonus-amount {
										font-size: 14px;
										line-height: 1;
										font-weight: 600;
									}
									span {
										line-height: 1;
									}
									&[data-bonustype="videogift"] {
										.payment-amount-bonus-amount {
											font-size: 13px;
										}
									}
								}
								div {
									font-size: 14px;
									font-weight: 400;
								}
							}
						}
					}
				}
				#modal-payment-forms-backbtn {
					position: absolute;
					top: 20px;
					left: 20px;
					font-size: 18px;
					cursor: pointer;
				}
				#modal-payment-submitbtn {
					display: none;
				}
			}
			.lpmodal-footer {
				display: flex;
				background-image: linear-gradient(0deg, #f1f1f1, rgb(241 241 241 / 0%));
				padding: 200px 30px 30px 30px;
				font-size: 14px;
				flex-direction: column;
				text-align: center;
				position: absolute;
				width: 400px;
				a {
					text-decoration: underline;
				}
				#modal-payment-footertext {
					margin-top: 15px;
					line-height: 1.25;
					span {
						font-size: 12px;
					}
				}
				#modal-payment-trustelements {
					display: flex;
					font-weight: 600;
					justify-content: center;
					.lpmodal-payment-trustelement {
						display: flex;
						flex-direction: column;
						text-align: center;
					}
					.icon {
						display: block;
						font-size: 24px;
						margin-bottom: 5px;
					}
				}
			}
			#payment-verifier-code {
				display: flex;
				flex-direction: column;
				align-items: center;
				input[type="text"] {
					width: 80px;
				}
			}
			input[type="text"] {
				display: block;
				box-shadow: none;
				background-color: transparent;
				border: 1px solid #ebebeb;
				border-radius: 5px;
				font-family: "Poppins", sans-serif;
				font-weight: 400;
				box-sizing: border-box;
				padding: 12px;
				font-size: 14px;
				color: #0A0A0A;
				margin-top: 10px;
			}
		}
	}
	&[data-dialogtype="subscription"] {
		#modal-payment-selectamount {
			display: none !important;
		}
		.payment-option.new {
			&[data-paymentoption="su"],
			&[data-paymentoption="psc"] {
				display: none !important;
			}
		}
	}
}
#modal-payment-done,
#modal-payment-error {
	.modal-box {
		width: 970px;
		height: 800px;
		overflow: hidden;

		.modal-body {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: $color-white;
			font-size: 17px;
			#modal-payment-done-backlink {
				a {
					color: $color-white;
				}
			}
			* {
				z-index: 2;
			}
			.icon-streamline-icon-check-118x18 {
				font-size: 70px;
			}
			.icon-LPCMS_back {
				margin-right: 10px;
			}

			#modal-payment-done-maintext {
				font-size: 26px;
				font-weight: 600;
			}
			#modal-payment-done-subtext {
				padding-bottom: 26px;
				border-bottom: 1px solid $color-green;
				margin-bottom: 29px;
				width: 630px;
				font-weight: 600;
				text-align: center;
			}
			.close-modal {
				position: relative;
				top: 0;
				right: 0;
			}
			.bgimg {
				z-index: 1;
				width: 110%;
				height: 110%;
				position: absolute;
				top: 0;
				left: 0;
				background-image: url(#{$cdn}/images/static/leonie_beach.jpg);
				background-size: 970px auto;
				background-position: -15px -60px;
				margin: -5px -10px -10px -5px;
				background-repeat: no-repeat;
				flex-direction: column;
				filter: brightness(70%) blur(4px);
			}
		}
	}
}
#modal-payment-error {
	.modal-box {
		.modal-body {
			#modal-payment-error-mainicon {
				font-size: 70px;
				color: $color-red;
			}
			#modal-payment-error-maintext {
				color: $color-red;
				font-size: 26px;
				margin-top: 20px;
				margin-bottom: 20px;
				width: 330px;
				font-weight: 600;
				text-align: center;
			}
			#modal-payment-error-subtext {
				padding-bottom: 26px;
				border-bottom: 1px solid $color-red;
				margin-bottom: 29px;
				width: 630px;
				font-weight: 600;
				text-align: center;
			}
			.bgimg {
				background: black;
				filter: none;
				margin: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}
