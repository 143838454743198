#profile-settings {
	margin-top: 40px;
	margin-bottom: 40px;
	display: grid;
	grid-gap: 50px;
	grid-template-columns: 200px 1fr;
	@media screen and (min-width: 1105px) {
		max-width: 1100px;
	}
}

.profile-settings__navigation {
	display: flex;
	flex-direction: column;
	a {
		line-height: 44px;
		border-radius: 12px;
		margin-bottom: 4px;
		text-indent: 20px;
		transition: 0.7s ease-out;
		font-size: 14px;
		&.active {
			background: linear-gradient(299deg, rgba(235,235,235,1) 0%, rgba(249,249,249,1) 100%);
			font-weight: 600;
		}
		&:hover {
			background: linear-gradient(299deg, rgba(235,235,235,1) 0%, rgba(249,249,249,1) 100%);
			transition: 0.35s ease-in-out;
			text-indent: 30px;
		}
	}
}

.profile-settings__content {
	padding: 0 50px;
	border-left: 1px solid #EBEBEB;
	.profile-settings__section {
		margin-bottom: 80px;
	}
	h1 {
		font-size: 28px;
		margin-bottom: 40px;
	}
	h2 {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 4px;
	}
	.infotext {
		font-size: 12px;
		display: block;
		color: #545454;
		&.indented {
			margin-left: 27px;
		}
	}
	a {
		text-decoration: underline;
	}
	.inputbox {
		margin-top: 20px;
		display: block;
		input {
			display: block;
			box-shadow: none;
   		background-color: transparent;
    	border: 1px solid #ebebeb;
    	border-radius: 5px;
			font-family: "Poppins", sans-serif;
			font-weight: 400;
			box-sizing: border-box;
			width: 350px;
			&::placeholder {
				font-weight: 300;
			}
			&:disabled {
				background-color: #f9f9f9;
			}
		}
	}
	button, a.button-md {
		font-size: 14px;
		font-weight: 500;
		border-width: 1px;
		padding: 8px 16px;
		&:disabled {
			pointer-events: none;
		}
	}
	.payment-settings-headline {
		img {
			display: none;
		}
	}
	.saved-payments {
		display: flex;
    align-items: center;
		background-color: #f9f9f9;
    margin-bottom: 12px;
    border-radius: 12px;
    width: 100%;
		max-width: 500px;
		justify-content: space-between;
		&:first-of-type {
			margin-top: 16px;
		}
		> div {
			display: flex;
			align-items: center;
		}
		span {
			margin-left: 24px;
			padding: 12px 0;
		}
		img {
			height: 18px;
			margin-left: 24px;
		}
		a {
			font-size: 12px;
			margin: 0 12px;
			color: $color-primary;
			text-decoration: none;
			padding: 12px;
			border-radius: 12px;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.alert {
		background-color: #f9f9f9;
		padding: 20px 24px;
		font-size: 14px;
		border-radius: 12px;
		border: 0;
		color: #000000;
		font-weight: 300;
		line-height: 1.5;
		p {
			margin-top: 0;
			font-size: 14px;
		}
	}
	.alert.alert-success {
		background-color: #ebffe5;
	}
	.alert.alert-danger {
		background-color: #ffe5e5;
	}
	form {
		margin-top: 20px;
		input[type=checkbox] + label, input[type=radio] + label {
			display: flex;
			align-items: center;
			width: fit-content;
			cursor: pointer;
			position: relative;
		}
		input[type=checkbox] + label::before {
			margin: 0 8px 0 0;
			border: 1px solid $color-dark;
			padding: 1px 0px 1px 2px;
		}
		input[type=checkbox]:checked + label::before {
			border-color: $color-primary;
			background-color: $color-primary;
			color: $color-bright;
			font-size: 13px;
		}
		input[type=radio] {
			display: none;
		}
		input[type=radio] + label::before {
			width: 15px;
			height: 15px;
			border-radius: 99px;
			border: 1px solid $color-dark;
			content: "";
			margin-right: 8px;
			padding: 1px;
		}
		input[type=radio] + label::after {
			width: 13px;
			height: 13px;
			border-radius: 99px;
			background-color: $color-primary;
			content: "";
			position: absolute;
			left: 3px;
			transform: scale(0);
			transition: 200ms transform ease-in-out;
		}
		input[type=radio]:checked + label::before {
			border-color: $color-primary;
		}
		input[type=radio]:checked + label::after {
			transform: scale(1);
			transition: 200ms transform ease-in-out;
		}
		div {
			margin-top: 16px;
		}
	}
	.current-runtime {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		background-color: #f9f9f9;
    padding: 20px 24px;
    border-radius: 12px;
		max-width: 500px;
		box-sizing: border-box;
		div {
			display: flex;
			flex-direction: column;
   		justify-content: flex-start;
		}
		span {
			&:first-of-type {
				font-weight: 500;
				margin-bottom: 4px;
			}
		}
		a {
			font-size: 12px;
			color: $color-primary;
			text-decoration: none;
			display: block;
			margin-bottom: 8px;
		}
	}
	#new-runtime {
		display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
		max-width: 500px;
		div {
			margin-top: 0;
		}
		input[type=radio] + label {
			background-color: #f9f9f9;
			border: 2px solid #f9f9f9;
			padding: 18px 22px;
			width: 100%;
			box-sizing: border-box;
			border-radius: 12px;
			justify-content: space-between;
			.infotext {
				margin-left: 0;
			}
		}
		input[type=radio]:checked + label {
			border-color: $color-primary;
		}
		input[type=radio] + label::before, input[type=radio] + label::after {
			display: none;
		}
		span.runtime-active {
			position: absolute;
			left: 10px;
			top: -10px;
			background-color: #ffe2df;
			padding: 2px 8px;
			border-radius: 8px;
			font-size: 12px;
			letter-spacing: 1px;
			color: #FF6F61;
		}
	}
}

@media screen and (max-width: 820px) {
	#profile-settings {
		grid-gap: 30px;
	}
	.profile-settings__content {
		padding-left: 30px;
		padding-right: 10px;
		.inputbox input {
			width: 100%;
		}
		.saved-payments {
			max-width: unset;
			i {
				font-size: 50px;
			}
			span {
				font-size: 14px;
   			font-weight: 500;
			}
		}
	}
}

@media screen and (max-width: 750px) {
	#profile-settings {
		grid-template-columns: 1fr;
		.profile-settings__navigation {
			text-align: center;
			align-items: center;
			a {
				text-indent: 0;
				max-width: 400px;
    		width: 100%;
			}
		}
		.profile-settings__content {
			border-top: 1px solid #EBEBEB;
			border-left: none;
			padding-top: 50px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}
@media screen and (max-width: 650px) {
	.profile-settings__content {
		h1 {
			font-size: 22px;
		}
	}
}

@media screen and (max-width: 560px) {
	#profile-settings .profile-settings__content {
		padding-left: 0;
		padding-right: 0;
	}
}

@media screen and (max-width: 450px) {
	.profile-settings__content {
		.saved-payments {
			img {
				display: none;
			}
		}
		.payment-settings-headline {
			display: flex;
			align-items: center;
			margin-bottom: 4px;
			img {
				height: 16px;
				margin-right: 12px;
				display: block;
				margin-left: 4px;
			}
			h2 {
				margin-bottom: 0;
			}
		}
	}
}

@media screen and (max-width: 450px) {
	.profile-settings__content .current-runtime {
		flex-direction: column;
		div:last-of-type {
			flex-direction: row;
			margin-top: 24px;
			a {
				margin-bottom: 0;
				margin-right: 20px;
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.profile-settings__content {
		.saved-payments {
			span {
				margin-left: 12px;
				padding: 8px 0;
			}
			a {
				margin: 0;
			}
		}
	}
}