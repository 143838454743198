.staticpage {
	h3 {
		font-size: 14px;
		/*color: $color-coral;*/
		text-transform: uppercase;
		margin-top: 40px;
		margin-bottom: 5px;
	}
	h2 {
		font-weight: normal;
		margin-top: 10px;
		margin-bottom: 40px;
		font-size: 22px;
	}
	p {
		font-size: 17px;
		font-weight: 300;
		line-height: 24px;
	}
}
#whatsapp-page {
	margin-top: 40px;
	#whatsapp-page-header {
		h3 {color: $color-coral;}
		background-image: url(#{$cdn}/images/static/LPCMS_FullHeader_wa.jpg);
		height: 400px;
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		img {
			display: none;
		}
	}
	#whatsapp-page-content {
		display: flex;
		> div {
			width: 50%;
		}
		#whatsapp-page-content-left {
			margin-right: 20px;
		}
		#whatsapp-page-content-right {
			margin-left: 20px;
		}
	}
}
#aboutme-page {
	h2 {
		margin: 30px 0;
		color: $color-coral;
		font-weight: normal;
		text-align: center;
	}
	p {
		line-height: 1.8;
		max-width: 750px;
		margin: 35px auto 0 auto;
		a {
			color: $color-coral;
		}
	}
	#aboutme-page-header {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-top: 40px;
		#aboutme-page-header-left {
			line-height: 0px;
			img {
				width: 100%;
				height: 100%;
   			object-fit: cover;
			}
		}
		#aboutme-page-header-right {
			text-align: center;
			padding: 30px;
			line-height: 28px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			span {
				margin-bottom: 15px;
				color: $color-coral;
				font-size: 14px;
			}
			.big-headline {
				@media screen and (min-width: 1025px) {
					font-size: 38px;
				}
				@media screen and (max-width: 425px) {
					font-size: 24px;
				}
			}
			.goldline {
				margin: 14px auto 24px auto;
			}
			p {
				margin: 0 auto;
				font-weight: 500;
			}
		}
	}
	#aboutme-page-content {
		padding-top: 35px;
		margin: 35px 0 60px 0;
		border-top: 1px solid $color-grey1;
	}
	.aboutme-page-content-media {
		max-width: 1440px;
		margin-top: 50px;
    margin-bottom: 50px;
		display: grid;
		grid-template-columns: 3fr 1fr;
		img {
			width: 100%;
			cursor: pointer;
		}
		video {
			width: 100%;
			cursor: pointer;
		}
		&#aboutme-page-content-media-first {
			grid-template-columns: 1fr 3fr;
			.aboutme-page-content-media-hashtags {
				text-align: right;
				margin-left: 110px;
				margin-right: 40px;
				span {
					font-size: 16px;
					letter-spacing: 1px;
				}
				.goldline {
					margin-left: auto;
				}
			}
		}
		.aboutme-page-content-media-hashtags {
			width: 230px;
			min-width: 230px;
			font-size: 17px;
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
			margin-left: 40px;
			margin-right: 110px;
			flex-direction: column;
			line-height: 1.5em;
			.goldline {
				margin-bottom: 15px;
			}
		}
	}
	#aboutme-page-signup {
		border-top: 1px solid $color-coral;
		margin-left: auto;
		margin-right: auto;
		margin-top: 60px;
		margin-bottom: 35px;
		display: flex;
		padding-top: 15px;
		align-items: center;
		justify-content: space-around;
		color: $color-coral;
		max-width: 750px;
		span {
			font-family: "Leonie_Accent";
			font-size: 28px;
		}
		.button {
			text-transform: none;
			font-size: 16px;
			line-height: 1;
			padding: 16px 32px;
			margin-bottom: 0;
		}
	}
}
#welcome-page {
	margin-top: 40px;
	#welcome-page-header {
		background-image: url(#{$cdn}/images/static/LPCMS_FullHeader_forced.jpg);
		height: 400px;
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		img {
			display: none;
		}
	}
	#welcome-page-content {
		text-align: center;
		picture, img {
			max-width: 100%;
			cursor: pointer;
		}
		.button {
			padding: 15px 80px;
			display: inline-block;
			text-align: center;
			margin-top: 20px;
			margin-bottom: 50px;
		}
		h2 {margin-bottom:25px;}
		h3 {margin-top:15px;}
		> div {
			display: flex;
			justify-content: space-between;
			.button.green {
				padding: 15px 42px;
				margin-bottom: 40px;
			}
			#welcome-page-content-right {
				display: flex;
				flex-direction: column;
				text-transform: uppercase;
				align-items: center;
				justify-content: center;
				> div {
					text-align: center;
					padding-top: 28px;
					border-top: 1px solid $color-grey1;
				}
				.checkboxes {
					text-transform: none;
					margin-top: 30px;
				}
			}
			> div {
				width: 45%;
			}
		}
	}
}
#subscription-page {
	#subscription-page-header {
		margin-top: 36px;
		position: relative;
		display: none;
		.icon-LPCMS_FuerDichRotate {
			position: absolute;
			top: 20px;
			right: -40px;
			width: 82px;
			font-size: 82px;
		}
		#subscription-page-cta {
			font-size: 17px;
			color: $color-coral;
			position: absolute;
			text-align: center;
			width: 100%;
			bottom: 40px;
			font-weight: 600;
			display: block;
			cursor: pointer;
			-webkit-transition-duration: 0.3s;
			transition-duration: 0.3s;
			-webkit-transition-property: -webkit-transform;
			transition-property: transform;
			-webkit-transform: translateZ(0);
			-ms-transform: translateZ(0);
			transform: translateZ(0);
			-webkit-transform: translateY(-6px);
			-ms-transform: translateY(-6px);
			transform: translateY(-6px);
			-webkit-animation-name: hover;
			animation-name: hover;
			-webkit-animation-duration: 2s;
			animation-duration: 2s;
			-webkit-animation-delay: 0.3s;
			animation-delay: 0.3s;
			-webkit-animation-timing-function: linear;
			animation-timing-function: linear;
			-webkit-animation-iteration-count: infinite;
			animation-iteration-count: infinite;
			-webkit-animation-direction: alternate;
			animation-direction: alternate;
		}
	}
	#subscription-page-content {
		h2 {
			font-weight: normal;
		}
		.flex-vsplit {
			&:nth-child(1) {
				margin-top: 10px;
				h3 {
					margin-top: 40px;
					margin-bottom: 10px;
				}
				h2 {
					margin-bottom: 30px;
				}
			}
			&:nth-child(2) {
				border-top: 1px solid #f1f1f1;
				margin-top: 40px;
				padding-top: 30px;
			}
			img {
				width: 100%;
			}
		}
		#subscription-page-subscriptions-anchor {
			position: absolute;
			margin-top: -30px;
		}
		#subscription-page-subscriptions {
			margin-top: 30px;
			background-image: url(#{$cdn}/images/static/LPCMS-wapremium_sektion-1.jpg);
			background-size: cover;
			height: 810px;
			width: 100%;
			background-repeat: no-repeat;
			text-align: center;
			h2 {
				font-size: 30px;
				font-weight: 300;
				width: 580px;
				margin: 0 auto;
				margin-top: 10px;
				margin-bottom: 30px;
			}
			h3 {
				padding-top: 40px;
			}
			#subscription-page-subscriptions-list {
				display: flex;
				align-items: center;
				justify-content: center;
				.subscription {
					width: 280px;
					height: 490px;
					position: relative;
					background-color: $color-grey1;
					box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
					border-radius: 5px;
					text-align: center;
					.subscription-letter {
						color: $color-coral;
						font-size: 55px;
						font-weight: 600;
						margin-top: 30px;
						margin-bottom: 15px;
					}
					.subscription-title {
						font-weight: 600;
						margin-bottom: 115px;
					}
					.subscription-amount {
						margin-bottom: 35px;
					}
					.subscription-description {
						margin-bottom: 35px;
					}
					.button {
						letter-spacing: 5.71px;
						color: $color-white;
						background-color: $color-coral;
						border-radius: 5px;
						height: 42px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 30px;
						margin-left: 30px;
						padding: 0;
					}
					&.highlight {
						margin-left: 30px;
						margin-right: 30px;
						color: $color-white;
						background-color: $color-coral;
						height: 530px;
						&.off50 {
							&:before {
								background-image: url(#{$cdn}/images/static/lpcms_PremiumMail--50OFF.svg);
								content: " ";
							}
						}
						&:before {
							background-image: url(#{$cdn}/images/static/LPCMS-TagBadge-green.svg);
							width: 175px;
							height: 35px;
							position: absolute;
							z-index: 1;
							left: -26px;
							top: 6px;
							background-repeat: no-repeat;
							background-size: cover;
							content: "SEHR BELIEBT";
							font-size: 12px;
							letter-spacing: 4px;
							font-weight: 600;
							padding-top: 6px;
							padding-left: 18px;
							color: $color-dark;
						}
						.subscription-letter {
							color: $color-white;
							margin-top: 50px;
						}
						.button {
							background-color: $color-green;
						}
					}
				}
			}
		}
	}
	#subscription-page-footer {
		padding: 30px 15% 0px 15%;
		text-align: center;
		border-top: 1px solid #f1f1f1;
		margin-top: 40px;
	}
}
#legal {
	h1 {
		margin: 40px 0 20px 0;
	}
	h2 {
		font-size: 22px;
		margin: 30px 0 15px 0;
	}
	h3 {
		font-size: 18px;
		margin-bottom: 15px;
	}
	h4 {
		font-size: 14px;
		line-height: 21px;
	}
	p {
		font-size: 16px;
		line-height: 24px;
		text-align: justify;
		margin: 0 0 25px 0;
		font-weight: 300;
	}
	ul {
		margin: 0 50px 25px 50px;
	}
	li {
		margin-bottom: 15px;
		line-height: 24px;
	}
}

#telegram-page {
	h2 {
		font-size: 24px;
		font-weight: 600;
		line-height: 1.1em;
	}
	.offset-wrapper {
		padding: 0px 100px 40px 120px;
	}
	.button.lightblue {
		padding: 17px 65px;
		font-size: 18px;
		width: 245px;
		margin: 0 auto;
		display: block;
	}
	#telegram-page-header {
		h2 {
			width: 360px;
			margin-bottom: 20px;
		}
		background-image: url(#{$cdn}/images/static/telegram/lpcms-global-header-telegram.jpg);
		height: 520px;
		width: 2560px;
		margin-left: calc((1280px - 50%) * -1);
		background-position: center;
		background-repeat: no-repeat;
		img {
			display: none;
		}
	}
	#telegram-page-content {
		display: flex;
		> div {
			width: 50%;
		}
		#telegram-page-content-left {
			margin-right: 20px;
			margin-top: -80px;
			background-color: white;
			padding: 20px 50px;
		}
		#telegram-page-content-right {
			margin-left: 20px;
			margin-top: 40px;
			#telegram-selfies {
				div {
					img {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
	#telegram-page-bottom {
		padding-top: 40px;
		margin-top: 60px;
		padding-right: 10px;
		display: flex;
		justify-content: flex-end;
		background-image: url(#{$cdn}/images/static/telegram/lpcms-telegramfreeV2-section-1.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		.button.lightblue {
			margin-top: 20px;
			margin-bottom: 20px;
		}
		#telegram-page-accordion {
			h2 {
				margin-bottom: 20px;
				font-weight: 600;
			}
			background-color: #F5F6FA;
			width: 500px;
			padding: 20px 15px 0px 15px;
		}
	}
	#telegram-page-premiumbanner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 40px 30px 0px 10px;
		h2 {
			margin-bottom: 20px;
		}
		div {
			width: 48%;
			text-align: center;
			img {
				width: 100%;
			}
			.tg-premium {
				display: inline-block;
			}
			.button {
				background-color: #E22F46;
				font-size: 18px;
				padding: 17px 86px;
				text-transform: uppercase;
				margin-top: 40px;
				display: inline-block;
			}
		}
	}
}

/* AVS Seite Start */
.agecheck {
	margin-bottom: 80px;
}

.agecheck__image {
	background-image: url(#{$cdn}/images/static/members/LPCMS-1440x630_avs--D.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin: 0 auto;
	height: 630px;
	min-height: 390px;
	max-height: 55vh;
}

.agecheck__image h1 {
	font-size: 65px;
	color: #ffffff;
	text-shadow: 0px 2px 2px #0000004D;
	max-width: 55%;
	padding: 60px 0 0 80px;
	font-weight: 400;
	line-height: 0.9;
}

.agecheck__text {
	max-width: 1024px;
	margin: 50px auto 30px auto;
}

.agecheck__text p {
	font-weight: 300;
	font-size: 16px;
	line-height: 1.3;
	text-align: center;
	margin-bottom: 30px;
}

.agecheck__button {
	display: grid;
	justify-content: center;
	grid-column-gap: 20px;
	grid-auto-flow: column;
}

.agecheck__button a {
	padding: 15px 70px;
	background-image: $gradient-coral;
	color: $color-white;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.agecheck__button i {
	margin-right: 20px;
	font-size: 25px;
}

.agecheck__button span {
	font-size: 18px;
	letter-spacing: 0.54px;
}

@media screen and (max-width: 1024px) {
	.agecheck__image {
		margin-left: -20px;
		margin-right: -20px;
	}

	.agecheck__image h1 {
		max-width: 65%;
		padding: 40px 0 0 40px;
	}
}

@media screen and (max-width: 768px) {
	.agecheck__image {
		height: 350px;
		min-height: 250px;
	}

	.agecheck__text {
		margin-top: 20px;
	}

	.agecheck__text p {
		text-align: left;
	}

	.agecheck__image h1 {
		font-size: 45px;
	}
}

@media screen and (max-width: 425px) {
	.agecheck__image {
		height: 250px;
	}

	.agecheck__image h1 {
		font-size: 26px;
		line-height: 1.1;
		max-width: 75%;
		padding: 20px 20px 0 20px;
	}

	.agecheck__button {
		display: block;
	}

	.agecheck__button a {
		padding: 15px;
	}
}

@media screen and (max-height: 900px) and (min-width: 1025px) {
	.agecheck__content {
		display: grid;
		grid-column-gap: 80px;
		grid-template-columns: minmax(200px, 720px) 350px;
		justify-content: center;
	}

	.agecheck__text {
		margin: 50px 0 0 0;
	}

	.agecheck__text p {
		text-align: left;
	}

	.agecheck__text p:last-of-type {
		margin-bottom: 0px;
	}

	.agecheck__button {
		max-width: 350px;
		justify-content: unset;
		margin-top: 50px;
	}

	.agecheck__button a {
		width: 100%;
		height: 55px;
		box-sizing: border-box;
	}
}
/* AVS Seite Ende */

/* Landingpage Start */

#landingpage-new {
	img, video {
		display: block;
		max-width: 100%;
	}
	.fullwidth {
		max-width: 2560px;
		margin: 0 auto;
	}
	.heroimage {
		height: 450px;
		background-image: linear-gradient(90deg, rgba(10,10,10,0.7) 0%, rgba(10,10,10,0) 50%, rgba(10,10,10,0.7) 100%), url(#{$cdn}/images/static/bg-thumbs.jpg);
		background-position: center;
		.hero__content {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}
		.hero__image {
			height: 95%;
			align-self: flex-end;
		}
		.hero__text {
			max-width: 50%;
			color: $color-bright;
			text-align: center;
			.big-headline {
				margin: 0;
				display: block;
				text-transform: none;
			}
			span {
				text-transform: uppercase;
			}
			.button-md {
				margin-left: auto;
				margin-right: auto;
			}
		}
		img {
			height: 100%;
			object-fit: cover;
		}
	}
	.advent.heroimage {
		background-image: radial-gradient(circle, rgb(226, 58, 60) 20%, rgb(184 19 33 / 0%) 100%), url(/images/static/snow.png);
    background-color: #b81321;
		@media screen and (max-width: 480px) {
			min-height: 80vh;
		}
		.hero__text {
			@media screen and (min-width: 1025px) {
				.big-headline {
					font-size: 58px;
				}
			}
		}
		.hero__image {
			@media screen and (max-width: 480px) {
				img {
					right: -42px;
				}
			}
		}
		.button-md {
			background-color: $color-bright;
			color: $color-dark;
			border: 2px solid $color-bright;
		}

	}
	.welcome {
		padding-top: 120px;
		padding-bottom: 120px;
		display: grid;
		grid-template-columns: 1.4fr 1fr;
		grid-gap: 20px;
		align-items: center;
		&__text {
			padding-right: 80px;
			position: relative;
			img {
				position: absolute;
				top: -72px;
				left: -108px;
				transform: rotate(-20deg);
			}
		}
		&__image {
			display: grid;
			grid-template-columns: 1.2fr 1fr;
			align-items: center;
			grid-gap: 20px;
			img, video {
				width: 100%;
				object-fit: cover;
				border-radius: 8px;
 			  box-shadow: 0 4px 16px rgb(10 10 10 / 20%);
			}
		}
		h1 {
			margin-top: 0;
		}
	}
	.video__grid {
		img {
			height: 100%;
			object-fit: cover;
		}
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		grid-gap: 20px;
		.grid__item {
			&-1, &-3 {
				grid-row: 1 /span 2;
			}
			&-2 {
				grid-column: 2 /span 2;
				grid-row: 1 /span 1;
			}
		}
	}
	.video__grid-infos {
		padding-top: 120px;
		padding-bottom: 120px;
		text-align: center;
		.grid__infos-top {
			margin: 0 auto 60px auto;
			width: 60%;
		}
		.grid__infos-bottom {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 30px;
		}
	}
	.lvrs__section {
		height: 600px;
		background-color: lightseagreen;
	}
	.mailer__section {
		padding-bottom: 120px;
		padding-top: 120px;
		display: grid;
		grid-gap: 80px;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		img {
			max-height: 380px;
			object-fit: cover;
			object-position: top;
			width: 100%;
			border-radius: 8px;
 			box-shadow: 0 4px 16px rgb(10 10 10 / 20%);
		}
	}
	.gallery__section {
		background-color: #1b1b1b;
		overflow: hidden;
		h1 {
			margin-top: 0;
		}
		.content-limited {
			display: grid;
			grid-gap: 80px;
			grid-template-columns: 1fr 1fr;
			align-items: center;
			color: $color-bright;
			position: relative;
		}
		&-text {
			padding: 120px 0;
		}
		&-images {
			align-self: end;
		}
		.gallery__image-grid {
			display: grid;
    	grid-template-columns: 1fr 1fr 1fr;
			top: 155px;
			grid-gap: 5px;
			align-self: end;
			position: absolute;
			right: 80px;
			width: calc(50% - 120px);
			img {
				&:nth-child(3n+2) {
					margin-top: -55px;
				}
			}
			div {
				display: grid;
				grid-gap: 10px;
			}
		}
	}
	.new__videos {
		padding-top: 120px;
		padding-bottom: 120px;
		button {
			margin: 0 auto;
		}
	}
	.welcome__video {
		background-color: #28323E;
		.content-limited {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 80px;
			color: white;
			align-items: center;
			video {
				max-height: 450px;
				object-fit: cover;
				border: 15px solid white;
				justify-self: center;
				margin: 40px 0;
				box-sizing: border-box;
			}
		}
	}
	.gift__section {
		div {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;
			padding: 120px 0;
		}
		.gift__section-text {
			text-align: center;
			font-size: 36px;
			margin-bottom: 30px;
			max-width: 780px;
		}
		a {
			margin-top: 0;
		}
	}
	.lovers__section {
		background-color: $color-grey1;
		.content-limited {
			padding-bottom: 120px;
			padding-top: 120px;
			display: flex;
			gap: 80px;
		}
		.big-headline {
			margin-top: 0;
		}
		.lovers__section-images {
			width: 50%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			.mid, .left, .right {
				aspect-ratio: 4 / 6;
				border-radius: 8px;
				object-fit: cover;
				top: 50%;
				box-shadow: 0 4px 16px rgba(10, 10, 10, 0.2);
			}
			.mid {
				max-width: 50%;
				z-index: 1;
			}
			.left {
				left: 0;
				transform: translate(0, -50%);
				max-width: 36.25%;
				position: absolute;
			}
			.right {
				right: 0;
				transform: translate(0, -50%);
				max-width: 36.25%;
				position: absolute;
			}
		}
		.lovers__section-text {
			width: 50%;
		}
		.lovers__section-pros {
			display: grid;
			grid-template-columns: 1fr 1fr;
			margin-top: 24px;
			grid-gap: 12px;
			.lovers__section-pro {
				display: flex;
				align-items: flex-start;
				font-weight: 500;
				.material-symbols-rounded {
					padding: 0;
					margin-right: 12px;
				}
			}
		}
	}
	@media screen and (max-width: 1104px) {
		.gallery__section .gallery__image-grid {
			right: 0;
			width: calc(50% - 40px);
		}
	}
	@media screen and (max-width: $break-desktop) {
		.welcome {
			padding: 60px 40px;
			grid-template-columns: 2fr 1fr;
			&__text {
				padding-right: 40px;
			}
			&__image {
				grid-template-columns: 1fr;
				img:last-of-type {
					display: none;
				}
				video, img {
					max-height: 400px;
				}
			}
		}
		.gallery__section {
			&-text {
				padding: 60px 0;
			}
			.gallery__image-grid {
				width: calc(50% - 20px);
			}
		}
		.mailer__section {
			grid-gap: 40px;
			padding: 60px 40px;
		}
		.welcome__video, .gallery__section, .lovers__section {
			padding: 0 40px;
			.content-limited {
				grid-gap: 40px;
			}
		}
		.fullwidth .content-limited {
			padding: 0;
		}
		.new__videos {
			padding-top: 60px;
			padding-bottom: 60px;
		}
		.gift__section {
			.content-limited {
				padding: 60px 0;
			}
			.gift__section-text {
				font-size: 24px;
				margin-bottom: 24px;
				max-width: 570px;
			}
		}
		.lovers__section {
			.content-limited {
				padding: 60px 0;
			}
			.lovers__section-pros {
				grid-template-columns: 1fr;
			}
		}
	}
	@media screen and (max-width: $break-ipad) {
		.heroimage {
			position: relative;
			overflow: hidden;
			.hero__image {
				img {
					position: absolute;
					bottom: 0;
					right: -50px;
					height: 95%;
				}
			}
			.hero__content {
				justify-content: space-around;
			}
			.hero__text {
				max-width: 60%;
				z-index: 1;
			}
		}
		.welcome {
			grid-template-columns: 1fr;
			grid-gap: 40px;
		}
		.welcome__image {
			grid-template-columns: 1fr 1fr;
			img:last-of-type {
				display: block;
			}
		}
		.welcome__text {
			padding-right: 0;
		}
		.mailer__section {
			grid-template-columns: 1fr;
			img {
				max-height: 240px;
			}
		}
		.gallery__section {
			.content-limited {
				grid-template-columns: 1fr;
			}
			&-text {
				padding: 60px 0 0 0;
			}
			.gallery__image-grid {
				position: static;
				width: 100%;
				grid-template-columns: 1fr 1fr 1fr 1fr;
				margin-top: 55px;
				max-height: 35vw;
				img:nth-child(3n+2) {
					margin-top: 0;
				}
				img:nth-child(even) {
					margin-top: -55px;
				}
				img:last-child {
					display: none;
				}
			}
		}
		.gift__section {
			.gift__section-text {
				font-size: 22px;
				max-width: 500px;
			}
		}
		.lovers__section {
			.content-limited {
				flex-direction: column-reverse;
				.lovers__section-images, .lovers__section-text {
					width: 100%;
				}
			}
		}
	}
	@media screen and (max-width: $break-phone) {
		.hero__content.content-limited.px1200 {
			padding: 0;
		}
		.heroimage {
			min-height: 84vh;
			.hero__content {
				justify-content: center;
				align-items: flex-end;
			}
			.hero__text {
				position: absolute;
				bottom: 0;
				max-width: 100%;
				box-sizing: border-box;
				text-align: center;
				padding: 0 20px;
				background-image: linear-gradient(180deg, rgba(10,10,10,0) 15%, rgba(10,10,10,0.6) 100%);
				span {
					margin-top: 200px;
   				display: block;
				}
				.big-headline {
					margin-top: 4px;
				}
				.button-md {
					margin-top: 26px;
					margin-bottom: 40px;
				}
			}
			.hero__image {
				display: flex;
    		align-items: flex-end;
				img {
					height: 100%;
					right: -70px;
					max-width: unset;
				}
			}
		}
		.welcome {
			padding: 60px 20px;
			&__image {
				grid-gap: 8px;
				img, video {
					max-height: 280px;
				}
			}
		}
		.mailer__section, .gallery__section, .welcome__video, .gift__section {
			padding: 60px 20px;
		}
		.mailer__section {
			img {
				max-height: 180px;
			}
		}
		.gallery__section {
			.gallery__section-text {
				padding: 0;
			}
			.gallery__image-grid {
				grid-template-columns: 1fr 1fr 1fr;
				max-height: 40vw;
				img:nth-child(even) {
					margin-top: 0;
				}
				img:nth-child(3n+2) {
					margin-top: -45px;
				}
			}
		}
		.lovers__section {
			padding: 0 20px;
			.lovers__section-pros {
				grid-gap: 8px;
			}
			.lovers__section-pro {
				font-size: 14px;
			}
		}
		.gift__section {
			.content-limited {
				padding: 0;
			}
			.gift__section-text {
				font-size: 18px;
			}
		}
	}
}

.premium-slider {
	margin-bottom: 100px;
	max-height: 400px;
	display: flex;
	video {
		max-height: 400px;
		width: 50%;
		object-fit: cover;
	}
	img {
		max-height: 200px;
	}
	&-images {
		width: 50%;
		display: grid;
		grid-gap: 4px;
		grid-template-columns: 1fr 1fr;
		img {
			object-fit: cover;
			width: 100%;
		}
	}
	@media screen and (max-width: 1024px) {
		video {
			width: 66.666%;
		}
		img:nth-child(n+3) {
			display: none;
		}
		&-images {
			width: 33.334%;
			grid-template-columns: 1fr;
		}
	}
}

body.agecheck-modal-active {
	overflow: hidden !important;
	width: 100vw !important;
	height: 100vh !important;
}
.precheck__age-background {
	background-image: linear-gradient(6deg, rgba(255, 255, 255, 0.7) 35%, rgba(255, 255, 255, 0.7) 100%), url(/images/static/bg-thumbs.jpg);
	background-size: cover;
	height: 100vh;
	width: 100vw;
	position: relative;
	display: inline-block;
	padding: 40px 20px;
	box-sizing: border-box;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 9999;
}
.precheck__age {
	max-width: 768px;
	width: 100%;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 12px;
	position: absolute;
	top: 35%;
	left: 50%;
	transform: translate(-50%, -35%);
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
	padding: 80px;
	text-align: center;
	span {
		font-size: 42px;
	}
	p {
		font-size: 18px;
	}
	button {
		font-size: 18px;
		margin-top: 36px;
		transition: 0.4s ease-in-out;
		margin: 40px auto 0 auto;
		&:hover {
			background-color: transparent;
			color: #FF6F61;
			transition: 0.4s ease-in-out;
		}
	}
	i {
		font-size: 40px;
		margin-bottom: 48px;
		display: block;
	}
	@media screen and (max-width: 1024px) {
		margin: 0 auto;
		position: unset;
		transform: unset;
		padding: 40px;
		span {
			font-size: 28px;
		}
		p {
			font-size: 16px;
		}
		button {
			font-size: 16px;
			line-height: 1.3;
		}
	}
}