input.searchbox {
	border: 1px solid #8A96A3;
	border-radius: 3px;
	padding: 0px 10px;
	font-size: 16px;
	font-family: "Poppins", sans-serif;
	font-weight: 300;
}
input[type="checkbox"] {
	display: none;
}
input[type="checkbox"] + label {
	cursor: pointer;
}
input[type="checkbox"] + label::before {
	border: 2px solid $color-dark;
	border-radius: 3px;
	width: 15px;
	height: 15px;
	background-color: $color-white;
	display: block;
	content: "";
	float: left;
	margin-left: 1px;
	line-height: 16px;
	margin-top: -4px;
}
input[type="checkbox"]:checked+label::before {
	font-family: 'puricons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: "\e937";
	font-size: 14px;
}
.radiobtn-styled {
	border: 1px solid $color-dark;
	border-radius: 50%;
	padding: 5px;
	width: 4px;
	height: 4px;
	background-color: $color-white;
	display: block;
	content: "";
	float: left;
	margin-left: 1px;
	line-height: 16px;
	margin-top: -2px;
	margin-right: 11px;

	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + div {
		display: none;
	}
	input[type="radio"]:checked + div {
		display: flex;
		border-radius: 50%;
		background-color: $color-dark;
		height: 8px;
		width: 8px;
		margin-left: -2px;
		margin-top: -2px;
	}
}

.inputbox-multi {
	.inputbox {
		input {
			margin-bottom: 0;
			box-shadow: none;
			background-color: transparent;
			width: 100%;
			box-sizing: border-box;
		}
	}
}
.inputbox {
	position: relative;
	input, select {
		padding: 12px;
		box-shadow: $color-grey-15 0px 1px 5px;
		background-color: $color-grey1;
		border-radius: 3px;
		border: 0;
		margin-bottom: 20px;
		width: 330px;
		font-size: 14px;
		color: $color-dark;
		/*border: 0;
		background: transparent;
		width: 100%;
		border-bottom: 1px solid $color-grey-15;*/
	}
	&.is-invalid {
		&:after {
			color: $color-red;
			content: "\e939";
			font-family: 'puricons' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 14px;
			position: absolute;
			right: 15px;
    	top: 15px;
		}
	}
	&.is-valid {
		&:after {
			color: $color-green;
			content: "\e938";
			font-family: 'puricons' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 14px;
			position: absolute;
			right: 15px;
    		top: 15px;
		}
	}
}
.inputbox-invalid-feedback {
	font-weight: 600;
	width: 100%;
	display: inline-block;
	margin-bottom: 12px;
	color: $color-red;
	font-weight: 400;
	a {
		color: $color-coral;
		display: inline-block;
	}
}
.button {
	cursor: pointer;
	font-size: 14px;
	padding: 8px 48px;
	color: #FFFFFF;
	background-image: $gradient-coral;
	border: 0;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	border-radius: 99px;
	&.green {
		padding: 10px 42px;
		white-space: nowrap;
		background-color: $color-green;
		font-size: 17px;
		margin-bottom: -8px;
		text-transform: uppercase;
	}
}
button {
	font-family: "Poppins", sans-serif;
	cursor: pointer;
}

.button-md {
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	margin-top: 24px;
	padding: 12px 24px;
	border-radius: 99px;
	color: $color-bright;
	background-color: $color-primary;
	border: 2px solid $color-primary;
	width: fit-content;
	display: block;
	@media screen and (max-width: $break-phone) {
		font-size: 14px;
		padding: 10px 20px;
	}
	&.ghost {
		background-color: transparent;
		text-decoration: none;
		&.dark {
			color: $color-dark;
			border-color: $color-dark;
			transition: 0.4s ease-in-out;
			&:hover {
				background-color: $color-dark;
				color: $color-bright;
				transition: 0.4s ease-in-out;
			}
		}
		&.bright {
			color: $color-bright;
			border-color: $color-bright;
		}
		a {
			color: inherit;
		}
	}
}

.phoneinput {
	display: flex;
	.inputbox {
		width: auto;
		margin-top: 5px;
		select, input {
			margin-bottom: 0;
		}
		&:nth-child(1) {
			&:before {
				width: 115px;
				margin-left: 14px;
			}
			select {
				width: 142px;
			}
		}
		&:nth-child(2) {
			margin-left: 7px;
			&:before {
				width: 246px;
				margin-left: 14px;
			}
			input {
				width: 230px;
			}
		}
	}
}
@media screen and (max-width: $break-desktop) {
	.phoneinput {
		.inputbox {
			&:nth-child(2) {
				&:before {
					width: 170px;
				}
				input {
					width: 150px;
				}
			}
		}
	}
}