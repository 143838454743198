#sitefooter {
	.inner {
		width: 100%;
		padding: 0;
	}
	padding: 0px 20px;
	#sitefooter-bottom {
		font-size: 12px;
	}
	#sitefooter-main {
		flex-direction: column;
		padding-top: 40px;
		border-bottom: 1px solid #aaa;
		> div {
			margin-bottom: 20px;
		}
		#sitefooter-pagelinks {
			display: none;
		}
		.icon {
			font-size: 24px;
		}
		#sitefooter-socialmedia {
			a {
				margin-right: 23px;
			}
			.icon {
				font-size: 20px;
			}
		}
		#sitefooter-misclinks a {
			text-decoration: underline;
		}
	}
}
