.content-limited {
	max-width: 1200px;
	min-width: 880px;
	padding: 0 80px;
	margin: 0 auto;
	@media screen and (max-width: 1104px) {
		padding: 0;
		max-width: 984px;
	}
	&.px1200 {
		max-width: 1200px;
		@media screen and (max-width: 1104px) {
			padding: 0;
			max-width: 984px;
		}
	}
}
#siteheader-mobile, #mobile-mainmenu {
	display: none;
}
h1 {
	font-size: 22px;
	font-weight: 500;
}
.dropdown {
	border: 1px solid $color-grey-15;
	border-radius: 99px;
	padding: 8px 16px;
	font-size: 14px;
	display: flex;
  align-items: center;
	.icon {
		font-size: 6px;
		margin-left: 8px;
		cursor: pointer;
	}
	&.toggletrigger .icon {
		transition: 500ms ease-in-out;
	}
	&.toggletrigger.active .icon {
		transform: rotate(180deg);
    transition: 500ms ease-in-out;
	}
}
.checkboxes {
	display: flex;
	justify-content: space-around;
	div {
		font-weight: 600;
		white-space: nowrap;
		.icon {
			color: $color-coral;
			margin-right: 15px;
			margin-left: 30px;
		}
	}
}
.whatsapp-logo {
	position: relative;
	color: #25D366;
	font-size: 45px;
	font-family: "Leonie_Accent";
	line-height: 70px;
	.icon {
		position: absolute;
		top: 11px;
		margin-left: 6px;
	}
	.whatsapp-logo-text2 {
		margin-left: 65px;
		margin-top: -25px;
		margin-bottom: 10px;
	}
}
.offset-wrapper {
	padding: 0px 120px 40px 120px;
	h3 {
		color: $color-coral;
	}
}
#pw-reset {
	margin-top: 48px;
	margin-bottom: 144px;
	max-width: 650px;
	min-width: unset;
	text-align: center;
	@media screen and (max-width: 425px) {
		text-align: left;
		margin-top: 20px;
	}
	h1 {
		margin-bottom: 24px;
		font-size: 26px;
		font-weight: 400;
	}
	p {
    font-weight: 300;
    line-height: 24px;
		margin-bottom: 32px;
	}
	.alert {
		margin: 0 auto 20px auto;
    max-width: 350px;
    box-sizing: border-box;
	}
	form {
		display: flex;
		flex-direction: column;
		max-width: 350px;
		margin: 0 auto;
		input {
			font-size: 14px;
			color: #101014;
			font-weight: 300;
			box-sizing: border-box;
			width: 100%;
			padding: 12px;
			font-family: "Poppins", sans-serif;
			border-radius: 3px;
			border: 1px solid #8A96A3;
			margin-bottom: 12px;
		}
		button, .button {
			margin: 0 auto;
			font-size: 16px;
			padding: 12px 32px;
			margin-top: 12px
		}
	}
}

#support-page {
	margin-bottom: 144px;
	margin-top: 20px;
	h1 {
		margin-bottom: 24px;
		font-size: 26px;
		font-weight: 400;
	}
	p {
    font-weight: 300;
    line-height: 24px;
		margin-bottom: 32px;
	}
	.alter {
		max-width: 1024px;
		margin: 0;
	}
	form {
		max-width: 1024px;
	}
	textarea {
		font-size: 14px;
		color: #101014;
		font-weight: 300;
		box-sizing: border-box;
		width: 100%;
		padding: 12px;
		font-family: "Poppins", sans-serif;
		border-radius: 3px;
		border: 1px solid #8A96A3;
		margin-bottom: 12px;
		resize: vertical;
	}
	button, .button {
		font-size: 16px;
		padding: 12px 32px;
		margin-top: 12px;
		display: block;
		min-width: 200px;
		@media screen and (max-width: 1024px) {
			margin: 0 auto;
		}
	}
}

#newsletter-canceled {
	text-align: center;
	margin-top: 60px;
	margin-bottom: 90px;
	h1 {
		font-size: 36px;
		font-weight: 700;
		max-width: 700px;
		margin: 0 auto 48px auto;
	}
	.newsletter-vorteile {
		margin-bottom: 60px;
		display: inline-block;
		p {
			text-align: left;
			margin-bottom: 20px;
			font-size: 17px;
			font-weight: 600;
		}
		div {
			display: flex;
			align-items: baseline;
			margin-bottom: 12px;
			font-size: 17px;
			font-weight: 300;
		}
		.icon {
			color: #F44646;
			margin-right: 10px;
		}
	}
	h3 {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 20px;
	}
	a {
		display: block;
		font-size: 14px;
		color: #8A96A3;
	}
	a.button {
		display: inline-block;
		text-transform: uppercase;
		padding: 12px 48px;
		font-size: 18px;
		margin-bottom: 20px;
		color: $color-white;
	}
	@media screen and (max-width: 768px) {
		h1 {
			font-size: 32px;
		}
	}
	@media screen and (max-width: 425px) {
		h1 {
			font-size: 22px;
		}
		.newsletter-vorteile {
			p, div {
				font-size: 16px;
			}
		}
		h3 {
			font-size: 20px;
		}
		a.button {
			font-size: 16px;
		}
	}
}

@import "inputs_desktop";
@import "siteheader_desktop";
@import "staticpages_desktop";
@import "videopage_desktop";
@import "gallery_desktop";
@import "fanclub";
@import "bonuspage";
@import "profile";
@import "videojs";
@import "loggedin_desktop";
@import "loggedout_desktop";
@import "sitefooter_desktop";
