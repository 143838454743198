@media screen and (max-width: $break-desktop) {
	ul.medialist {
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	}
	#video-filter-menu {
		flex-direction: column-reverse;
		&.shown {
			padding: 0;
			padding-top: 10px;
			box-shadow: none;
			background-color: $color-white;
			.vertical-banner {
				display: none;
			}
			#video-filter-menu-btnsleft {
				order: 2;
				flex-direction: row;
				margin-right: 0;
				margin-left: 0;
				justify-content: space-between;
				a {
					width: 140px;
					height: 50px;
					padding: 10px;
				}
			}
			.searchform {
				order: 1;
				display: flex;
				margin-bottom: 10px;
				width: auto;
				img {
					display: none;
				}
				input.searchbox {
					width: 100%;
				}
			}
		}
		.video-filter-menu-right {
			width: 100%;
			margin-bottom: 16px;
			a img {
				@media only screen and (max-width: 768px) {
					height: 80px;
				}
				@media only screen and (max-width: 425px) {
					height: 72px;
				}
				@media only screen and (max-width: 375px) {
					height: 68px;
				}
				@media only screen and (max-width: 325px) {
					height: 64px;
				}
			}
		}
		.video-filter-menu-left {
			margin-right: 0;
		}
	}
	ul.medialist_cam li.medialist__cam {
		.medialist__item-title a {
			height: 42px;
		}
	}
	@media only screen and (max-width: 529px) {
	}
	ul.medialist li.medialist {
		.medialist__item-title a {
			height: 42px;
		}
	}
	.sale--video {
		font-size: 12px;
	}
}
@media only screen and (max-width: 320px) {
	ul.medialist {
		grid-template-columns: 1fr;
	}
	#member-videos-new ul.medialist {
		grid-template-columns: 1fr;
	}
}
