.modal,
.modal-box {
	z-index: 900;
}

.modal-sandbox {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: transparent;
}

.modal {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: rgb(0, 0, 0);
	background: rgba(0, 0, 0, 0.8);
	overflow: auto;
}

.modal-box {
	position: relative;
	width: 80%;
	max-width: 920px;
	margin: 30px auto;
	height: 94vh;
	display: flex;
  flex-direction: column;
}

.modal-header {
	padding: 20px 40px;
	background: $color-coral;
	color: $color-white;
}

.modal-body {
	background: $color-white;
	padding: 60px 40px;
	flex: 1;

	.container, .row.justify-content-center, .card {
		height: 100%;
		iframe#payment-sepa-iframe {
			height: 100%;
		}
	}
}


/*EMAIL Modify*/

#modal-verifyuser {

	.modal-box{
		top: 25%;
	}

	.modal-body {
	background: #eceff1;
	padding: 6em;
	flex: 1;
	}

	.modal-header h1{
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: .1em;
	text-align: center;
	}

	.modal-header h1::before{
	content: "\E937";
	font-family: "puricons" !important;
	padding-right: 15px;
	}

	@media only screen and (max-width: $break-phone){
	.modal-box  {
	position: relative;
	width: 90%;
	display: flex;
    flex-direction: column;
	padding: 5%;
	}

	.modal-header {
	padding: 5%;
	}

	.modal-body {
		padding: 5%;
	}
}

}

/*END EMAIL Modify*/


.close-modal {
	text-align: right;
	cursor: pointer;
}

.lpmodal {
	.modal-box {
		background-color: $color-white;
		width: 970px;
		height: 680px;
		.close-modal {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 2;
		}
		.modal-body {
			background-color: $color-white;
			display: flex;
			padding: 0;
			color: $color-dark;
			.lpmodal-left {
				position: relative;
				width: 460px;
				background-image: url(#{$cdn}/images/static/leonie-payment-2.jpg);
				background-size: cover;
				background-repeat: no-repeat;
				h2 {
					margin: 60px;
					font-size: 26px;
					line-height: 1em;
				}
				.icon {
					font-size: 45px;
					position: absolute;
					bottom: 60px;
					left: 167px;
				}
			}
			.lpmodal-right {
				width: 460px;
				font-size: 12px;
				position: relative;
				> form, div.form {
					display: flex;
					flex-direction: column;
					padding: 0px 40px;
					button {
						cursor: pointer;
						color: $color-white;
						border: 0;
						font-family: "Poppins", sans-serif;
						font-weight: 600;
						background-image: linear-gradient(79deg ,#ff617c, #ff6f61);
						margin: 24px auto 0 auto;
						display: block;
						border-radius: 99px;
						padding: 10px 48px;
						text-transform: uppercase;
						font-size: 14px;
						min-width: 200px;
						.icon {
							margin-right: 8px;
							font-size: 15px;
							display: block;
							float: left;
						}
					}
				}
				a.modal-trigger {
					color: $color-coral;
				}
				h2 {
					font-size: 17px;
					margin-top: 70px;
					margin-bottom: 40px;
				}
				label {
					font-size: 14px;
					margin-top: 26px;
				}
				input[type="checkbox"] + label::before {
					margin-right: 7px;
				}
				#payment-show-saved-methods,#payment-show-new-methods{
					background-color: $color-white;
					color: #101014;
					padding: 0;
    			width: 100%;
   				margin-top: 10px;
					font-weight: 200;
					box-shadow: none;
					background-image: none;
					text-transform: none;
					}
			}
		}
	}
}
#modal-subscription-selectchannel.lpmodal .modal-box .modal-body .lpmodal-right div.form {
	align-items: flex-start;
	padding-top: 25px;
	#subscription-error-message {
		margin-top: 10px;
	}
	h2.mobile-only {
		margin-bottom: 0;
	}
	.alert {
		font-size: 16px;
		a {
			text-decoration: underline;
		}
	}
	label {
		font-size: 19px;
	}
	#whatsapp_number_box, #msgsvc_telegram_code {
		font-size: 17px;
    	margin-top: 20px;
	}
}

#modal-login, #modal-signup {
	.modal-box {
		width: 750px;
		height: 650px;
		min-height: 600px;
		margin-bottom: 0;
		@media screen and (max-height: 700px) and (min-width: 1001px) {
			margin-top: 10px;
		}
		@media screen and (max-height: 670px) {
			margin-top: 0;
		}
	}
	.close-modal {
		line-height: 1;
	}
	.lpmodal-left, .lpmodal-right {
		width: 375px;
	}
	.lpmodal-left {
		box-sizing: border-box;
		padding: 30px;
		text-align: center;
		background-image: url(#{$cdn}/images/static/lpcms_modal_login.jpg?v=1);
		i {
			position: absolute;
			color: $color-white;
			bottom: 30px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.lpmodal-right {
		h2 {
			margin-top: 100px;
			margin-bottom: 16px;
			font-size: 26px;
			font-weight: 400;
		}
		.inputbox-multi {
			box-shadow: none;
			background-color: $color-white;
			border-radius: 0;
			width: 100%;
			max-width: 450px;
			margin: 0 auto;
			.inputbox-invalid-feedback {
				a {
					display: inline-block;
				}
			}
			.inputbox {
				border: 1px solid #8A96A3;
				margin-bottom: 12px;
				border-radius: 5px;
				&:before {
					content: unset;
				}
				input, select {
					font-size: 14px;
					color: $color-dark;
					font-weight: 300;
					box-sizing: border-box;
					width: 100%;
					padding: 12px;
					font-family: "Poppins", sans-serif;
					&::placeholder {
						color: #8A96A3;
						font-weight: 300;
					}
				}
			}
			a {
				display: block;
				text-align: right;
				font-weight: 300;
				font-size: 13px;
				color: $color-dark;
				text-decoration: underline;
			}
		}
		label {
			margin-top: 8px;
			font-size: 12px;
			font-weight: 400;
			display: flex;
    	align-items: center;
		}
		input[type=checkbox] + label::before {
			margin: 0 8px 0 0;
			border-radius: 0;
			width: 16px;
			height: 16px;
			line-height: 16px;
			border: 1px solid #101014;;
		}
		input[type=checkbox]:checked + label::before {
			font-size: 16px;
		}
		.form button, form button {
			background-image: linear-gradient(79deg ,#ff617c, #ff6f61);
			margin: 24px auto 0 auto;
			display: block;
			border-radius: 99px;
			padding: 10px 48px;
			text-transform: uppercase;
			font-size: 14px;
			min-width: 200px;
		}
		#loginform-socialmedia, #signupform-socialmedia {
			text-align: center;
			margin-top: 48px;
			max-width: 450px;
			span {
				font-size: 13px;
				font-weight: 300;
				color: $color-dark;
				margin-bottom: 16px;
				display: block;
			}
			.socialmedia-button {
				margin-bottom: 24px;
				display: flex;
				justify-content: center;
				iframe {
					width: 100%;
					height: 44px;
					&:first-child {
						margin-top: 0;
					}
				}
			}
		}
		#signupform-link-login, #loginform-bottomlinks {
			border-top: 1px solid #F5F6FA;
			padding-top: 16px;
			text-align: center;
			font-weight: 300;
			font-size: 14px;
			a {
				color: $color-dark;
				text-decoration: underline;
			}
		}
	}
}

#modal-signup {
	.lpmodal-right {
		h2 {
			margin-top: 40px;
		}
		#signupform-socialmedia {
			margin-top: 24px;
			span {
				margin-bottom: 24px;
			}
			.socialmedia-button {
				margin-bottom: 40px;
			}
		}
		.signup-modal-benefit {
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 12px;
			margin-top: -4px;
			padding: 10px 20px;
			background-color: $color-dark;
			display: flex;
			justify-content: space-between;
			span {
				color: $color-white;
				padding: 0;
			}
		}
	}
}
.grecaptcha-badge {
    visibility: hidden;
}