#pwa {
  margin-top: 20px;
  .medialist__item-image {
    i, .delete-confirm {
      position: absolute;
      font-size: 14px;
      right: 10px;
      top: 10px;
      color: white;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 12px;
      border-radius: 50px;
      z-index: 2;
    }
  }
  .medialist__item-infos {
    padding: 10px 20px 10px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .medialist__item-title {
    font-size: 14px;
		line-height: 1.4;
    font-weight: 400;
    padding: 0;
    border-bottom: none;
    margin-bottom: 10px;
    a {
      height: auto;
    }
  }
  .medialist__item-details {
    padding-top: 0;
    span {
      font-size: 11px;
      font-weight: 300;
    }
    .medialist__item-detail-duration {
      margin-left: 0;
    }
		.medialist__item-detail-filesize {
			margin-left: 12px;
		}
  }
}

#pwa-banner {
  background-color: #0A0A0A;
  height: 80px;
  display: none;
  align-items: center;
  justify-content: center;
  .pwa-banner_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    margin-left: 10px;
    button {
      font-size: 12px;
      line-height: 1;
      padding: 8px 16px;
    }
    i {
      color: black;
      background-color: white;
      padding: 10px;
      font-size: 24px;
      border-radius: 12px;
      margin-right: 12px;
      @media screen and (max-width: 374px) {
        display: none;
      }
    }
  }
  .pwa-banner_text {
    margin-right: 20px;
    h3 {
      font-size: 14px;
    }
    p {
      margin-top: 0;
      font-size: 12px;
    }
  }
  .pwa-banner-close {
    cursor: pointer;
    color: white;
  }
}

.pwa-download-button {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  .button {
    padding: 8px 24px;;
  }
}


#pwa-welcome {
  margin: 40px 0;
  p {
    margin-top: 8px;
  }
  button {
    padding: 12px 24px;
    display: block;
    margin: 0 auto;
    font-weight: 500;
    line-height: 1;
  }
}
.app-benefits {
  margin: 40px 0
}
.app-benefit {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 28px;
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 28px 16px;
  h2 {
    margin-bottom: 8px;
    font-size: 16px;
  }
  i {
    color: white;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 24px;
    &.app-download-icon {
      background-color: rgb(29,151,108);
      background: linear-gradient(10deg, rgba(29,151,108,1) 35%, rgba(147,249,185,1) 100%);
    }
    &.app-news-icon {
      background-color: rgb(235,51,73);
      background: linear-gradient(10deg, rgba(235,51,73,1) 35%, rgba(244,92,67,1) 100%);
    }
  }
  span {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
}