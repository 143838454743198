#sitefooter {
	background-color: $color-dark;
	box-shadow: 0 50vh 0 50vh $color-dark;
	color: $color-white;
	font-size: 14px;
	display: flex;
	justify-content: center;
	.inner {
		width: 1440px;
		padding: 0 60px;
	}
	#sitefooter-main {
		display: flex;
		padding-top: 120px;
		padding-bottom: 120px;
		border-bottom: 1px solid #E1E1E1;
		> div {
			flex: 1;
		}
		.sale--video {
			display: none;
		}
		#sitefooter-logo {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.icon {
			font-size: 40px;
			}
		}
		ul.menu li {
			display: block;
			margin-bottom: 10px;
			a {
				color: $color-white;
				font-size: 14px;
				&:hover {
					color: $color-coral;
				}
			}
		}
		#sitefooter-socialmedia {
			a {
				color: $color-white;
				font-size: 24px;
				margin-right: 40px;
			}
		}
	}
	#sitefooter-bottom {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 65px;
	}
	.select-language {
		margin-top: 8px;
		a {
			color: $color-white;
		}
	}
}
